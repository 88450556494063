import i18n from './i18';

const maxTimeout = 20000;

function promiseTimeout<T>(msec: number, promise: Promise<Response>): Promise<any> {
    const timeout = new Promise((resolve, reject) =>
        // setTimeout(() => reject(new Error('Timeout expired')), msec)
        setTimeout(() => reject(promise), msec)
    );

    return Promise.race([promise, timeout])
}

function processCallback<T>(route: string, data?: any) {

    if (localStorage.getItem('token')) {
        if (typeof data.headers == 'undefined') {
            data.headers = {};
        }
        data.headers["withCredentials"] = true;
        data.headers["credentials"] = "include";
        data.headers["Authorization"] = "Bearer " + localStorage.getItem('token');
    }
    if (localStorage.getItem('device_token')) {
        data.headers["devicetoken"] = localStorage.getItem('device_token');
    }

    return promiseTimeout<T>(
        maxTimeout,
        new Promise((resolve, reject) => {
            fetch('/api/' + i18n.language + '/' + route, data)
                    .then(async (response) => {
                        let json = await response.json();
                        if (!response.ok) {
                            if (response.status === 422) {
                                return reject(json)
                            } if (response.status === 401) {
                                if (json.message === "Unauthenticated.") {
                                    localStorage.removeItem('token');
                                }
                                reject(json);
                            } else {
                                reject({
                                    response,
                                    json
                                });
                            }
                        }
                        resolve(json);
                    });
            }
        ));
}

function get<T>(route: string, data?: any) {

    if (typeof data == 'undefined') {
        data = {};
    }

    data = Object.assign(data, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        }
    });

    return processCallback<T>(route, data);
}

function post<T>(route: string, data?: any) {

    if (typeof data.headers === 'undefined') {
        data = Object.assign(data, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        });
    }
    if (typeof data.method === 'undefined') {
        data = Object.assign(data, {
            method: 'POST'
        });
    }

    return processCallback<T>(route, data);
}

function put<T>(route: string, data?: any) {

    data = Object.assign(data, {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        method: 'PUT'
    });

    return processCallback<T>(route, data);
}

const Api = {
    get,
    post,
    put,
    delete:
        function <T>(route: string, data?: any) {

            if (typeof data == 'undefined') {
                data = {};
            }

            data = Object.assign(data, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                method: 'DELETE'
            });
        
            return processCallback<T>(route, data);
        }
}

export default Api;
