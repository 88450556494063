import Header from "../../components/Header/Header";
import React, {useCallback, useEffect, useState} from "react";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonRow
} from "@ionic/react";
import "./Videos.scss";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import {Redirect, useParams} from "react-router-dom";
import Video from "../../interfaces/Video";
import {useTranslation} from "react-i18next";
import {chatbubblesOutline, copyOutline, printOutline} from "ionicons/icons";
import getData from "../../services/getData";
import VideoPopup from "../../components/VideoPopup/VideoPopup";
import VideoInvitation from "../../components/VideoInvitation/VideoInvitation";
import Toast from "../../components/Toast";
import Profile from "../../services/profile";
import useWatchVideo from "../../hooks/useWatchVideo";
import Page from "../../components/Page/Page";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Storage from "../../services/Storage";
import Topic from "../../interfaces/Topic";
import {Content as IContent} from "../../interfaces/Content";
import GetPremiumComponent from "../../components/GetPremiumComponent/GetPremiumComponent";
import {IBrainCheck} from "../../interfaces/IBrainCheck";
import Widget404 from "../../components/Widget404/Widget404";

const Videos = () => {
    const {t} = useTranslation();
    let {topicId} = useParams();
    const membership = localStorage.getItem('membership');
    const [video, setVideo] = useState<Video>();
    const [showPopover, setShowPopover] = useState(false);
    const [showBrainCheckButton, setShowBrainCheckButton] = useState(false);
    const [showInvitationPopover, setShowInvitationPopover] = useState(false);
    const [popoverType, setPopoverType] = useState(0);
    const [toastColor, setToastColor] = useState<string>('danger');
    const [toastMessage, setToastMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const setWatchedVideo = useWatchVideo();
    const [crumbs, setCrumbs] = useState<Array<object>>([{}]);
    const [content, setContent] = useState<IContent>();

    const onEnded = useCallback(() => {
        setWatchedVideo({
            id: "-1",
            profile_id: Profile.getId(),
            topic_id: parseInt(topicId!),
            video_id: video!.id,
            is_finished: 1
        });

        setPopoverType(0);
        setShowPopover(true);


    }, [video, setWatchedVideo, topicId]);

    // useEffect(() => {
    //     if (topic && video) {
    //
    //     }
    // }, [topic, video, setWatchedVideo]);


    useEffect(() => {
        if (topicId) {
            getData<Video>({
                storageCallback: (db) => db.table<Video>('videos').where('topicId').equals(parseInt(topicId)).first(),
                storageName: 'videos',
                apiUrl: 'video/' + topicId,
                recordType: 'single'
            }).then((data: Video) => {
                setVideo(data);
                if (membership === '2') {
                    setWatchedVideo({
                        profile_id: Profile.getId(),
                        topic_id: parseInt(topicId),
                        video_id: data.id,
                        is_finished: 0
                    });
                }

                // if (crumbsText) {
                //     let crumbsTmp = JSON.parse(crumbsText.replace(/:"topic"/g, ':"video"'));
                //     crumbsTmp.push({'text': data.topicName, 'href': '/videos'});
                //     setCrumbs(crumbsTmp);
                // }
            })

            getData<IBrainCheck[]>({
                apiUrl: 'brainchecks/' + topicId,
                storageName: 'brainchecks',
                storageCallback: (db) => db.table<IBrainCheck>('brainchecks').where('topic_id').equals(parseInt(topicId )).toArray(),
            })
                .then(response => {
                    if (response.length > 0) {
                        setShowBrainCheckButton(true);
                    }
                })
                .catch(response => {})
            ;

            Storage.get<Topic>({
                storageCallback: (db) => db.table<Video>('topics').where('id').equals(parseInt(topicId)).first(),
                storageName: 'topics',
                apiUrl: 'topic/' + topicId,
                recordType: 'single'
            }).then((data: Topic) => {

                if (data) {
                    let crumbs = [
                        {'text': t('page.grades'), 'href': '/grades'},
                        {'text': data.grade_id + '. ' + t('page.grade'), 'href': '/subjects'},
                        {'text': data.subject_name, 'href': '/topics'}
                    ];
                    for (let i = 0; i < data.breadcrumb.length; i++) {
                        const element = data.breadcrumb[i];
                        crumbs.push({'text': element.name, 'href': '/topics/' + element.id});
                    }
                    setCrumbs(crumbs);
                }
            })
                .catch(response => {});
        }
        if (membership !== '2' && membership !== '4') {
            Storage.get<IContent>({
                storageCallback: db => db.table<IContent>('contents').where('slug').equals('nopayment').first(),
                storageName: 'contents',
                apiUrl: 'content/nopayment',
                recordType: 'single'
            })
                .then(response => setContent(response));
        }
    }, [topicId, membership]);

    function showDescriptionFeedback() {
        setPopoverType(1);
        setShowPopover(true);
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(document.getElementById('video-description')!.innerText).then(function () {
            setToastMessage(t('videos.copiedSuccessfully'));
            setToastColor('success');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        }, function () {
            setToastMessage(t('videos.copyFailed'));
            setToastColor('danger');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        });
    }

    function popoverHide() {
        setShowPopover(false);
        if (popoverType === 0) {
            setShowInvitationPopover(true);
        }
    }

    function printDescription() {
        const mywindow: any = window.open('', 'PRINT', 'height=600,width=800');

        mywindow.document.write('<html><head><title>' + video!.topicName + '</title>');
        mywindow.document.write('</head><body>');
        mywindow.document.write('<p><b>brainclub.hu</b></p>');
        mywindow.document.write('<h2>' + video!.topicName + '</h2>');
        mywindow.document.write(video!.description);
        mywindow.document.write('</body></html>');

        mywindow.document.close();
        mywindow.focus();

        mywindow.print();
        mywindow.close();
    }

    if (!topicId) {
        return <Redirect to="/topics"/>
    }

    if (membership !== '2' && membership !== '4') {
        return (
            <Page>
                <Header/>
                <IonContent fullscreen>
                    {crumbs ?
                        <Breadcrumb crumbs={crumbs}/>
                        : <></>
                    }
                    <IonGrid>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                                {content && <div className="ion-margin-vertical"
                                                 dangerouslySetInnerHTML={{__html: content?.content}}></div>}
                                <GetPremiumComponent/>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </Page>
        )
    }


    return (
        <Page>
            <Header/>
            <IonContent fullscreen>
                <Breadcrumb crumbs={crumbs}/>
                <Widget404/>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                            <VideoPopup type={popoverType} video_id={video?.id} visible={showPopover}
                                        onHide={popoverHide}/>

                            <VideoInvitation video_id={video?.id} visible={showInvitationPopover}
                                             onHide={e => setShowInvitationPopover(false)}/>

                            <Toast message={toastMessage} onHide={() => setShowToast(false)} show={showToast}
                                   color={toastColor}/>
                            {video && <> {
                                video.url !== '0' ?
                                    <div>
                                        <div className="ion-margin-bottom">
                                            <p className="text-large" id="topic-name">
                                                {video.topicName}
                                            </p>
                                        </div>
                                        <VideoPlayer
                                            videoSrc={'https://file.brainclub.hu/uploads/' + video.url + '/' + video.videoToken}
                                            videoType="video/mp4"
                                            poster={(video.poster ? 'https://file.brainclub.hu/uploads/' + video.poster + '/' + video.posterToken : '')}
                                            onEnded={onEnded}
                                        />

                                        {showBrainCheckButton && <div
                                            className="ion-text-center ion-padding-top ion-padding-bottom ion-margin-bottom">
                                            <IonButton
                                                color="yellow"
                                                href={'/brain-check/' + topicId}
                                                size="large"
                                            >
                                                {t('videos.brainCheckLink')}
                                            </IonButton>
                                        </div>}
                                        {video.description && <>
                                            <div id="video-description"
                                                 className="ion-padding-vertical ion-margin-bottom glass ion-text-left"
                                                 dangerouslySetInnerHTML={{__html: video.description}}/>

                                            {showBrainCheckButton && <div className="ion-text-center ion-padding-bottom">
                                                <IonButton
                                                    color="yellow"
                                                    href={'/brain-check/' + topicId}
                                                    size="large"
                                                >
                                                    {t('videos.brainCheckLink')}
                                                </IonButton>
                                            </div>}

                                            <div className="copy-to-clipboard btn-desc" onClick={copyToClipboard}>
                                                <IonIcon icon={copyOutline} size="large"/>
                                                {t('videos.copyToClipboard')}
                                            </div>

                                            <div className="print btn-desc" onClick={printDescription}>
                                                <IonIcon icon={printOutline} size="large"/>
                                                {t('videos.printDescription')}
                                            </div>
                                        </>}
                                        <div className="description-feedback btn-desc"
                                             onClick={showDescriptionFeedback}>
                                            <IonIcon icon={chatbubblesOutline} size="large"/>
                                            {t('videos.descriptionFeedback')}
                                        </div>
                                    </div>
                                    : <div>
                                        {t('videos.noVideoSet')}
                                        {console.log({topicId, video})}
                                    </div>}
                            </>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default Videos;