import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import ChildProfilesComponent from "../../components/ChildProfiles/ChildProfiles";
import {
    IonCol,
    IonContent,
    IonGrid,
    IonRow
} from "@ionic/react";
import Page from "../../components/Page/Page";
import {Content} from "../../interfaces/Content";
import Storage from "../../services/Storage";
import {useTranslation} from "react-i18next";

const ChildProfiles = () => {
    const { t } = useTranslation();

    const [content, setContent] = useState<Content>();

    useEffect(() => {
        Storage.get<Content>({
            recordType: 'single',
            apiUrl: 'content/gyerekprofil-letrehozas-hogyan-tovabb',
            storageName: 'contents',
            storageCallback: (db) => db.table<Content>('contents').where('slug').equals('gyerekprofil-letrehozas-hogyan-tovabb').first()
        })
            .then(
                response => {
                    setContent(response);
                }
            );
    }, []);

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <ChildProfilesComponent opened={1} />

                            {content && <div id="content" dangerouslySetInnerHTML={{__html: content.content}}/>}
                            <p className="ion-text-center ion-margin-vertical"><a href="/my-account/profile-select">{t('childprofiles.continue')}</a></p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}
export default ChildProfiles;