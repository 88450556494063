import React, {useState} from "react";
import IProfile from "../../interfaces/IProfile";
import {IonButton, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {chevronDown, chevronForward, personOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {convertToDate, getAge} from "../../services/dateHelper";

const Personal = (
    {
        profileDetails,
        active,
        selectActive,
        storeData,
        setProfileDetailByKey
    }: {
        profileDetails: IProfile,
        active: number,
        selectActive: (index: number) => void,
        storeData: (index: keyof IProfile) => void,
        setProfileDetailByKey: (key: keyof IProfile, value: any) => void
    }
) => {
    const {t} = useTranslation();
    const [birthdayMax, setBirthdayMax] = useState<string>(() => {
        const today = new Date();
        const max = new Date(
            today.getFullYear() - 18,
            today.getMonth(),
            today.getDate()
        );
        return convertToDate(max);
    });

    return (
        <div className="glass">
            <IonListHeader className="ion-no-padding">
                <IonButton
                    fill="clear"
                    expand="full"
                    className="ion-no-margin"
                    onClick={(e) => {
                        e.preventDefault();
                        selectActive(1)
                    }}
                    size="large"
                >
                    <IonIcon icon={personOutline} size="large"/>&nbsp;
                    <span className="ion-text-left width-100">{t("myaccount.personalData")}</span>
                    {active === 1 ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 1 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                <IonItem className="ion-margin-bottom" key="lastname">
                    <IonLabel position="stacked">
                        {t("myaccount.lastName")}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.lastname}
                        onIonChange={e => setProfileDetailByKey('lastname', e.detail.value!)}
                        onIonBlur={() => storeData('lastname')}
                    />
                </IonItem>
                <IonItem className="ion-margin-bottom" key="firstname">
                    <IonLabel position="stacked">
                        {t("myaccount.firstName")}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.firstname}
                        onIonChange={e => setProfileDetailByKey('firstname', e.detail.value!)}
                        onIonBlur={() => storeData('firstname')}
                    />
                </IonItem>
                <IonItem className="ion-margin-bottom" key="phone">
                    <IonLabel position="stacked">
                        {t("myaccount.phonePlaceholder")}*
                    </IonLabel>
                    <IonInput
                        type="tel"
                        value={profileDetails?.phone}
                        onIonChange={e => setProfileDetailByKey('phone', e.detail.value!)}
                        onIonBlur={() => storeData('phone')}
                    />
                </IonItem>
                <IonItem className="ion-margin-bottom" key="birthday">
                    <IonCol size={'4'} sizeMd={'3'}>
                        <label htmlFor="birthday">
                            {t('myaccount.birthday')}:*
                        </label>
                    </IonCol>
                    <IonCol size={'8'} sizeMd={'9'}>
                        <input
                            className="width-100"
                            name="birthday"
                            placeholder={t('myaccount.birthdayPlaceholder')}
                            type="date"
                            max={birthdayMax}
                            value={profileDetails.birthday ? profileDetails.birthday : ''}
                            onChange={e => setProfileDetailByKey('birthday', e.target.value)}
                            onBlur={() => storeData('birthday')}
                        />
                    </IonCol>
                </IonItem>
                <IonItem lines="none" key="over18">
                    {t('myaccount.over18')}
                </IonItem>
            </IonList>
        </div>
    )
}
export default Personal