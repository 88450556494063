import React, { useEffect, useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import "./BrainChecks.scss";
import Header from "../../components/Header/Header";
import TopicTitle from "../../components/TopicTitle/TopicTitle";
import getData from "../../services/getData";
import { IBrainCheck } from "../../interfaces/IBrainCheck";
import config from "../../config/config";
import { Result } from "../../interfaces/Result";
import { shuffle } from "../../helpers/array";
import BrainCheck from "../../components/BrainCheck/BrainCheck";
import setData from "../../services/setData";
import Profile from "../../services/profile";
import { useTranslation } from "react-i18next";
import { BrainCheckResult } from "../../interfaces/BrainCheckResult";
import Page from "../../components/Page/Page";
import { useParams } from "react-router-dom";
import Widget404 from "../../components/Widget404/Widget404";

const BRAINCHECK_SHOW_QUESTION = 0;
const BRAINCHECK_SHOW_FINISH = 1;

const BrainChecks = () => {
    const { topic } = useParams();
    const { t } = useTranslation();
    const [brainChecks, setBrainChecks] = useState<Array<IBrainCheck>>();
    const [results, setResults] = useState<Array<Result>>([]);
    const [currentBrainCheck, setCurrentBrainCheck] = useState<number>(0);
    const [mode, setMode] = useState<number>(BRAINCHECK_SHOW_QUESTION);


    useEffect(() => {
        if (topic) {
            getData<IBrainCheck[]>({
                apiUrl: 'brainchecks/' + topic,
                storageName: 'brainchecks',
                storageCallback: (db) => db.table<IBrainCheck>('brainchecks').where('topic_id').equals(parseInt(topic)).toArray(),
            })
                .then(response => {
                    setBrainChecks(shuffle<IBrainCheck>(response));

                    const brainCheckResultsCount = Math.min(config('brainchecks.questionCount'), response.length);
                    const newResults: Result[] = [];
                    for (let i = 0; i < brainCheckResultsCount; i++) {
                        newResults.push({
                            answered: false,
                            isGood: false
                        });
                    }
                    setResults(newResults);
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [topic]);



    const saveResults = () => {
        setData<BrainCheckResult>({
            apiUrl: 'braincheck-result',
            methodType: 'post',
            apiData: {
                records: [
                    {
                        profile_id: Profile.getId(),
                        topic_id: parseInt(topic!),
                        questions: results.length,
                        good_answers: results.reduce((accumulator, currentValue) => (currentValue.isGood ? accumulator + 1 : accumulator), 0)
                    }
                ]
            },
            storageName: 'braincheckresults'
        });
    }

    const onSelect = (result: boolean) => {

        let newResults = [...results];

        newResults[currentBrainCheck].answered = true;
        newResults[currentBrainCheck].isGood = result;
        setResults(newResults);
    }


    const onNext = () => {

        if (results.length - 1 > currentBrainCheck) {
            setCurrentBrainCheck(prevState => prevState + 1);
        } else {
            saveResults();
            setMode(BRAINCHECK_SHOW_FINISH);
        }
    }

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <Widget404/>
                <TopicTitle parentUrl={'/videos/' + topic} />
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            {
                                results &&
                                <div className="results ion-padding-bottom">
                                    {results.map((result, index) =>
                                        <div key={index} className={"result" +
                                            (result.answered ? ' answered' : '') +
                                            (result.isGood ? ' good-answer' : '') +
                                            (index === currentBrainCheck ? ' active' : '')
                                        }></div>
                                    )
                                    }
                                </div>
                            }

                            {brainChecks && mode === BRAINCHECK_SHOW_QUESTION &&
                                <BrainCheck
                                    brainCheck={brainChecks[currentBrainCheck]}
                                    current={currentBrainCheck}
                                    all={results.length}
                                    onSelect={onSelect}
                                    onNext={onNext}
                                />
                            }

                            {
                                mode === BRAINCHECK_SHOW_FINISH &&
                                <div className="finish ion-text-center">
                                    <p>
                                        <strong>{t('brainchecks.finishTitle')}</strong>
                                        <br />{t('brainchecks.finishDescription')}
                                    </p>
                                    <div className="buttons">
                                        <IonButton
                                            routerLink={"/videos/" + topic}
                                            expand="block"
                                            size="default"
                                        >
                                            {t('brainchecks.backToVideo')}
                                        </IonButton>

                                        <IonButton
                                            onClick={e => window.location.reload()}
                                            expand="block"
                                            size="default"
                                            className="ion-margin-vertical"
                                        >
                                            {t('brainchecks.restart')}
                                        </IonButton>

                                        <IonButton
                                            routerLink="/my-account/default/child"
                                            expand="block"
                                            size="default"
                                        >
                                            {t('brainchecks.backToHomepage')}
                                        </IonButton>
                                    </div>
                                </div>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default BrainChecks;
