import React from "react";
import {IonContent} from "@ionic/react";
import Header from "../../components/Header/Header";
import {useTranslation} from "react-i18next";
import Page from "../../components/Page/Page";

const NetworkProblem = () => {
    const {t} = useTranslation();
    return (
        <Page>
            <Header/>
            <IonContent fullscreen>
                {t('networkproblem.errorTitle')}<br />
                {t('networkproblem.errorContent')}
            </IonContent>
        </Page>
    )
}


export default NetworkProblem;