import {useEffect, useState} from "react";
import Api from "../api";
import {useHistory} from "react-router-dom";

export default function useHasActivePayment() {
    let history = useHistory();
    const [canBuy, setCanBuy] = useState<boolean>(false);

    useEffect(() => {
        Api.get<boolean>('payment/has-active-payment')
            .then(response => {
                if (response) {
                    history.push('/home');
                } else {
                    setCanBuy(true);
                }
            });
    }, [history])

    return {canBuy}
}