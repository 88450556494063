import IProfile from "../interfaces/IProfile";
import getData from "./getData";

function getId(): string {
    const profile_id = localStorage.getItem('profile_id');
    if (profile_id !== null) {
        return JSON.parse(JSON.stringify(profile_id));
    }
    return '';
}

function getUserId(): number {
    const user_id = localStorage.getItem('user_id');
    if (user_id !== null) {
        return +(JSON.parse(JSON.stringify(user_id)));
    }
    return 0;
}

function setId(profile_id: number) {
    localStorage.setItem('profile_id', profile_id.toString());
}

async function getProfileDataByType(type: number) {
    const profiles = await getProfiles();
    const result: IProfile[] = [];
    profiles.forEach((profile) => {
        if (profile.type === type) {
            result.push(profile);
        }
    });

    return result;
}

function getProfileData(): Promise<IProfile> {
    if (!getId()) {
        return new Promise<IProfile>((resolve, reject) => {
            reject('No profile selected')
        })
    }
    return getData<IProfile>({
        apiUrl: 'profile/' + getId(),
        storageName: 'profiles',
        storageCallback: (db) => {
            db.table<IProfile>('profiles').where('id').equals(getId()).first();
        },
        recordType: 'single'
    });
}

function getProfiles() {
    return getData<Array<IProfile>>({
        apiUrl: 'profile/list',
        storageName: 'profiles',
        storageCallback: (db) => {
            db.table<IProfile>('profiles').where('user_id').equals(getUserId()).sortBy('created_at');
        }
    });
}

const Profile = {
    setId,
    getId,
    getProfileDataByType,
    getProfileData,
    getUserId,
    getProfiles
}

export default Profile;