import React, {useEffect, useState} from "react";
import Topic from "../../interfaces/Topic";
import getData from "../../services/getData";
import {useParams} from "react-router-dom";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {useTranslation} from "react-i18next";


const TopicTitle = (props: {
    parentUrl: string
}) => {
    const {topic} = useParams();
    const { t } = useTranslation();
    const [topicDetails, setTopicDetails] = useState<Topic>();
    const [crumbs, setCrumbs] = useState<Array<object>>([{}]);

    useEffect(() => {
        getData<Topic>({
            apiUrl: 'topic/' + topic,
            storageName: 'topics',
            storageCallback: (db) => db.table<Topic>('topics').where('id').equals(parseInt(topic!)).first(),
            recordType: 'single'
        }).then(response => {
            setTopicDetails(response);
        })
    }, []);

    useEffect(() => {
        if (topicDetails) {
            let crumbsTmp = [
                {'text': t('page.grades'), 'href': '/grades'},
                {'text': topicDetails.grade_id + '. ' + t('page.grade'), 'href': '/subjects'},
                {'text': topicDetails.subject_name, 'href': '/topics'}
            ];
            topicDetails.breadcrumb.map(item => {
                crumbsTmp.push({'text': item.name, 'href': '/videos/' + item.id});
            })
            crumbsTmp.push({'text': 'Braincheck', 'href': ''});
            setCrumbs(crumbsTmp);
        }
    }, [topicDetails])

    return (
        <>
            {topicDetails &&
            <Breadcrumb crumbs={crumbs}/>
            }
        </>
    );
}

export default TopicTitle;