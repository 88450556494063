import React, {useEffect, useState} from "react";
import getData from "../../services/getData";
import Header from "../../components/Header/Header";
import {IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonPage, IonRow} from "@ionic/react";
import useProduct from "../../hooks/useProduct";
import Content from "./Content";
import IProduct from "../../interfaces/IProduct";
import Product from "../../services/product";
import Payment from "./Payment";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

interface LastMembership {
    details: {
        membership: number;
        devices: number;
    }
}

const RenewPaymentPage = () => {
    const [withDevices, setWithDevices] = useState<boolean>(false);
    const [deviceCount, setDeviceCount] = useState<number>(0);
    const [redirectURL, setRedirectURL] = useState<string>('');
    const [pageVisible, setPageVisible] = useState<boolean>(false);
    const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(false);
    const {period, price, priceText, product} = useProduct(true, isRecurringChecked)
    const [devices, setDevices] = useState<Array<IProduct>>([]);
    const [device, setDevice] = useState<IProduct>({} as IProduct);
    const [devicePrice, setDevicePrice] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);


    //Get last membership details
    useEffect(() => {
        getData<LastMembership>({
            apiUrl: 'payment/get-last-membership',
        }).then(response => {
            if (response.details.devices > 0) {
                setWithDevices(true);
                setDeviceCount(response.details.devices)
                Product.getDevices().then(
                    (response) => {
                        setDevices(response);
                    }
                );
            }
            if (response.details.membership == 0) {
                setRedirectURL('/my-account/welcome');
            } else {
                setPageVisible(true);
            }
        });
    }, []);

    //Számoljuk ki az eszközök árát
    useEffect(() => {
        if (device.id) {
            setDevicePrice(device.price * deviceCount);
        }
    }, [device, deviceCount])

    // Válasszuk ki az eszközt
    useEffect(() => {
        if (devices.length) {
            for (let index = 0; index < devices!.length; index++) {
                const item = devices![index];
                if (item.period === period) {
                    setDevice(item);
                    break;
                }
            }
        }
    }, [devices, period])

    // SZámoljuk ki a teljes árat
    useEffect(() => {
        let total: number = price;
        if (withDevices) {
            total = total + devicePrice
        }
        setTotalPrice(total);
    }, [withDevices, period, price, devicePrice, deviceCount])


    useEffect(() => {
        if (redirectURL !== '') {
            window.location.href = redirectURL
        }
    }, [redirectURL])

    if (!pageVisible) {
        return <LoadingComponent/>;
    }

    return <IonPage>
        <Header/>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                        <IonCard>
                            <IonCardContent className={"ion-text-center"}>
                                <Content
                                    price={price}
                                    priceText={priceText}
                                    device={device}
                                    deviceCount={deviceCount}
                                    devicePrice={devicePrice}
                                    setWithDevices={setWithDevices}
                                    withDevices={withDevices}
                                    totalPrice={totalPrice}/>
                                <Payment
                                    product={product}
                                    device={device}
                                    deviceCount={deviceCount}
                                    withDevices={withDevices}
                                    isRecurringChecked={isRecurringChecked}
                                    setIsRecurringChecked={setIsRecurringChecked}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
}

export default RenewPaymentPage;