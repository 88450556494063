import React, {useEffect, useState} from "react";
import {IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {albumsOutline, chevronDown, chevronForward, downloadOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import Storage from "../../services/Storage";
import Payment from "../../interfaces/Payment";
import DateFormat from "../../helpers/DateFormat";

const OrdersInvoices = () => {
    const {t} = useTranslation();
    const [active, setActive] = useState<boolean>(true);
    const [payments, setPayments] = useState<Payment[]>([])

    useEffect(() => {
        Storage.get<Payment[]>({
            apiUrl: 'invoices'
        })
            .then(response => {
                setPayments(response);
            })
    }, []);

    function download(reference: string) {
        window.location.href = ('/uploads/invoice/' + reference);
    }

    return (
        <>
            <IonListHeader className="ion-no-padding">
                <IonButton
                    size="large"
                    fill="clear"
                    expand="full"
                    onClick={(e) => setActive(prevState => !prevState)}
                >
                    <IonIcon icon={albumsOutline} size="large"/>&nbsp;
                    <span className="ion-text-left width-100">{t("parentdefaultinvoices.title")}</span>
                    {active === true ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === true ? 'ion-padding-horizontal ion-margin-bottom' : 'ion-hide'} lines="none">
                {payments && payments.map((payment, index) => (
                    <IonItem
                        key={payment.id}
                        className={`clickable ion-align-items-center ${index > 0 ? "ion-margin-top" : ""}`}
                        onClick={() => download(payment.reference!)}
                    >
                        {/* <a href={'/uploads/invoice/' + payment.reference}> */}
                        <IonLabel className="ion-no-margin">
                            {DateFormat('Y. m. d.', new Date(payment.created_at!))} -
                            {t('parentdefaultinvoices.invoiceNumber')}: {payment.invoice}<br/>
                            {t('parentdefaultinvoices.transactionId')}: {payment.transaction_id}
                        </IonLabel>
                        <IonIcon
                            icon={downloadOutline}
                            className="ion-margin-start text-yellow"
                        />
                        {/* </a> */}

                    </IonItem>
                ))}
            </IonList>
        </>
    );
}
export default OrdersInvoices;