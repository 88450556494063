import React from "react";
import formatPrice from "../../helpers/formatPrice";
import {IonText} from "@ionic/react";
import {useTranslation} from "react-i18next";

const Discounts = ({discounts, price, devicePrice}: { discounts: any, price: number, devicePrice: number }) => {

    const {t} = useTranslation();

    function getByType(type: number) {
        return discounts.filter((item: any) => {
            return item.type === type;
        })
    }

    return (
        <IonText color="primary">
            <h3>
                {t("discounts.monthlyDiscount")}<br/>

                {[0, 1, 2].map((type) => (<div key={type}>
                    {getByType(type).length > 0 && <>
                        {getByType(type)[0].name}<br/>
                        {devicePrice > 0 && <>{t("discounts.monthlyDiscountMembership")}<br/></>}
                        {formatPrice(getByType(type).length * (price / 10))}<br/>
                        {devicePrice > 0 && <>
                        {t("discounts.monthlyDiscountDevices")}<br/>
                        {formatPrice(getByType(type).length * (devicePrice / 10))}
                        </>}
                    </>}
                </div>))}

            </h3>
        </IonText>
    );
    // return (
    //     <tr>
    //         <td>
    //             {t("discounts.monthlyDiscount")}<br/>
    //         </td>
    //         <td>
    //             {[0, 1, 2].map((type) => (<div key={type}>
    //                 {getByType(type).length > 0 && <>
    //                     {getByType(type)[0].name}<br/>
    //                     {devicePrice > 0 && <>{t("discounts.monthlyDiscountMembership")}<br/></>}
    //                     {formatPrice(getByType(type).length * (price / 10))}<br/>
    //                     {devicePrice > 0 && <>
    //                         {t("discounts.monthlyDiscountDevices")}<br/>
    //                         {formatPrice(getByType(type).length * (devicePrice / 10))}
    //                     </>}
    //                 </>}
    //             </div>))}
    //
    //         </td>
    //     </tr>
    // );
}

export default Discounts;