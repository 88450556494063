import React, {useState} from "react";
import {IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonList, IonListHeader} from "@ionic/react";
import {chevronDown, chevronForward, readerOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import IProfile from "../../interfaces/IProfile";
const Billing = (
    {
        profileDetails,
        active,
        selectActive,
        storeData,
        setProfileDetailByKey

    }:{
        profileDetails: IProfile,
        active: number,
        selectActive: (index: number) => void,
        storeData: (index: keyof IProfile) => void,
        setProfileDetailByKey: (key: keyof IProfile, value: any) => void
    }
) => {
    const {t} = useTranslation();
    const [billingDataChecked, setBillingDataChecked] = useState(false);


    return (
        <div className="ion-margin-vertical glass">
            <IonListHeader className="ion-no-padding">
                <IonButton
                    fill="clear"
                    expand="full"
                    className="ion-no-margin"
                    onClick={(e) => {
                        e.preventDefault();
                        selectActive(2)
                    }}
                    size="large"
                >
                    <IonIcon icon={readerOutline} size="large"/>&nbsp;
                    <span className="ion-text-left width-100">{t("myaccount.billingData")}</span>
                    {active === 2 ? <IonIcon icon={chevronDown}/> :
                        <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 2 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                <IonItem className="ion-margin-bottom" key="city">
                    <IonLabel position="stacked">
                        {t("myaccount.city")}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.city}
                        onIonChange={e => setProfileDetailByKey('city', e.detail.value!)}
                        onIonBlur={() => storeData('city')}
                    />
                </IonItem>
                <IonItem className="ion-margin-bottom" key="postcode">
                    <IonLabel position="stacked">
                        {t("myaccount.postcode")}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.postcode}
                        onIonChange={e => setProfileDetailByKey('postcode', e.detail.value!)}
                        onIonBlur={() => storeData('postcode')}
                    />
                </IonItem>
                <IonItem className="ion-margin-bottom" key="street">
                    <IonLabel position="stacked">
                        {t("myaccount.address1")}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.street}
                        onIonChange={e => setProfileDetailByKey('street', e.detail.value!)}
                        onIonBlur={() => storeData('street')}
                    />
                </IonItem>
                <IonItem className="ion-margin-bottom" key="housenumber">
                    <IonLabel position="stacked">
                        {t("myaccount.address2")}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.housenumber}
                        onIonChange={e => setProfileDetailByKey('housenumber', e.detail.value!)}
                        onIonBlur={() => storeData('housenumber')}
                    />
                </IonItem>
                <IonItem key="floor">
                    <IonLabel position="stacked">
                        {t("myaccount.address3")}
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.floor}
                        onIonChange={e => setProfileDetailByKey('floor', e.detail.value!)}
                        onIonBlur={() => storeData('floor')}
                    />
                </IonItem>
                <IonItem key="billing">
                    <IonLabel className="ion-text-wrap">{t('myaccount.billingDifferent')}</IonLabel>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={billingDataChecked}
                        onIonChange={e => setBillingDataChecked(e.detail.checked)}/>
                </IonItem>
                <div className={billingDataChecked === true ? 'ion-padding-start' : 'ion-hide'}>
                    <IonItem className="ion-margin-bottom" key="billingname">
                        <IonLabel position="stacked">
                            {t("myaccount.billingName")}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.billingname}
                            onIonChange={e => setProfileDetailByKey('billingname', e.detail.value!)}
                            onIonBlur={() => storeData('billingname')}
                        />
                    </IonItem>
                    <IonItem className="ion-margin-bottom" key="vat">
                        <IonLabel position="stacked">
                            {t("myaccount.VAT")}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.vat}
                            onIonChange={e => setProfileDetailByKey('vat', e.detail.value!)}
                            onIonBlur={() => storeData('vat')}
                        />
                    </IonItem>
                </div>
            </IonList>
        </div>
    )
}
export default Billing