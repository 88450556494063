import get from "./storage/get";
import create from "./storage/create";
import update from "./storage/update";
import destroy from "./storage/destroy";
import Dexie from "dexie";

export type IDates = {
    id?: string;
    downloadDate?: number;
    created_at?: string;
    updated_at?: string;
}

export interface ISetData<T> {
    apiUrl: string;
    methodType: string;
    apiData: {
        records: Array<T & IDates>;
    };
    storageName: string;

}

export interface IData {
    apiUrl: string;
    apiData?: object;
    apiCallback?: () => any;
    storageName?: string;
    storageCallback?: (db: Dexie) => any;
    recordType?: string;
}

const Storage = {
    get,
    create,
    update,
    destroy
}

export default Storage;