import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import User from "../../User";
import {
    appsOutline,
    bookOutline,
    bugOutline,
    clipboardOutline,
    helpOutline,
    homeOutline,
    listOutline,
    logInOutline,
    logOutOutline,
    mailOutline,
    personAddOutline,
    personOutline
} from "ionicons/icons";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import DataContext from "../../context/DataContext";

const MenuWidget = () => {
    const {t} = useTranslation();
    const {profile} = useContext(DataContext);
    const membership = localStorage.getItem('membership');

    function isChild() {
        return User.isAuthenticated() && membership === "2" && profile.type === 1;
    }

    function isParent() {
        return User.isAuthenticated() && membership === "2" && profile.type === 0;
    }

    function isAuthenticatedGuest() {
        return User.isAuthenticated() && membership !== "2" && profile.type === 0;
    }

    return (
        <IonMenu side="start" menuId="defaultSideMenu" contentId="menuContent" id="defaultSideMenu">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{t('Menu')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {isChild() &&
                    <IonItem routerLink="/my-account/default/child">
                        <IonIcon slot="start" icon={homeOutline}/>
                        <IonLabel>{t('menu.home')}</IonLabel>
                    </IonItem>
                    }

                    {isChild() &&
                    <IonItem routerLink="/grades">
                        <IonIcon slot="start" icon={appsOutline}/>
                        <IonLabel>{t('menu.childDefaultGrades')}</IonLabel>
                    </IonItem>
                    }

                    {isChild() &&
                    <IonItem routerLink="/my-account/default/child/subjects">
                        <IonIcon slot="start" icon={listOutline}/>
                        <IonLabel>{t('menu.childDefaultSubjects')}</IonLabel>
                    </IonItem>
                    }

                    {isChild() &&
                    <IonItem routerLink="/my-account/default/child/invite">
                        <IonIcon slot="start" icon={personAddOutline}/>
                        <IonLabel>{t('menu.ChildDefaultInvite')}</IonLabel>
                    </IonItem>
                    }

                    {isChild() &&
                    <IonItem routerLink="/my-account/default/child/watched-videos">
                        <IonIcon slot="start" icon={bookOutline}/>
                        <IonLabel>{t('menu.childDefaultWatchedVideos')}</IonLabel>
                    </IonItem>
                    }

                    {isParent() &&
                    <IonItem routerLink="/my-account/profile-settings">
                        <IonIcon slot="start" icon={homeOutline}/>
                        <IonLabel>{t('menu.home')}</IonLabel>
                    </IonItem>
                    }

                    {isParent() &&
                    <IonItem routerLink="/my-account/orders">
                        <IonIcon slot="start" icon={clipboardOutline}/>
                        <IonLabel>{t('menu.orders')}</IonLabel>
                    </IonItem>

                    }

                    {(isParent() || isChild()) && <>
                        <IonItem routerLink="/my-account/profile-select">
                            <IonIcon slot="start" icon={personOutline}/>
                            <IonLabel>{t('menu.profiles')}</IonLabel>
                        </IonItem>

                        <IonItemDivider/>
                        <IonItem routerLink="/content/adatkezelesi-tajekoztato">
                            <IonIcon slot="start" icon={helpOutline}/>
                            <IonLabel>{t('menu.adatkezelesiTajekoztato')}</IonLabel>
                        </IonItem>
                        {User.isAuthenticated() &&
                        <IonItem routerLink="/bugfound">
                            <IonIcon slot="start" icon={bugOutline}/>
                            <IonLabel>{t('menu.bugFound')}</IonLabel>
                        </IonItem>
                        }
                        <IonItem routerLink="/customerservice">
                            <IonIcon slot="start" icon={mailOutline}/>
                            <IonLabel>{t('menu.customerService')}</IonLabel>
                        </IonItem>
                    </>}

                    {isAuthenticatedGuest() && <>
                        <IonItem routerLink="/my-account/no-profile-home">
                            <IonIcon slot="start" icon={personOutline}/>
                            <IonLabel>{t('menu.home')}</IonLabel>
                        </IonItem>

                        <IonItem routerLink="/my-account/get-premium">
                            <IonIcon slot="start" icon={personOutline}/>
                            <IonLabel>{t('menu.getPremium')}</IonLabel>
                        </IonItem>
                        <IonItem routerLink="/grades">
                            <IonIcon slot="start" icon={personOutline}/>
                            <IonLabel>{t('menu.grades')}</IonLabel>
                        </IonItem>
                    </>}

                    <IonItemDivider/>

                    {User.isAuthenticated() &&
                    <IonItem routerLink="/logout">
                        <IonIcon slot="start" icon={logOutOutline}/>
                        <IonLabel>{t('menu.logout')}</IonLabel>
                    </IonItem>
                    }

                    {!User.isAuthenticated() &&
                    <IonItem routerLink="/login">
                        <IonIcon slot="start" icon={logInOutline}/>
                        <IonLabel>{t('menu.login')}</IonLabel>
                    </IonItem>
                    }
                </IonList>
            </IonContent>
        </IonMenu>
    );
}
export default MenuWidget;