import React from "react";
import "./Spinner.scss";

const Spinner = () => {
    return <div className="loader-container">

        <div className="loader">Loading...</div>
    </div>
}

export default Spinner;

export function showSpinner() {
    const el = document.querySelector('.loader-container') as HTMLElement;
    if (el) {
        el.style.display = 'flex';
    }
}

export function hideSpinner() {
    const el = document.querySelector('.loader-container') as HTMLElement;
    if (el) {
        el.style.display = 'none';
    }
}