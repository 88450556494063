import {
    IonItem,
    IonList,
    IonListHeader,
    IonSegment,
    IonSegmentButton,
} from "@ionic/react";
import DateFormat from "../../helpers/DateFormat";
import React, { useEffect, useState } from "react";
import { WatchedVideoWithDates } from "../../interfaces/WatchedVideo";
import Video from "../../interfaces/Video";
import Topic from "../../interfaces/Topic";
import { BrainCheckResult } from "../../interfaces/BrainCheckResult";
import { useTranslation } from "react-i18next";
import db from "../../services/db";
import Api from "../../api";
import { Redirect } from "react-router-dom";
import Profile from "../../services/profile";
import './WatchedVideos.scss';

type WatchedVideoList = { full: WatchedVideoWithDates[], partial: WatchedVideoWithDates[] };

type FullData = {
    watchedVideos: WatchedVideoWithDates[],
    videos: Video[],
    topics: Topic[],
    brainCheckResults: BrainCheckResult[]
}

const getVideo = (id: number, videoList?: Video[]) => {

    const filtered = videoList?.filter(video => video.id === id);
    if (filtered && filtered.length) {
        return filtered[0];
    }
    return null;
}

const WatchedVideos = () => {

    const { t } = useTranslation();
    // const history = useHistory();
    const [lastTopicMode, setLastTopicMode] = useState<keyof WatchedVideoList>('partial');
    const [watchedVideos, setWatchedVideos] = useState<WatchedVideoList>();
    const [videos, setVideos] = useState<Video[]>();
    const [topics, setTopics] = useState<Topic[]>();
    const [recommendedVideos, setRecommendedVideos] = useState<Video[]>();

    const [redirectUrl, setRedirectUrl] = useState<string>('');

    function videoClick(topic_id: number) {
        // localStorage.setItem('topic', topic_id.toString());
        setRedirectUrl('/videos/' + topic_id.toString());
    }

    function getTopic(id: number): Topic | null {
        if (!topics || topics.length === 0) {
            return null;
        }
        const filteredTopics = topics.filter(item => item.id === id);
        if (filteredTopics.length === 0) {
            return null;
        }
        return filteredTopics[0];
    }

    useEffect(() => {
        let hasEverythingLocal = true;
        let dexieWatchedVideos: WatchedVideoWithDates[];
        let dexieVideos: Video[];
        let dexieTopics: Topic[];
        let dexieBraincheckResults: BrainCheckResult[];

        function buildWatchedVideos(watchedVideos: WatchedVideoWithDates[], videoList: Video[]) {
            const full: WatchedVideoWithDates[] = [];
            const partial: WatchedVideoWithDates[] = [];
            let recommended: Video[] = [];

            // This is needed when we want to make the topics unique
            const fullTopics: number[] = [];
            const partialTopics: number[] = [];

            watchedVideos.forEach(value => {
                if (value.index === 100) {
                    if (fullTopics.indexOf(value.topic_id) === -1) {
                        fullTopics.push(value.topic_id);
                        full.push(value);
                    }
                } else {
                    const pIndex = partialTopics.indexOf(value.topic_id);
                    if (pIndex === -1) {
                        partialTopics.push(value.topic_id);
                        partial.push(value);
                    } else {
                        partial[pIndex].index = Math.max(partial[pIndex].index!, value.index!);
                    }
                }
            });

            setWatchedVideos({ full: full.slice(0, 10), partial: partial.slice(0, 10) });

            function pushRecommended(item: WatchedVideoWithDates) {
                const next_video_id = getVideo(item.video_id, videoList)?.next_video_id;
                if (next_video_id) {
                    const video = getVideo(next_video_id, videoList);
                    if (video) {
                        recommended.push(video);
                    }
                }
            }

            full.forEach((item) => {
                pushRecommended(item);
            });
            partial.forEach((item) => {
                pushRecommended(item);
            });
            recommended = recommended.filter((value, index, self) => self.indexOf((value)) === index);
            setRecommendedVideos(recommended);

        }

        function setAll(data: FullData) {
            setVideos(data.videos);
            setTopics(data.topics);

            data.watchedVideos = data.watchedVideos.map(video => {

                let index = 0;

                const braincheckResult = data.brainCheckResults.filter(item => item.topic_id === video.topic_id);
                if (braincheckResult.length) {
                    index = braincheckResult.reduce((accumulator, item) => (Math.max(accumulator, Math.round(item.good_answers / item.questions * 100))), 0);
                }

                if (index > 0) {
                    video.index = index;
                } else {
                    video.index = (video.is_finished ? 10 : 0);
                }

                return video;
            });
            buildWatchedVideos(data.watchedVideos, data.videos);
        }

        // db.transaction("r", 'profiles', 'videos', 'topics', 'watchedvideos', 'braincheckresults', async () => {
        //     dexieWatchedVideos = await db.table<WatchedVideoWithDates>('watchedvideos')
        //         .where('profile_id').equals(Profile.getId())
        //         .reverse().sortBy('created_at');
        //     // dexieWatchedVideos = dexieWatchedVideos.slice(0, 10);
        //     if (dexieWatchedVideos.length === 0) {
        //         hasEverythingLocal = false;
        //     } else {
        //
        //         const videoIds = dexieWatchedVideos.reduce<number[]>((accumulator, currentValue) => {
        //             if (accumulator.indexOf(currentValue.video_id) === -1) {
        //                 accumulator.push(currentValue.video_id);
        //             }
        //
        //             return accumulator;
        //         }, []);
        //
        //         dexieVideos = await db.table<Video>('videos').where('id').anyOf(videoIds).toArray();
        //
        //         if (videoIds.length !== dexieVideos.length) {
        //             hasEverythingLocal = false;
        //         } else {
        //             const topicIds = dexieVideos.reduce<number[]>((accumulator, currentValue) => {
        //                 if (accumulator.indexOf(currentValue.topic_id) === -1) {
        //                     accumulator.push(currentValue.topic_id);
        //                 }
        //
        //                 return accumulator;
        //             }, []);
        //
        //             dexieTopics = await db.table<Topic>('topics').where('id').anyOf(topicIds).toArray();
        //
        //             if (topicIds.length !== dexieTopics.length) {
        //                 hasEverythingLocal = false;
        //             } else {
        //                 dexieBraincheckResults = await db.table<BrainCheckResult>('braincheckresults').where('topic_id').anyOf(topicIds).toArray();
        //
        //             }
        //         }
        //     }
        // }).then(() => {
        //     if (hasEverythingLocal) {
        //
        //         setAll({
        //             brainCheckResults: dexieBraincheckResults,
        //             topics: dexieTopics,
        //             videos: dexieVideos,
        //             watchedVideos: dexieWatchedVideos
        //         })
        //     } else {
        //         Api.get<FullData>('child-default/' + Profile.getId()).then(response => {
        //             setAll(response)
        //         });
        //     }
        // });

        Api.get<FullData>('child-default/' + Profile.getId()).then(response => {
            setAll(response)
        });
    }, []);

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    }

    if (watchedVideos && watchedVideos['partial'].length === 0 && watchedVideos['full'].length === 0) {
        return <></>
    }

    return (
        <div className="glass ion-margin-bottom">
            <IonListHeader className="form-block-heading ion-align-items-center">
                <div className="ion-padding-horizontal">
                    <p className="text-white">
                        {t('childdefaultpage.lastTopicsTitle')}
                    </p>
                </div>
            </IonListHeader>
            <IonList className="ion-padding-horizontal" lines="none">
                {watchedVideos && (watchedVideos['partial'].length > 0 || watchedVideos['full'].length > 0) &&
                    <IonItem>
                        <div className="width-100 ion-margin-bottom">
                            {(watchedVideos && (watchedVideos['partial'].length > 0 || watchedVideos['full'].length > 0) &&
                                <>
                                    <IonSegment className="ion-margin-bottom" mode="ios" value={lastTopicMode} onIonChange={e => {
                                        setLastTopicMode(lastTopicMode === 'full' ? 'partial' : 'full')
                                    }}>
                                        <IonSegmentButton value="partial">
                                            <p>{t('childdefaultpage.partial')}</p>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="full">
                                            <p>{t('childdefaultpage.full')}</p>
                                        </IonSegmentButton>
                                    </IonSegment>
                                    {watchedVideos && watchedVideos[lastTopicMode] &&
                                        <div className="watched-videos">
                                        {/* <IonList lines="none" className="watched-videos-list"> */}
                                            {watchedVideos && watchedVideos[lastTopicMode].map(watchedVideo =>
                                                <React.Fragment key={watchedVideo.id}>
                                                    <div className="video-poster clickable" onClick={e => videoClick(watchedVideo.topic_id)}>
                                                        <img alt=""
                                                            src={'https://file.brainclub.hu/uploads/' + getVideo(watchedVideo.video_id, videos)!.poster + '/' + getVideo(watchedVideo.video_id, videos)!.posterToken + '?size=thumb'} />
                                                    </div>

                                                    <div className="watch-details clickable" onClick={e => videoClick(watchedVideo.topic_id)}>
                                                        <span className="video-title">{getTopic(watchedVideo.topic_id)?.name}</span>
                                                        {/*{console.log(watchedVideo.created_at, watchedVideo.created_at.replace(/-/g, '/'))}*/}
                                                        {/*{DateFormat('Y.m.d.', new Date(watchedVideo.created_at.replace(/-/g, '/')))}*/}
                                                        {DateFormat('Y.m.d.', new Date(watchedVideo.created_at))}
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0,0,50,50">
                                                        <circle cx="25" cy="25" r="24" fill="transparent" stroke="#fcf400ff"
                                                            strokeWidth={2} />
                                                        <path d="M 25 0 L 25 25 L 49 25 A 24 24 0 0 0 25 0" fill="#fcf400ff" />
                                                    </svg>
                                                    <div className="watch-index">
                                                        {watchedVideo.index} %
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    }
                                </>
                            )}
                        </div>
                    </IonItem>
                }
                {recommendedVideos && (recommendedVideos.length > 0) &&
                    <IonItem>
                        <div className="recommended-topics ion-margin-bottom">
                            <div className="ion-margin-bottom">
                                <p>
                                    <strong>{t('childdefaultpage.recommendedTopicsTitle')}</strong>
                                </p>
                                <div>
                                    {(recommendedVideos && (recommendedVideos.length > 0) &&
                                        <IonList>
                                            {recommendedVideos && recommendedVideos.map(recommendedVideo =>
                                                <IonItem key={recommendedVideo.id}
                                                    onClick={e => videoClick(recommendedVideo.topic_id)}>
                                                    <div className="video-poster">
                                                        <img alt=""
                                                            src={'https://file.brainclub.hu/uploads/' + getVideo(recommendedVideo.id, videos)!.poster + '/' + getVideo(recommendedVideo.id, videos)!.posterToken + '?size=thumb'} />
                                                    </div>
                                                    <div className="watch-details">
                                                        <span
                                                            className="video-title">{getTopic(recommendedVideo.topic_id)?.name}</span>
                                                    </div>
                                                </IonItem>
                                            )}
                                        </IonList>
                                    ) || t('childdefaultpage.noRecommendedVideosYet')}
                                </div>
                            </div>
                        </div>
                    </IonItem>
                }
            </IonList>
        </div>
    );
}

export default WatchedVideos;