import React, {useContext} from "react";
import "./Breadcrumb.scss";
import DataContext from "../../context/DataContext";
import {useTranslation} from "react-i18next";

const Breadcrumb = (props: any) => {
    const {t} = useTranslation();
    const { profile } = useContext(DataContext);

    function isLast(index: any) {
        return index === props.crumbs.length - 1;
    }

    return (
        <div className="breadcrumb ion-text-left">
            {
                profile && profile.firstname &&
                <span>
                        <a href={(profile.type === 0 ? '/my-account/profile-settings' : '/my-account/default/child')}>{profile.firstname + ' ' + t('page.homepage')}</a>
                    {props.crumbs.length > 0 ? '\u00A0/\u00A0' : ''}
                </span>
            }
            {
                props.crumbs.map((crumb: any, ci: any) => {
                    if (isLast(ci)) {
                        return (
                            <span key={ci}>
                                {crumb.text}
                            </span>
                        );
                    }

                    return (
                        <span key={ci}>
                            <a href={crumb.href}>{crumb.text}</a>&nbsp;/&nbsp;
                        </span>
                    );
                })
            }
        </div>
    );
};

export default Breadcrumb;
