import Header from "../../components/Header/Header";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import Page from "../../components/Page/Page";
import Storage from "../../services/Storage";
import {Content as IContent} from "../../interfaces/Content";

const Content = () => {
    const {alias} = useParams();
    const [content, setContent] = useState<IContent>();

    useEffect(() => {
        if (alias) {
            Storage.get<IContent>({
                storageCallback: db => db.table<IContent>('contents').where('slug').equals(alias).first(),
                storageName: 'contents',
                apiUrl: 'content/' + alias,
                recordType: 'single'
            })
                .then(response => setContent(response));
        }
    }, [alias]);

    return <Page>
        <Header/>
        <IonContent>
            <IonGrid>
                <IonRow className={'ion-justify-content-center'}>
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                        {content && <>
                            <h1>{content.title}</h1>
                            <div dangerouslySetInnerHTML={{__html: content.content}}></div>
                        </>}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </Page>
;
}
export default Content;