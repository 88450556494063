import {IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import Header from "../../components/Header/Header";
import DevicesComponent from "../../components/Devices/Devices";
import React from "react";
import Page from "../../components/Page/Page";

const Devices = () => {
    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <DevicesComponent />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}
export default Devices;