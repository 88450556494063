import IProduct from "../interfaces/IProduct";
import getData from "./getData";

function getProducts() {
    return getData<Array<IProduct>>({
        apiUrl: 'products/list',
        storageName: 'products',
        storageCallback: (db) => {
            db.table<IProduct>('products');
        }
    });
}

function getMemberships() {
    return getData<Array<IProduct>>({
        apiUrl: 'products/getmemberships',
        storageName: 'products',
        storageCallback: (db) => {
            db.table<IProduct>('products');
        }
    });
}

function getDevices() {
    return getData<Array<IProduct>>({
        apiUrl: 'products/getdevices',
        storageName: 'products',
        storageCallback: (db) => {
            db.table<IProduct>('products');
        }
    });
}

const Product = {
    getProducts,
    getMemberships,
    getDevices
}

export default Product;