import React, { useEffect, useState } from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { Content } from "../../interfaces/Content";
import { useTranslation } from "react-i18next";

import Api from "../../api";
import "./InstallNotification.scss";
import { browserName, browserVersion } from "react-device-detect";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;

    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;

    prompt(): Promise<void>;
}

const InstallNotification = () => {
    const { t } = useTranslation();

    const [show, setShow] = useState<boolean>(false);
    const [showContentLine, setShowContentLine] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState<Content>();
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent>();
    const hideInstallNotification = localStorage.getItem("hideInstallNotification");

    useEffect(() => {
        if (browserName == "Firefox" || browserName == "Safari") {
            setShowContentLine(true);
            return;
        }

        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e as BeforeInstallPromptEvent);
            setShow(true);
        });
    }, []);

    useEffect(() => {
        if (content && content.title) {
            setShowModal(true);
        }
    }, [content]);

    function doInstall() {
        if (!deferredPrompt) {
            return;
        }
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            console.log(choiceResult);
        });
    }

    function showContent() {
        Api.get<Content>("content/install-pwa").then((response) => setContent(response));
    }

    function hideNotification() {
        localStorage.setItem("hideInstallNotification", "1");
        setShowModal(false);
    }

    if (hideInstallNotification === '1') {
        return <></>
    }

    return (
        <>
            {show && (
                <div className="install-notification" onClick={doInstall}>
                    <span>{t("installNotification.installPWA")}</span>
                </div>
            )}
            {showContentLine && (
                <div className="install-notification" onClick={showContent}>
                    <span>{t("installNotification.installContent")}</span>
                </div>
            )}
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle slot="start">{content?.title}</IonTitle>
                        <IonButtons slot="start"></IonButtons>
                        <IonButtons slot="end">
                            <IonButton slot="end" onClick={() => setShowModal(false)}>
                                {t("registration.closeModal")}
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <div className="hide-notification" onClick={() => hideNotification()}>
                        {t("installNotification.dontShowAgain")}
                    </div>
                </IonHeader>
                <IonContent className="ion-padding">
                    <h1>{content?.title}</h1>
                    {content && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content.content,
                            }}
                        />
                    )}
                </IonContent>
            </IonModal>
        </>
    );
};

export default InstallNotification;
