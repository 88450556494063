import {LoginToken} from "../interfaces/auth/LoginToken";

export default function (response:LoginToken) {
    localStorage.setItem('token', response.token);
    localStorage.setItem('user_id', response.user_id);
    localStorage.setItem('hasProfiles', response.hasProfiles);

    switch (response.payment) {
        case 0:
            localStorage.setItem('membership', '0');
            window.location.replace('/my-account/no-profile-home');
            break;
        case 1:
            localStorage.setItem('membership', '1');
            window.location.replace('/renew-payment');
            break;
        case 2:
            localStorage.setItem('membership', '2');
            window.location.replace('/device-check');
            break;
        case 3:
            localStorage.setItem('membership', '3');
            window.location.replace('/device-check');
            break;
        case 4:
            localStorage.setItem('membership', '4');
            if (response.hasProfiles) {
                window.location.replace('/device-check');
            } else {
                window.location.replace('/my-account/create-parent-profile');
            }
            break;
    }
}