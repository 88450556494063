import {IonToast} from "@ionic/react";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";

const ErrorMessage = ({message, setMessage}: {message:string, setMessage:Dispatch<SetStateAction<string>> }) => {
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        if (message !== '') {
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
                setMessage('');
            }, 5000);
        }
    }, [message]);

    return <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={message}
        position="top"
        color="danger"
    />
}
export default ErrorMessage;