import React, { useEffect, useState } from "react";
import IProfile from "../../interfaces/IProfile";
import Storage from "../../services/Storage";
import Page from "../../components/Page/Page";
import Header from "../../components/Header/Header";
import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import OrdersProducts from "../../components/OrdersProducts/OrdersProducts";
import OrdersInvoiceData from "../../components/OrdersInvoiceData/OrdersInvoiceData";
import OrdersInvoices from "../../components/OrdersInvoices/OrdersInvoices";
import { useTranslation } from 'react-i18next';
import '../../i18';
import {Membership} from "../../interfaces/Membership";



interface Response {
    membership: Membership;
    profiles: IProfile[];
    save: number;
}

const Orders = () => {
    const [membership, setMembership] = useState<Membership>({} as Membership);
    const [childProfiles, setChildProfiles] = useState<IProfile[]>([]);
    const [parentProfile, setParentProfile] = useState<IProfile>({} as IProfile);
    const { t } = useTranslation();
    const crumbs = [
        // { 'text': t('page.parentHomepage'), 'href': '/my-account/profile-settings' },
        { 'text': t('page.myOrders') }
    ];
    useEffect(() => {
        Storage.get<Response>({
            apiUrl: 'payment-details',
        }).then((response: Response) => {
            if (response.membership) {
                setMembership(response.membership);
            }
            if (response.profiles.length) {
                const parentProfiles = response.profiles.filter(profile => profile.type === 0);
                setChildProfiles(response.profiles.filter(profile => profile.type === 1));
                if (parentProfiles.length)
                    setParentProfile(parentProfiles[0]);
            }
        })
    }, []);

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                            <p className="text-large ion-margin-vertical">
                                {t('menu.orders')}
                            </p>
                            <div className="glass">
                                <OrdersProducts childProfiles={childProfiles} membership={membership} />
                            </div>
                            <div className="ion-margin-vertical glass">
                                <OrdersInvoiceData profile={parentProfile} />
                            </div>
                            <div className="glass">
                                <OrdersInvoices />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default Orders;