import React from 'react';
import {Redirect} from "react-router-dom";

const Home = () => {
    const token = localStorage.getItem('token');
    const hasProfiles = localStorage.getItem('hasProfiles');
    const profile_id = localStorage.getItem('profile_id');
    const profile_type = localStorage.getItem('profile_type');

    if (!token) {
        return <Redirect to="/login"/>;
    }

    if (!hasProfiles || hasProfiles === '0') {
        return <Redirect to="/my-account/no-profile-home"/>;
    }

    if (!profile_id) {
        return <Redirect to="/my-account/profile-select"/>;
    }

    if (profile_type === '1') {
        return <Redirect to="/my-account/default/child"/>;
    }

    return <Redirect to="/my-account/profile-settings"/>;
};

export default Home;
