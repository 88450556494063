import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol, IonIcon, IonButton
} from "@ionic/react";

import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import ChildProfiles from "../../components/ChildProfiles/ChildProfiles";
import ParentProfile from "../../components/ParentProfile/ParentProfile";
import '../../i18';
import {useTranslation} from 'react-i18next';
import {Redirect} from "react-router-dom";
import Devices from "../../components/Devices/Devices";
import Page from "../../components/Page/Page";
import Invite from "../../components/Invite/Invite";
import Profile from "../../services/profile";
import {chevronDown, chevronForward, personOutline} from "ionicons/icons";

const ProfileSettings = () => {
    const {t} = useTranslation();

    const [childProfileOpened, setChildProfileOpened] = useState<number>(-1);

    const crumbs: any = [
        // { 'text': t('page.parentHomepage') }
    ];
    const membership = localStorage.getItem('membership');

    function loadProfiles() {
        Profile.getProfileDataByType(1).then(
            data => {
                if (data.length > 0) {
                    setChildProfileOpened(0);
                } else {
                    setChildProfileOpened(1);
                }
            }
        ).catch(response => console.log(response));
    }

    useEffect(() => {
        loadProfiles();
    }, []);

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login"/>
    }

    return (
        <Page>
            <Header/>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        {/* <IonCol size="12">
                            <Breadcrumb crumbs={crumbs} />
                        </IonCol> */}
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                            {/* <IonCard> */}
                            {/* <IonCardHeader> */}
                            {/* <Breadcrumb crumbs={crumbs} /> */}
                            {/* </IonCardHeader> */}
                            {/* <IonCardContent> */}
                            <p className="ion-text-center text-large">{t('profilesettings.toptext')}</p>
                            <div className="glass ion-margin-top">
                                <ParentProfile/>
                            </div>
                            <div className="glass ion-margin-vertical">
                                <ChildProfiles opened={childProfileOpened === 1 ? 1 : 0}/>
                            </div>
                            <div className="glass ion-margin-bottom">
                                <Devices/>
                            </div>

                            {membership === "2" && <div className="glass">
                                <Invite/>
                            </div>}

                            <IonGrid>
                                <IonRow className="ion-justify-content-center">
                                    <IonCol size={membership !== "2" ? '6' : '12'} className="ion-text-left">
                                        <IonButton href="/my-account/profile-select" size="small">
                                            {t("myaccount.toProfileSelect")}
                                        </IonButton>
                                    </IonCol>
                                    {membership !== "2" && <IonCol size={'6'} className="ion-text-right">
                                        <IonButton href="/renew-payment" size="small">
                                            {t('myaccount.getPremiumButton')}
                                        </IonButton>
                                    </IonCol>}
                                </IonRow>
                            </IonGrid>

                            {/* </IonCardContent> */}
                            {/* </IonCard> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </Page>
    );
}

export default ProfileSettings;