import React, {useEffect, useRef} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export type VideoPlayerProps = {
    videoSrc: string;
    videoType: string;
    poster?: string;
    onEnded?: () => void;
}

function VideoPlayer({videoSrc, videoType, poster, onEnded}: VideoPlayerProps) {
    const videoRef = useRef(null);
    const playerRef = useRef<any>(null);
    const options = {autoplay: false, controls: true, muted: false};

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            playerRef.current = videojs(videoElement, options, () => {
                playerRef.current = player;
            });
        }
        const player = playerRef.current;
        player.src({
            src: videoSrc,
            type: videoType
        });
        if (poster) {
            player.poster(poster);
        }
        player.on('ended', function () {
            if (onEnded) {
                onEnded();
            }
        });
        // return () => {
        //     if (player) {
        //         player.dispose();
        //     }
        // };
    }, [options, onEnded, poster, videoSrc, videoType]);

    return (
        <div data-vjs-player>
            <video ref={videoRef} className="video-js vjs-16-9 vjs-big-play-centered" playsInline/>
        </div>
    );

    // const playerRef = useRef(null);

    // useEffect(() => {
    //     if (playerRef.current) {
    //         const player = videojs(playerRef.current, {autoplay: false, controls: true, muted: true});
    //         player.src({
    //             src: videoSrc,
    //             type: videoType
    //         });
    //         if (poster) {
    //             player.poster(poster);
    //         }
    //         player.on('ended', function () {
    //             if (onEnded) {
    //                 onEnded();
    //             }
    //         });

    //         return () => {
    //             if (player) {
    //                 player.dispose();
    //             }
    //         };
    //     }
    // }, [playerRef, onEnded, poster, videoSrc, videoType]);


    // return (
    //     <div data-vjs-player>
    //         <video ref={playerRef} className="video-js vjs-16-9 vjs-big-play-centered" playsInline />
    //     </div>
    // );
}

export default VideoPlayer;