import React, { useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonToast
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import Api from "../../api";
import { useHistory, useParams } from "react-router-dom";
import Page from "../../components/Page/Page";
import TextWithBorder from "../../components/TextWithBorder/TextWithBorder";
import Header from "../../components/Header/Header";

const EmailConfirm = () => {
    const { t } = useTranslation();
    const [code, setCode] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    let history = useHistory();
    // @ts-ignore
    let { token } = useParams();

    const submitCode = () => {
        Api.post<any>('emailconfirm', {
            body: JSON.stringify({
                token: token,
                code: code
            })
        })
            .then(response => {
                history.push('/login');
            })
            .catch(error => {
                let message = "";
                if (typeof error.errors !== 'undefined') {
                    if (typeof error.errors.code !== 'undefined') {
                        message = error.errors.code.join('<br />');
                    }
                }
                setErrorMessage(message);
                setShowToast(true);
                setTimeout(() => setShowToast(false), 5000);
            })
            ;
    }

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={errorMessage}
                    position="top"
                    color="danger"
                />
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className="ion-margin-vertical ion-padding-top">
                                <TextWithBorder text={t('emailconfirm.formTitle')} />
                                <p className="ion-text-center">
                                    {t('emailconfirm.note')}
                                </p>
                            </div>
                            <IonLabel>
                                {t('emailconfirm.code')}
                            </IonLabel>
                            <IonInput
                                autofocus={true}
                                value={code}
                                role="email-input"
                                onIonChange={e => setCode(e.detail.value!)}
                            />
                            <IonButton
                                className="width-100"
                                size="default"
                                onClick={submitCode}>{t('emailconfirm.submit')}</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>)
}

export default EmailConfirm;