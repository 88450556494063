import {useTranslation} from "react-i18next";
import React from "react";
import Page from "../../components/Page/Page";
import Header from "../../components/Header/Header";
import {IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";

const PaymentHelp = () => {
    const {t} = useTranslation();

    return <Page>
        <Header/>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow className={'ion-justify-content-center'}>
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                        <h2 className="ion-text-center">
                            {t('paymenthelp.successTitle')}
                        </h2>
                        <p className="ion-text-center">
                            {t('paymenthelp.success')}
                        </p>
                        <p className="ion-text-center">
                            <a href="/home">{t('paymenthelp.home')}</a>
                        </p>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </Page>
}

export default PaymentHelp;