import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Api from "../../api";
import Options from "../../services/Options";

import "./CheckMembership.scss";

const CheckMembership = () => {
    const {t} = useTranslation();
    const [hasMembership, setHasMembership] = useState<number>(() => {
        const membership= localStorage.getItem('membership')
        if (membership) {
            return parseInt(membership);
        }
        return 0;
    });
    const [checked, setChecked] = useState<boolean>(false);
    const [needRedirect, setNeedRedirect] = useState<boolean>(false);
    const membership = localStorage.getItem('membership');

    useEffect(() => {
        function APICheckMembership() {
            Api.get<number>('has-membership').then(response => {
                Options.set('checkMembership', new Date().toJSON());
                setHasMembership(response);
                    localStorage.setItem('membership', response.toString());
                if (response < 2 && !checked) {
                    setNeedRedirect(true);
                }
                setChecked(true);
            })
        }

        if (localStorage.getItem('token')) {
            Options.get('checkMembership')
                .then(value => {
                    if (value) {
                        const diff = new Date().getTime() - new Date(value).getTime();
                        if (diff <= (3600 * 24)) {
                            setChecked(true);
                            setHasMembership(membership ? parseInt(membership) : 0);
                            return;
                        }
                        APICheckMembership();
                    }
                })
                .catch(() => {
                    APICheckMembership();
                })
        }
    }, []);

    if (!localStorage.getItem('token')) {
        return (<></>);
    }

    if (needRedirect) {
        // return <Redirect to="/renew-payment"/>
    }

    return (<>
        {hasMembership < 2 && <div className="header-message">
            {hasMembership == 0 && <>
                {t('checkmembership.noMembership')}
                <a href="/my-account/get-premium">{t('checkmembership.get')}</a>
            </>}
            {hasMembership == 1 && <>
                {t('checkmembership.expiredMembership')}
                <a href="/renew-payment">{t('checkmembership.renew')}</a>
            </>}
        </div>}
        {hasMembership === 4 && <div className="blue-header-message">
            {t('checkmembership.trialMembership')}
            <a href="/content/trial-details">{t('checkmembership.trialDetails')}</a>
        </div>}
    </>);
}

export default CheckMembership;
