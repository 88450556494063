import React, {Dispatch, SetStateAction, useState} from "react";
import {IonButton, IonCheckbox, IonIcon, IonItem, IonList, IonListHeader, IonRouterLink} from "@ionic/react";
import {cardOutline, chevronDown, chevronForward} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import Api from "../../api";
import {Content} from "../../interfaces/Content";
import ContentService from "../../services/ContentService";
import Modal from "../../components/Modal/Modal";
import ISimplepay from "../../interfaces/ISimplepay";
import IProduct from "../../interfaces/IProduct";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
const Payment = (
    {
        product,
        device,
        deviceCount,
        withDevices,
        isRecurringChecked,
        setIsRecurringChecked
    }: {
        product: IProduct,
        device: IProduct,
        deviceCount: number,
        withDevices: boolean,
        isRecurringChecked: boolean,
        setIsRecurringChecked: Dispatch<SetStateAction<boolean>>
    }
) => {
    const {t} = useTranslation();

    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>({} as Content);
    const [errorMessage, setErrorMessage] = useState<string>('');

    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    function showContent(content: string) {
        ContentService.get(content)
            .then(response => {
                displayModal(response);
            });
    }

    const simplepayPayment = () => {
        if (!privacyChecked) {
            setErrorMessage(t('myaccount.privacyRequired'));
            return false;
        }
        if (!termsChecked) {
            setErrorMessage(t('myaccount.termsRequired'));
            return false;
        }

        Api.post<ISimplepay>('payment/renew-membership', {
            body: JSON.stringify({
                product_id: product.id,
                with_devices: withDevices,
                device_id:device.id,
                device_count: deviceCount,
                user_id: localStorage.getItem('user_id'),
            })
        }).then(response => {
            window.location.href = response
        })
    }

    return (
        <>
            <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
            <Modal content={modalContent} show={showModal} setShow={setShowModal}/>
            <IonList
                className={'ion-padding-horizontal ion-margin-bottom'}
                lines="none">
                <IonItem key='privacy'>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={privacyChecked}
                        onIonChange={e => setPrivacyChecked(e.detail.checked)}
                    />
                    <div className="toplayer">
                        {t('myaccount.chkAccept')}&nbsp;
                        <IonRouterLink
                            onClick={e => showContent('adatkezelesi-tajekoztato')}
                        >
                            {t('myaccount.acceptPP')}
                        </IonRouterLink>.
                    </div>
                </IonItem>
                <IonItem key='terms'>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={termsChecked} onIonChange={e => setTermsChecked(e.detail.checked)}
                    />
                    <div className="toplayer">
                        {t('myaccount.chkAccept')}&nbsp;
                        <IonRouterLink
                            onClick={e => showContent('altalanos-szerzodesi-feltetelek')}
                        >
                            {t('myaccount.acceptTOS')}
                        </IonRouterLink>.
                    </div>
                </IonItem>
                <IonItem key='recurring'>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={isRecurringChecked}
                        onIonChange={e => setIsRecurringChecked(e.detail.checked)}
                    />
                    <div className="toplayer">
                        {t('myaccount.acceptRecurring')}&nbsp;
                        (<IonRouterLink
                        onClick={e => showContent('tajekoztato-az-ismetlodo-fizetesrol')}
                    >
                        {t('myaccount.recurringConditions')}
                    </IonRouterLink>)
                    </div>
                </IonItem>
                <IonItem key="button" className="ion-margin-top">
                    <IonButton
                        disabled={!(privacyChecked && termsChecked)}
                        size="default"
                        className="ion-text-wrap"
                        onClick={simplepayPayment}
                    >
                        {t('myaccount.paymentWithCreditCard')}
                    </IonButton>
                    <IonRouterLink
                        href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                        target="_blank"
                        slot="end"
                    >
                        <img src="/images/simplepay_logo_color.png" alt={t('myaccount.simplepayTerms')} title={t('myaccount.simplepayTerms')}/>
                    </IonRouterLink>
                </IonItem>
            </IonList>
        </>
    )
}
export default Payment