import React, { useState } from "react";
import Header from "../../components/Header/Header";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonGrid, IonInput, IonItem, IonLabel, IonList,
    IonPage,
    IonRow,
    IonToast
} from "@ionic/react";
import TextWithBorder from "../../components/TextWithBorder/TextWithBorder";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../api";

const LostPassword = () => {

    const { t } = useTranslation();
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [messageColor, setMessageColor] = useState<string>();
    const [showToast, setShowToast] = useState(false);

    if (localStorage.getItem('token')) {
        return <Redirect to="/home" />
    }

    const submitLostPassword = () => {
        Api.post<string>('lost-password', {
            body: JSON.stringify({
                email: email,
            })
        })
            .then(response => {
                setMessageColor('success')
                setMessage(response.message);
                setShowToast(true);
                setTimeout(() => setShowToast(false), 5000);
            })
            .catch(response => {
                setMessageColor('danger')
                setMessage(typeof response.errors != "undefined" ? response.errors.email : response.message);
                setShowToast(true);
                setTimeout(() => setShowToast(false), 5000);
            })
            ;
    }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={message}
                    position="top"
                    color={messageColor}
                />

                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className="ion-margin-vertical ion-padding-top">
                                <TextWithBorder text={t('lostpassword.formTitle')} />
                                <p className="ion-text-center">
                                    {t('lostpassword.formDescription')}
                                </p>
                            </div>
                            <IonLabel>
                                {t('lostpassword.Email')}
                            </IonLabel>
                            <IonInput value={email}
                                role="email-input"
                                name="email"
                                onIonChange={e => setEmail(e.detail.value!)}
                                inputmode="email"
                                id="email"
                            />
                            <IonButton
                                className="ion-margin-top width-100"
                                onClick={submitLostPassword}
                                size="default"
                            >
                                {t('lostpassword.submit')}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default LostPassword;