import React from "react";
import './TextWithBorder.scss';

interface ContainerProps {
    text:string;
}

const TextWithBorder = ({ text }: ContainerProps) => {
    return (
        <div className="twb-container">
            <div className="twb-line" />
            {text}
            <div className="twb-line" />
        </div>
    );
};

export default TextWithBorder;