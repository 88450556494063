import {
    IonButton,
    IonHeader, IonIcon, IonImg, IonItem, IonList,
    IonMenuButton, IonRouterLink, IonSearchbar,
    IonToolbar
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Header.scss";
import { useHistory } from "react-router-dom";
import DataContext from "../../context/DataContext";
import { closeOutline, searchOutline } from "ionicons/icons";
import User from "../../User";
import InstallNotification from "../InstallNotification/InstallNotification";

interface HeaderType {
    title?: string;
    children?: React.ReactNode;
    menu?: string;
    menuSlot?: string;
}

const Header = (props: HeaderType) => {
    const { t } = useTranslation();
    const [showSearch, setShowSearch] = useState(false);
    const { searchText, setSearchText } = useContext(DataContext);
    let history = useHistory();
    const [logoClass, setLogoClass] = useState<string>('');

    const {profile} = useContext(DataContext);

    function handleSearch(event: React.FormEvent) {
        event.preventDefault();
        history.push('/search/' + searchText);
        if (history.location.pathname.indexOf('/search') === 0) {

        }
    }

    function hideSearch() {
        setShowSearch(false);
        setSearchText('');
    }

    useEffect(() => {
        if (props.menuSlot === 'end') {
            setLogoClass('ion-margin-start');
        }
    }, [props.menuSlot])

    useEffect(() => {
        if (searchText != '') {
            setShowSearch(true);
        }
        if (showSearch === false) {
            setSearchText('');
        }
    }, [showSearch, searchText])

    return (
        <IonHeader className="main-header">
        <InstallNotification />
            <IonToolbar color="clear" className="">
                <IonButton slot="end" fill="clear" onClick={e => setShowSearch(p => !p)} className="search-icons">
                    {showSearch === false && !searchText ? (
                        <IonIcon icon={searchOutline} />
                    ) : (
                        <IonIcon icon={closeOutline} />
                    )}
                </IonButton>
                <IonMenuButton slot="start" menu="defaultSideMenu" color="dark" />
                <IonRouterLink
                    slot="start"
                    href="/home"
                    id="logo"
                >
                    <IonImg src={'/images/cropped-brainclub-logo-v5.png'} title={t('Brainclub')} className={logoClass} />
                    {/*<span id="header-title">&nbsp;{props.title ? props.title : t('Brainclub')}</span>*/}
                </IonRouterLink>

                {props.children}
            </IonToolbar>
            {(showSearch || searchText) &&
                <IonList lines="none" className="">
                    <form action="/search" onSubmit={handleSearch}>
                        <IonItem>
                            <IonSearchbar
                                value={searchText}
                                placeholder={t('header.searchPlaceholder')}
                                onIonChange={e => setSearchText(e.detail.value!)}
                            />
                        </IonItem>
                    </form>
                </IonList>}
        </IonHeader>
    )
}

Header.defaultProps = {
    menu: 'defaultSideMenu',
    menuSlot: 'start'
}

export default Header;
