import React from "react";
import Page from "../../components/Page/Page";
import Header from "../../components/Header/Header";
import Devices from "../../components/Devices/Devices";
import { IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import Invite from "../../components/Invite/Invite";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useTranslation } from 'react-i18next';
import '../../i18';


const ParentDefaultPage = () => {
    const { t } = useTranslation();
    const crumbs = [
        { 'text': t('page.parentHomepage'), 'href': '/my-account/profile-settings' },
        { 'text': t('page.parentInvite') },
    ];

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardHeader>
                                    <Breadcrumb crumbs={crumbs} />
                                </IonCardHeader>
                                <IonCardContent>
                                    <div className="glass-on-glass">
                                        <Devices />
                                    </div>
                                    <div className="ion-margin-top glass-on-glass">
                                        <Invite />
                                    </div>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    )

}
export default ParentDefaultPage;