import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { IonCol, IonContent, IonGrid, IonItem, IonList, IonListHeader, IonRow } from "@ionic/react";
import "./Grades.scss";
import Page from "../../components/Page/Page";
import GradesComponent from "../../components/GradesComponent/GradesComponent";
import Widget404 from "../../components/Widget404/Widget404";

const Grades = () => {

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <Widget404/>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <GradesComponent />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default Grades;