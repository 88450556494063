import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Api from "../../api";
import { useHistory } from "react-router-dom";

const DeviceCheck = () => {
    const { t } = useTranslation();
    const deviceToken = localStorage.getItem('device_token');
    let history = useHistory();

    useEffect(() => {
        if (!deviceToken) {
            // Initialize an agent at application startup.
            const fpPromise = FingerprintJS.load();

            (async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise;

                const result = await fp.get();

                Api.post('device', {
                    body: JSON.stringify({
                        // This is the visitor identifier:
                        visitorId: result.visitorId
                    })
                }
                ).then(response => {
                    if (response.result === 'success') {
                        localStorage.setItem('device_token', response.token);
                        localStorage.setItem('device_valid', '1');
                        history.push('/home');
                    } else {
                        localStorage.setItem('device_token', response.token);
                        localStorage.setItem('device_valid', '0');
                        history.push('/buy-new-device');
                    }
                });

            })();
        } else {
            Api.get('device/' + deviceToken).then(response => {
                if (response.result === 'success') {
                    localStorage.setItem('device_valid', '1');
                    history.push('/home');
                } else {
                    localStorage.setItem('device_valid', '0');
                    history.push('/buy-new-device');
                }
            });
        }
    }, [deviceToken, history]);

    return (
        <>
            {t('device_check.checking')}
        </>
    );
}
export default DeviceCheck;