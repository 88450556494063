import React, { useEffect, useState } from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPopover, IonRouterLink, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import './VideoInvitation.scss';
import getData from "../../services/getData";
import { Content } from "../../interfaces/Content";
import { closeOutline } from "ionicons/icons";

const VideoInvitation = ({ video_id, visible, onHide }: {
    video_id: number | undefined;
    visible: boolean;
    onHide: (e: any) => void;
}) => {

    const { t } = useTranslation();
    const [content, setContent] = useState<Content>();
    const [showContentModal, setShowContentModal] = useState<boolean>(false);

    useEffect(() => {
        const slug = 'video-game';
        getData<Content>({
            apiUrl: 'content/' + slug,
            storageName: 'contents',
            storageCallback: db => db.table<Content>('contents').where('slug').equals(slug).first(),
            recordType: 'single'
        }).then(response => setContent(response));
    }, [])

    return (
        <>
            <IonPopover
                cssClass="invitation-popover"
                isOpen={visible}
                onDidDismiss={e => onHide(e)}
            >
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonTitle slot="start">
                            {t('videoinvitation.didYouLikeVideo')}
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton slot="end"
                                onClick={e => onHide(e)}>
                                <IonIcon icon={closeOutline} size="large" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <p>{t('videoinvitation.line1')}</p>
                    <p>{t('videoinvitation.line2')}</p>
                    <IonRouterLink onClick={() => setShowContentModal(true)}>{t('videoinvitation.more')}</IonRouterLink>
                </IonContent>
            </IonPopover>
            <IonModal isOpen={showContentModal} cssClass="invitation-modal">
                {content &&
                    <>
                        <IonHeader>
                            <IonToolbar mode="ios">
                                <IonTitle slot="start">{content.title}</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton slot="end"
                                        onClick={() => setShowContentModal(false)}>
                                        {t('Close')}
                                        <IonIcon icon={closeOutline} size="large" />
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="ion-padding">
                            <p className="text-large">{content.title}</p>
                            <div className="content-content" dangerouslySetInnerHTML={{ __html: content.content }} />
                        </IonContent>
                    </>
                }
            </IonModal>
        </>
    );
}

export default VideoInvitation;