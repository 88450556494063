import React, {useState} from "react";
import {IonButton, IonIcon, IonItem, IonList, IonListHeader} from "@ionic/react";
import {chevronDown, chevronForward, giftOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const Invite = () => {
    const {t} = useTranslation();
    let history = useHistory();

    const [active, setActive] = useState<number>(1);
    const eventHandler = (e: any, index: number) => {
        e.preventDefault();
        active === index ? setActive(0) : setActive(index);
    }

    function goToInvitePage() {
        history.push('/invite');
    }

    return (
        <>
            <IonListHeader className="ion-no-padding">
                <IonButton
                    size="large"
                    fill="clear"
                    expand="full"
                    onClick={(e) => eventHandler(e, 1)}
                >
                    <IonIcon icon={giftOutline} size="large" />
                    <span className="ion-text-left width-100">{t("invite.title")}</span>
                    {active === 1 ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 1 ? 'ion-padding-start' : 'ion-hide'} lines="none">
                <IonItem onClick={goToInvitePage} className="clickable ion-margin-bottom">
                    {t('invite.description')}
                </IonItem>
            </IonList>
        </>
    );
}

export default Invite;