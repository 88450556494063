import {useEffect, useState} from "react";
import ProductService from "../services/product";
import IProduct from "../interfaces/IProduct";
import formatPrice from "../helpers/formatPrice";
import {useTranslation} from "react-i18next";

export default function useProduct (canBuy: boolean, isRecurring: boolean) {
    const {t} = useTranslation();

    const [product, setProduct] = useState<IProduct>({} as IProduct);
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [price, setPrice] = useState<number>(0);
    const [priceText, setPriceText] = useState<string>('');

    const [period, setPeriod] = useState<number>(() => {
        if (!localStorage.hasOwnProperty('yearlyMembership')) {
            return 1;
        }
        if (localStorage.getItem('yearlyMembership') !== 'true') {
            return 1;
        }
        return 12;
    })

    useEffect(() => {
        if (canBuy) {
            ProductService.getMemberships().then((response) => {
                setProducts(response);
            });
        }
    }, [canBuy])

    useEffect(() => {
        for (let index = 0; index < products!.length; index++) {
            const item = products![index];
            if (item.period === period && item.is_recurring === (isRecurring ? 1 : 0)) {
                setProduct(item);
                setPrice(item!.price);
                setPriceText(` / ${t('myaccount.' + (period === 1 ? 'month' : 'year'))}`);
            }
        }
    }, [period, products, t, isRecurring])

    return {period, product, products, price, priceText};
}