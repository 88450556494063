import Header from "../../components/Header/Header";
import React, { useEffect, useState } from "react";
import {
    IonButton, IonButtons,
    IonCard,
    IonCardContent, IonCardHeader, IonCheckbox,
    IonCol,
    IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList,
    IonListHeader, IonModal,
    IonRouterLink,
    IonRow,
    IonText, IonTitle,
    IonToolbar
} from "@ionic/react";
import getData from "../../services/getData";
import { Content } from "../../interfaces/Content";
import { useTranslation } from "react-i18next";
import config from "../../config/config";
import formatPrice from "../../helpers/formatPrice";
import { cardOutline, checkboxOutline, closeOutline, squareOutline } from "ionicons/icons";
import Api from "../../api";
import ISimplepay from "../../interfaces/ISimplepay";
import Product from "../../services/product";
import IProduct from "../../interfaces/IProduct";

import "./PaymentChange.scss";
import Page from "../../components/Page/Page";
import Discounts from "../../components/Discounts/Discounts";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const PaymentChange = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState<Array<string>>();
    const price = config("myaccount.yearlyPrice");
    const yearlyDiscount = config("myaccount.yearlyDiscount");
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>();
    // const [requiredMissing, setRequiredMissing] = useState<boolean>(true);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(false);
    const [simpleURL, setSimpleURL] = useState<string>();
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [devices, setDevices] = useState<Array<IProduct>>([]);
    const [device, setDevice] = useState<IProduct>({} as IProduct);
    const [devicesHidden, setDevicesHidden] = useState<boolean>(false);
    const [product, setProduct] = useState<IProduct>({ 'id': 0 } as IProduct);
    // const [priceText, setPriceText] = useState<string>();
    // const perYearText = ' / ' + t('myaccount.year');
    const [mode, setMode] = useState<number>(0);
    const [discountData, setDiscountData] = useState<any>();
    const [monthlyDiscount, setMonthlyDiscount] = useState<any>();

    const crumbs = [
        { 'text': t('page.parentHomepage'), 'href': '/my-account/profile-settings' },
        { 'text': t('page.myOrders'), 'href': '/my-account/orders' },
        { 'text': t('page.changePayment') }
    ];

    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    function showContent(content: string) {
        Api.get<Content>('content/' + content)
            .then(response => {
                displayModal(response);
            });
    }

    function getProductByRecurrenceAndPeriod(products: Array<IProduct>, is_recurring: number, period: number) {
        for (let index = 0; index < products!.length; index++) {
            const item = products![index];
            if (item.period === period && item.is_recurring === is_recurring) {
                return item;
            }
        }
    }

    function simplepayPayment() {
        Api.post<ISimplepay>('payment/change-membership', {
            body: JSON.stringify({
                product_id: product.id,
                mode: mode,
                device_id: mode === 0 ? device.id : 0,
                device_quantity: mode === 0 ? discountData.devices.quantity : 0,
                user_id: localStorage.getItem('user_id'),
            })
        }).then(response => {
            setSimpleURL(response);
        })
    }

    function setProductAndPricetext(products: Array<IProduct>) {
        const checked = isRecurringChecked === false ? 0 : 1;
        let result: any = {};
        result = getProductByRecurrenceAndPeriod(products, checked, 12);
        setProduct(result);
        // setPriceText(formatPrice(result.price) + perYearText);
    }

    function getDevicePrice() {
        const checked = isRecurringChecked === false ? 0 : 1;
        for (let index = 0; index < devices?.length; index++) {
            const item = devices[index];
            if (item.period === 12 && item.is_recurring === checked) {
                if (device.id !== item.id) {
                    setDevice(item);
                }
                return item.price;
            }
        }
        return 0;
    }

    useEffect(() => {
        if (products.length) {
            setProductAndPricetext(products);
        }
    }, [isRecurringChecked, products, setProductAndPricetext])

    useEffect(() => {
        getData<any>({
            apiUrl: 'payment/get-active-membership',
        }).then(response => {
            setDiscountData(response);
            if (response.devices?.quantity === 0) {
                setMode(1);
                setDevicesHidden(true);
            }
            setMonthlyDiscount(response.monthlyDiscount);

        });
    }, []);

    useEffect(() => {
        Product.getMemberships().then(
            (response) => {
                setProductAndPricetext(response);
                setProducts(response);
            }
        );
    }, []);

    useEffect(() => {
        Product.getDevices().then(
            (response) => {
                setDevices(response);
            }
        );
    }, []);

    useEffect(() => {
        getData<Content>({
            recordType: 'single',
            apiUrl: 'content/brainclub-tagsag',
            storageName: 'contents',
            storageCallback: (db) => db.table<Content>('contents').where('slug').equals('brainclub-tagsag').first()
        })
            .then(
                response => {
                    const content = response.content.split('<!-- pagebreak -->');
                    setContent(content);
                }
            );
    }, []);

    if (typeof simpleURL !== 'undefined') {
        window.location.href = simpleURL
    }

    function getPriceWithDevices() {
        let result: number = price;

        if (monthlyDiscount && monthlyDiscount.length > 0) {
            result -= monthlyDiscount.length * (price / 10);
        }

        if (discountData?.membership?.discount) {
            result -= discountData?.membership?.discount;
        }
        if (discountData?.devices?.quantity) {
            result += discountData?.devices?.quantity * getDevicePrice();
            if (monthlyDiscount && monthlyDiscount.length > 0) {
                result -= monthlyDiscount.length * (discountData?.devices?.quantity * getDevicePrice() / 10);
            }
        }

        return formatPrice(result);
    }

    function getPriceWithoutDevices() {
        let result: number = price;

        if (monthlyDiscount && monthlyDiscount.length > 0) {
            result -= monthlyDiscount.length * (price / 10);
        }

        if (discountData?.membership?.discount) {
            result -= discountData?.membership?.discount;
        }

        return formatPrice(result);
    }

    return (
        <Page>
            <Header />
            <IonContent fullscreen>

                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <IonHeader>
                        <IonToolbar mode="ios">
                            <IonTitle slot="start">{modalContent?.title}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    color="white"
                                    slot="end"
                                    onClick={() => setShowModal(false)}
                                >
                                    <IonIcon icon={closeOutline} size="large" color="white" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <h1>{modalContent?.title}</h1>
                        {modalContent && <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />}
                    </IonContent>
                </IonModal>

                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className="ion-margin-bottom ion-text-center">
                                <h2>{t('myaccount.yearlyMembership')}<br />-{yearlyDiscount}%</h2>
                                <IonText>
                                    <h2><b><span id={"price"}>{formatPrice(price)}</span></b></h2>
                                </IonText>
                                {content && <div dangerouslySetInnerHTML={{ __html: content[1] }} />}
                            </div>
                            <hr />
                            {/** Package price with devices */}
                            {!devicesHidden &&
                                <div className={'payment-details' + (mode === 0 ? ' active' : '')}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>{t('myaccount.yearlyMembership')}<br />
                                                    {t("paymentchange.withDevices")} ({discountData?.devices.quantity} db)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/** Package price */}
                                            <tr>
                                                <td>{t('myaccount.membershipPrice')}</td>
                                                <td>{formatPrice(price)}</td>
                                            </tr>
                                            {/** Monthly discount */}
                                            {monthlyDiscount && monthlyDiscount.length > 0 &&
                                                <Discounts devicePrice={discountData.devices.quantity * getDevicePrice()}
                                                    discounts={monthlyDiscount} price={price} />}
                                            {/** Membership discount */}
                                            {discountData && discountData.membership && discountData.membership.discount > 0 &&
                                                <tr>
                                                    <td>{t("paymentchange.membershipDiscount")}</td>
                                                    <td>{formatPrice(discountData.membership.discount)}</td>
                                                </tr>}
                                            {/** Devices */}
                                            {discountData && discountData.devices && <tr>
                                                <td>
                                                    {t("paymentchange.devicePrice")}<br />
                                                    {discountData.devices.quantity > 1 && <>
                                                        {discountData.devices.quantity} * {formatPrice(getDevicePrice())}&nbsp;=&nbsp;
                                                    </>}
                                                </td>
                                                <td>{formatPrice(discountData.devices.quantity * getDevicePrice())}</td>
                                            </tr>}
                                            {/** Summary */}
                                            <tr>
                                                <td>{t("paymentchange.summary")}</td>
                                                <td>{getPriceWithDevices()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="ion-text-center ion-margin-vertical">
                                        <IonButton onClick={e => setMode(0)}
                                            fill={mode === 0 ? 'solid' : 'outline'}>
                                            {mode === 1 && <IonIcon icon={squareOutline} />}
                                            {mode === 0 && <IonIcon icon={checkboxOutline} />}
                                            &nbsp;
                                            <IonLabel
                                                className="ion-text-left width-100">{t("paymentchange.pick")}</IonLabel>
                                        </IonButton>
                                    </div>
                                </div>}

                            {/** Package price without devices */}
                            {((discountData && discountData.membership && discountData.membership.discount) || !devicesHidden) &&
                                <div className={'payment-details' + (mode === 1 ? ' active' : '')}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    {t('myaccount.yearlyMembership')}<br />
                                                    {!devicesHidden && t("paymentchange.withoutDevices")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/** Package price */}
                                            <tr>
                                                <td>{t('myaccount.membershipPrice')}</td>
                                                <td>{formatPrice(price)}</td>
                                            </tr>
                                            {/** Monthly discount */}
                                            {monthlyDiscount && monthlyDiscount.length > 0 &&
                                                <Discounts devicePrice={discountData.devices.quantity * getDevicePrice()}
                                                    discounts={monthlyDiscount} price={price} />}
                                            {/** Membership discount */}
                                            {discountData && discountData.membership && discountData.membership.discount > 0 &&
                                                <tr>
                                                    <td>{t("paymentchange.membershipDiscount")}</td>
                                                    <td>{formatPrice(discountData.membership.discount)}</td>
                                                </tr>}
                                            {/** Summary */}
                                            <tr>
                                                <td>{t("paymentchange.summary")}</td>
                                                <td>{getPriceWithoutDevices()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="ion-text-center ion-margin-vertical">
                                        <IonButton onClick={e => setMode(1)}
                                            fill={mode === 1 ? 'solid' : 'outline'}>
                                            {mode === 0 && <IonIcon icon={squareOutline} />}
                                            {mode === 1 && <IonIcon icon={checkboxOutline} />}
                                            &nbsp;
                                            <IonLabel
                                                className="ion-text-left width-100">{t("paymentchange.pick")}</IonLabel>
                                        </IonButton>
                                    </div>
                                </div>}
                            {/**
                                     {!devicesHidden &&
                                    <div className="ion-margin-vertical">
                                        <h2>{t('myaccount.yearlyMembership')}<br/>{t("paymentchange.withDevices")} ({discountData?.devices.quantity} db)
                                        </h2>
                                        <IonText>
                                            <h2><b><span id={"price"}>{formatPrice(price)}</span></b></h2>
                                        </IonText>
                                        {monthlyDiscount && monthlyDiscount.length > 0 &&
                                        <Discounts devicePrice={discountData.devices.quantity * getDevicePrice()}
                                                   discounts={monthlyDiscount} price={price}/>}

                                        {discountData && discountData.membership && discountData.membership.discount > 0 && <>
                                            <IonText>
                                                <h3>
                                                    {t("paymentchange.membershipDiscount")}<br/>
                                                    {formatPrice(discountData.membership.discount)}
                                                </h3>
                                            </IonText>
                                        </>}

                                        {discountData && discountData.devices && <>
                                            <IonText>
                                                <h3>
                                                    {t("paymentchange.devicePrice")}<br/>
                                                    {discountData.devices.quantity > 1 && <>
                                                        {discountData.devices.quantity} * {formatPrice(getDevicePrice())}&nbsp;=&nbsp;
                                                    </>}
                                                    {formatPrice(discountData.devices.quantity * getDevicePrice())}
                                                </h3>
                                            </IonText>
                                        </>}

                                        <IonText>
                                            <h1>
                                                {t("paymentchange.summary")}<br/>
                                                {getPriceWithDevices()}
                                            </h1>
                                        </IonText>
                                        <IonButton onClick={e => setMode(0)} fill={mode === 0 ? 'solid' : 'outline'}>
                                            {mode === 1 && <IonIcon icon={squareOutline}/>}
                                            {mode === 0 && <IonIcon icon={checkboxOutline}/>}
                                            &nbsp;
                                            <IonLabel
                                                className="ion-text-left width-100">{t("paymentchange.pick")}</IonLabel>
                                        </IonButton>
                                    </div>
                                    }
                                     {((discountData && discountData.membership && discountData.membership.discount) || !devicesHidden) &&
                                    <div className="ion-margin-vertical">
                                        <h2>
                                            {t('myaccount.yearlyMembership')}<br/>
                                            {!devicesHidden && t("paymentchange.withoutDevices")}
                                        </h2>
                                        <IonText>
                                            <h2><b><span id={"price"}>{formatPrice(price)}</span></b></h2>
                                        </IonText>
                                        {monthlyDiscount && monthlyDiscount.length > 0 &&
                                        <Discounts devicePrice={0} discounts={monthlyDiscount} price={price}/>}

                                        {(discountData && discountData.membership && discountData.membership.discount > 0) &&
                                        <>
                                            <IonText>
                                                <h3>
                                                    {t("paymentchange.membershipDiscount")}<br/>
                                                    {formatPrice(discountData.membership.discount)}
                                                </h3>
                                            </IonText>
                                            <IonText>
                                                <h1>
                                                    {t("paymentchange.summary")}<br/>
                                                    {getPriceWithoutDevices()}
                                                </h1>
                                            </IonText>
                                        </>
                                        }


                                        <IonButton onClick={e => setMode(1)} fill={mode === 1 ? 'solid' : 'outline'}>
                                            {mode === 0 && <IonIcon icon={squareOutline}/>}
                                            {mode === 1 && <IonIcon icon={checkboxOutline}/>}
                                            &nbsp;
                                            <IonLabel
                                                className="ion-text-left width-100">{t("paymentchange.pick")}</IonLabel>
                                        </IonButton>
                                    </div>
                                    }
                                     */}

                            <IonListHeader className="ion-no-padding">
                                <IonButton
                                    size="large"
                                    fill="clear"
                                    expand="full"
                                    className="ion-no-padding"
                                >
                                    <IonIcon size="large" icon={cardOutline} />&nbsp;
                                    <span
                                        className="ion-text-left width-100">{t("myaccount.onlinePayment")}</span>
                                </IonButton>
                            </IonListHeader>
                            <IonList className="ion-no-padding ion-padding-start" lines="none">
                                <IonItem key='privacy'>
                                    <IonCheckbox
                                        slot="start"
                                        className="ion-no-margin ion-margin-end"
                                        checked={privacyChecked}
                                        onIonChange={e => setPrivacyChecked(e.detail.checked)}
                                    />
                                    <div className="toplayer">
                                        {t('myaccount.chkAccept')}&nbsp;
                                        <IonRouterLink onClick={() => showContent('adatkezelesi-tajekoztato')}>
                                            {t('myaccount.acceptPP')}
                                        </IonRouterLink>.
                                    </div>
                                </IonItem>
                                <IonItem key='terms'>
                                    <IonCheckbox
                                        slot="start"
                                        className="ion-no-margin ion-margin-end"
                                        checked={termsChecked}
                                        onIonChange={e => setTermsChecked(e.detail.checked)}
                                    />
                                    <div className="toplayer">
                                        {t('myaccount.chkAccept')}&nbsp;
                                        <IonRouterLink
                                            onClick={e => showContent('altalanos-szerzodesi-feltetelek')}
                                        >
                                            {t('myaccount.acceptTOS')}
                                        </IonRouterLink>.
                                    </div>
                                </IonItem>
                                <IonItem key='recurring'>
                                    <IonCheckbox
                                        slot="start"
                                        className="ion-no-margin ion-margin-end"
                                        checked={isRecurringChecked}
                                        onIonChange={e => setIsRecurringChecked(e.detail.checked)}
                                    />
                                    <div className="toplayer">
                                        {t('myaccount.acceptRecurring')}&nbsp;
                                        (<IonRouterLink
                                            onClick={e => showContent('tajekoztato-az-ismetlodo-fizetesrol')}
                                        >
                                            {t('myaccount.recurringConditions')}
                                        </IonRouterLink>)
                                    </div>
                                </IonItem>
                                <IonItem>
                                    <IonButton
                                        disabled={privacyChecked && termsChecked ? false : true}
                                        size="default"
                                        className="ion-text-wrap"
                                        onClick={simplepayPayment}
                                    >
                                        {t('myaccount.paymentWithCreditCard')}
                                    </IonButton>
                                    <IonRouterLink
                                        href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                                        target="_blank"
                                        slot="end"
                                    >
                                        <img src="/images/simplepay_logo_color.png" />
                                    </IonRouterLink>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    )
        ;
}

export default PaymentChange;