import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import {IonCol, IonContent, IonGrid, IonPage, IonRow, IonToast} from "@ionic/react";
import Product from "./Product";
import Personal from "./Personal";
import Billing from "./Billing";
import Payment from "./Payment";
import useProduct from "../../hooks/useProduct";
import useHasActivePayment from "../../hooks/useHasActivePayment";
import {Redirect} from "react-router-dom";
import IProfile from "../../interfaces/IProfile";
import Profile from "../../services/profile";
import {useTranslation} from "react-i18next";
import Storage from "../../services/Storage";
import {getAge} from "../../services/dateHelper";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

const requiredInputs: Array<Array<keyof IProfile>> = [
    ['lastname', 'firstname', 'phone', 'birthday'],
    ['city', 'postcode', 'street', 'housenumber',],
    []
];

const OrderPremiumPage = () => {
    const {t} = useTranslation();
    const {canBuy} = useHasActivePayment();

    const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(false);

    const {period, products, product, price, priceText} = useProduct(canBuy, isRecurringChecked)

    const [profileDetails, setProfileDetails] = useState<IProfile>({} as IProfile);
    const [active, setActive] = useState(1);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [requiredMissing, setRequiredMissing] = useState<boolean>(true);

    useEffect(() => {
        console.log(isRecurringChecked)
    }, [isRecurringChecked]);

    useEffect(() => {
        if (!Profile.getId()) {
            if (localStorage.getItem('hasProfiles')) {
                Profile.getProfiles().then(response => {
                    for (let profile of response) {
                        if (profile.type === 0) {
                            setProfileDetails(profile)
                            break
                        }
                    }
                })
            }
        } else {
            Profile.getProfileData().then(response =>
                setProfileDetails(response)
            )
        }
    }, [canBuy]);

    const checkRequiredActive = (index: number) => {
        const errors = requiredInputs[index - 1].reduce((result: string[], item: keyof IProfile) => {
            if (profileDetails && !profileDetails[item]) {
                result.push(t('myaccount.' + item));
            }
            return result;
        }, []);

        if (errors.length) {
            setErrorMessage(t('myaccount.validationError') + "\n" + errors.join("\n"));
            return false;
        }
        return true;
    }

    const selectActive = (index: number) => {
        if (checkRequiredActive(active)) {
            setActive(index);
        }
    }

    const getInputPage = (input: keyof IProfile) => {
        for (let [index, value] of requiredInputs.entries()) {
            if (value.includes(input)) {
                return index + 1
            }
        }
        return 0
    }

    const setProfileDetailByKey = (key: keyof IProfile, value: any) => {
        if (typeof value === "undefined" || value === null) {
            return;
        }
        if (key === "birthday") {
            const age = getAge(value);
            if (age < 18) {
                setErrorMessage(t('myaccount.over18'));
                value = null;
            }
        }
        setProfileDetails(prevState => ({...prevState, [key]: value}));
    }

    useEffect(() => {
        let result = true;
        for(const index of (requiredInputs.flat())) {
            if (typeof(profileDetails[index] === 'undefined' || profileDetails[index] === null || profileDetails[index] == '')) {
                result = false;
                break;
            }
        }
        setRequiredMissing(
            result
        );
    }, [profileDetails]);

    const storeData = (inputName: keyof IProfile) => {
        const data = {
            storageName: 'profiles',
            apiUrl: 'profile/save',
            apiData: {
                records: [
                    {
                        id: profileDetails.id,
                        [inputName]: profileDetails[inputName]
                    }
                ]
            },
            methodType: 'post'
        };
        Storage.update(data).catch(error => {
            let message = error[0].errors.join('<br />');
            setErrorMessage(message);
            setActive(getInputPage(inputName));
        });
    }


    // Ha gyerek profil van kiválasztva, átirányítás
    if (localStorage.getItem('profile_type') === '1') {
        return <Redirect to="/my-account/profile-select"/>
    }

    return (
        <IonPage>
            <Header/>
            <IonContent fullscreen>
                <ErrorMessage message={errorMessage} setMessage={setErrorMessage} />
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <Product period={period} price={price} priceText={priceText} product={product}/>
                            <Personal
                                profileDetails={profileDetails}
                                setProfileDetailByKey={setProfileDetailByKey}
                                active={active}
                                selectActive={selectActive}
                                storeData={storeData}
                            />
                            <Billing
                                profileDetails={profileDetails}
                                setProfileDetailByKey={setProfileDetailByKey}
                                active={active}
                                selectActive={selectActive}
                                storeData={storeData}
                            />
                            <Payment
                                active={active}
                                setActive={setActive}
                                selectActive={selectActive}
                                setErrorMessage={setErrorMessage}
                                checkRequiredActive={checkRequiredActive}
                                product={product}
                                isRecurringChecked={isRecurringChecked}
                                setIsRecurringChecked={setIsRecurringChecked}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default OrderPremiumPage;