export function getAge(DOB: any) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
export function convertToDate(datetime: Date) {
    return datetime.getFullYear() + '-' + (datetime.getMonth() + 1).toString().padStart(2, '0') + '-' + datetime.getDate().toString().padStart(2, "0")
}