import {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    IonToast,
    IonRouterLink,
    IonCheckbox,
    IonLabel,
    IonModal,
    IonButtons,
    IonCol,
    IonGrid,
    IonRow
} from '@ionic/react';
import React, { useState } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import './Registration.scss';
import '../../i18';
import { useTranslation } from 'react-i18next';
import Api from "../../api";
// import { SocialiteUrl } from "../../interfaces/auth/SocialiteUrl";
import { LoginToken } from "../../interfaces/auth/LoginToken";
import { Content } from "../../interfaces/Content";
import { closeOutline } from "ionicons/icons";
import TextWithBorder from '../../components/TextWithBorder/TextWithBorder';
import Header from "../../components/Header/Header";

const Registration = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>();
    const [emailConfirm, setEmailConfirm] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordConfirm, setPasswordConfirm] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [aszfChecked, setAszfChecked] = useState(false);
    const [modalContent, setModalContent] = useState<Content>();
    const [over18Checked, setOver18Checked] = useState(false);
    let history = useHistory();
    let invitation_token = localStorage.getItem('invitation-token');

    if (localStorage.getItem('token')) {
        return <Redirect to="/home" />
    }

    function displayToast(message: string) {
        setErrorMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);
    }

    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    // function loginWithFacebook() {
    //     Api.get<SocialiteUrl>('login/facebook')
    //         .then(response => {
    //             window.location.replace(response.url);
    //         });
    // }
    //
    // function loginWithGoogle() {
    //     Api.get<SocialiteUrl>('login/google')
    //         .then(response => {
    //             window.location.replace(response.url);
    //         });
    // }

    function showAszf() {
        Api.get<Content>('content/adatkezelesi-tajekoztato')
            .then(response => {
                displayModal(response);
            });
    }

    const submitRegistration = () => {
        if (!aszfChecked) {
            displayToast(t('registration.mustCheckAszf'));
            return;
        }
        if (!over18Checked) {
            displayModal({
                id: 0,
                title: t('registration.over18PopupTitle'),
                content: t('registration.over18PopupContent')
            });
            return;
        }

        Api.post<LoginToken>('registration', {
            body: JSON.stringify({
                email: email,
                email_confirmation: emailConfirm,
                password: password,
                password_confirmation: passwordConfirm,
                invitation_token: invitation_token
            })
        })
            .then(response => {
                history.push('/email-confirm/' + response.token);
            })
            .catch(error => {
                let message = "";
                if (typeof error.errors.email !== 'undefined') {
                    message = error.errors.email.join('<br />');
                }
                if (typeof error.errors.password !== 'undefined') {
                    if (message) {
                        message += '<br />';
                    }
                    message += error.errors.password.join('<br />');
                }
                displayToast(message);
            })
            ;
    }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={errorMessage}
                    position="top"
                    color="danger"
                />

                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <IonHeader>
                        <IonToolbar mode="ios">
                            <IonTitle slot="start">{modalContent?.title}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    slot="end"
                                    onClick={() => setShowModal(false)}
                                >
                                    <IonIcon icon={closeOutline} size="large" color='white' />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <h1>{modalContent?.title}</h1>

                        {modalContent && <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />}
                    </IonContent>
                </IonModal>

                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className='ion-margin-vertical'>
                                <IonRouterLink href="/login">
                                    {t('registration.loginLink')}
                                </IonRouterLink>
                                {t('registration.loginNote')}!
                            </div>
                            <div className="ion-margin-vertical ion-padding-top">
                                <TextWithBorder text={t('Create account')} key="label" />
                            </div>
                            <IonLabel>
                                {t('registration.Email')}
                            </IonLabel>
                            <IonInput
                                value={email}
                                onIonChange={e => setEmail(e.detail.value!)}
                            />
                            <IonLabel>
                                {t('registration.emailConfirm')}
                            </IonLabel>
                            <IonInput
                                value={emailConfirm}
                                onIonChange={e => setEmailConfirm(e.detail.value!)}
                            />
                            <IonLabel>
                                {t('registration.Password')}
                            </IonLabel>
                            <IonInput
                                type="password"
                                value={password}
                                onIonChange={e => setPassword(e.detail.value!)}
                            />
                            <IonLabel>
                                {t('registration.PasswordConfirm')}
                            </IonLabel>
                            <IonInput
                                type="password"
                                value={passwordConfirm}
                                onIonChange={e => setPasswordConfirm(e.detail.value!)}
                            />
                            <IonList lines="none">
                                <IonItem key='terms' className='ion-no-padding'>
                                    <IonCheckbox
                                        slot="start"
                                        className="ion-no-margin ion-margin-end"
                                        checked={aszfChecked}
                                        onIonChange={e => setAszfChecked(e.detail.checked)}
                                    />
                                    <div className="toplayer">
                                        {t('registration.acceptAszf')}&nbsp;
                                        <IonRouterLink onClick={showAszf}>
                                            {t('registration.aszfLink')}
                                        </IonRouterLink>.
                                    </div>
                                </IonItem>
                                <IonItem key="over18" className='ion-no-padding'>
                                    <IonCheckbox
                                        slot="start"
                                        className="ion-no-margin ion-margin-end"
                                        checked={over18Checked}
                                        onIonChange={e => setOver18Checked(e.detail.checked)}
                                    />
                                    <IonLabel className="ion-text-wrap">
                                        {t('registration.over18')}
                                    </IonLabel>
                                </IonItem>
                            </IonList>
                            <IonButton
                                className='width-100'
                                size="default"
                                onClick={submitRegistration}>
                                {t('registration.submit')}
                            </IonButton>

                            {/*<IonButton*/}
                            {/*    className="ion-margin-top"*/}
                            {/*    expand="full"*/}
                            {/*    onClick={loginWithFacebook}*/}
                            {/*    size="default"*/}
                            {/*>*/}
                            {/*    <IonIcon slot="start" icon={logoFacebook} />*/}
                            {/*    {t('registration.continueWithFacebook')}*/}
                            {/*</IonButton>*/}
                            {/*<IonButton*/}
                            {/*    className="ion-margin-vertical"*/}
                            {/*    expand="full"*/}
                            {/*    onClick={loginWithGoogle}*/}
                            {/*    size="default"*/}
                            {/*>*/}
                            {/*    <IonIcon slot="start" icon={logoGoogle} />*/}
                            {/*    Google*/}
                            {/*</IonButton>*/}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Registration;