import React, {Dispatch, SetStateAction, useState} from "react";
import {IonButton, IonCheckbox, IonIcon, IonItem, IonList, IonListHeader, IonRouterLink} from "@ionic/react";
import {cardOutline, chevronDown, chevronForward} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import Api from "../../api";
import {Content} from "../../interfaces/Content";
import ContentService from "../../services/ContentService";
import Modal from "../../components/Modal/Modal";
import ISimplepay from "../../interfaces/ISimplepay";
import IProduct from "../../interfaces/IProduct";

const Payment = (
    {
        active,
        setActive,
        selectActive,
        setErrorMessage,
        checkRequiredActive,
        product,
        isRecurringChecked,
        setIsRecurringChecked
    }: {
        active: number,
        setActive: Dispatch<SetStateAction<number>>,
        selectActive: (index: number) => void,
        setErrorMessage: Dispatch<SetStateAction<string>>,
        checkRequiredActive: (index: number) => boolean,
        product: IProduct,
        isRecurringChecked: boolean,
        setIsRecurringChecked: Dispatch<SetStateAction<boolean>>,
    }
) => {
    const {t} = useTranslation();
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>({} as Content);
    const [apiCall, setApiCall] = useState<boolean>(false);

    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    function showContent(content: string) {
        ContentService.get(content)
            .then(response => {
                displayModal(response);
            });
    }

    const simplepayPayment = () => {
        if (!privacyChecked) {
            setErrorMessage(t('myaccount.privacyRequired'));
            return false;
        }
        if (!termsChecked) {
            setErrorMessage(t('myaccount.termsRequired'));
            return false;
        }

        for (let index = 1; index < 3; index++) {
            if (!checkRequiredActive(index)) {
                setActive(index);
                return false;
            }
        }
        setApiCall(true);
        Api.post<ISimplepay>('payment/simplepay', {
            body: JSON.stringify({
                product_id: product.id,
                user_id: localStorage.getItem('user_id'),
            })
        }).then(response => {
            window.location.href = response
        })
    }

    return (
        <div className="glass">
            <Modal content={modalContent} show={showModal} setShow={setShowModal}/>
            <IonListHeader className="ion-no-padding">
                <IonButton
                    expand="full"
                    className="ion-no-margin"
                    onClick={(e) => {
                        e.preventDefault();
                        selectActive(3)
                    }}
                    size="large"
                >
                    <IonIcon icon={cardOutline} size="large"/>&nbsp;
                    <span className="ion-text-left width-100">{t("myaccount.onlinePayment")}</span>
                    {active === 3 ? <IonIcon icon={chevronDown}/> :
                        <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList
                className={active === 3 ? 'ion-padding-horizontal ion-margin-bottom' : 'ion-hide'}
                lines="none">
                <IonItem key='privacy'>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={privacyChecked}
                        onIonChange={e => setPrivacyChecked(e.detail.checked)}
                    />
                    <div className="toplayer">
                        {t('myaccount.chkAccept')}&nbsp;
                        <IonRouterLink
                            onClick={e => showContent('adatkezelesi-tajekoztato')}
                        >
                            {t('myaccount.acceptPP')}
                        </IonRouterLink>.
                    </div>
                </IonItem>
                <IonItem key='terms'>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={termsChecked} onIonChange={e => setTermsChecked(e.detail.checked)}
                    />
                    <div className="toplayer">
                        {t('myaccount.chkAccept')}&nbsp;
                        <IonRouterLink
                            onClick={e => showContent('altalanos-szerzodesi-feltetelek')}
                        >
                            {t('myaccount.acceptTOS')}
                        </IonRouterLink>.
                    </div>
                </IonItem>
                <IonItem key='recurring'>
                    <IonCheckbox
                        slot="start"
                        className="ion-no-margin ion-margin-end"
                        checked={isRecurringChecked}
                        onIonChange={e => setIsRecurringChecked(e.detail.checked)}
                    />
                    <div className="toplayer">
                        {t('myaccount.acceptRecurring')}&nbsp;
                        (<IonRouterLink
                        onClick={e => showContent('tajekoztato-az-ismetlodo-fizetesrol')}
                    >
                        {t('myaccount.recurringConditions')}
                    </IonRouterLink>)
                    </div>
                </IonItem>
                <IonItem key="button" className="ion-margin-top">
                    <IonButton
                        disabled={!(privacyChecked && termsChecked) || apiCall}
                        size="default"
                        className="ion-text-wrap"
                        onClick={simplepayPayment}
                    >
                        {t('myaccount.paymentWithCreditCard')}
                    </IonButton>
                    <IonRouterLink
                        href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                        target="_blank"
                        slot="end"
                    >
                        <img src="/images/simplepay_logo_color.png" alt={t('myaccount.simplepayTerms')}
                             title={t('myaccount.simplepayTerms')}/>
                    </IonRouterLink>
                </IonItem>
            </IonList>
        </div>
    )
}
export default Payment