import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    IonApp,
    IonRouterOutlet
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';

import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Logout from "./pages/Logout/Logout";
import Registration from "./pages/Registration/Registration";
import FacebookLogin from "./pages/FacebookLogin/FacebookLogin";
import GoogleLogin from "./pages/GoogleLogin/GoogleLogin";
import Gyik from "./pages/Gyik/Gyik";
import MyAccount from "./pages/MyAccount/MyAccount";
import Welcome from "./pages/Welcome/Welcome";
import GetPremium from "./pages/GetPremium/GetPremium";
import OrderPremium from "./pages/OrderPremium/OrderPremium";
import SimplepayBack from "./pages/Payment/SimplepayBack";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import PaymentError from "./pages/PaymentError/PaymentError";
import ProfileSelection from "./pages/ProfileSelect/ProfileSelect";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";
import EmailConfirm from "./pages/EmailConfirm/EmailConfirm";
import Grades from "./pages/Grades/Grades";
import Subjects from "./pages/Subjects/Subjects";
import Topics from "./pages/Topics/Topics";
import Videos from "./pages/Videos/Videos";
import BrainChecks from "./pages/BrainChecks/BrainChecks";
import NetworkProblem from "./pages/NetworkProblem/NetworkProblem";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.scss';

import checkDbInvalidation from "./services/checkDbInvalidation";
import Invite from "./pages/Invite/Invite";
import Search from "./pages/Search/Search";

import BuyNewDevice from "./pages/BuyNewDevice/BuyNewDevice";
import DeviceCheck from "./pages/DeviceCheck/DeviceCheck";
import NewDevice from "./pages/NewDevice/NewDevice";
import ParentDefaultPage from "./pages/ParentDefaultPage/ParentDefaultPage";
import PaymentChange from "./pages/PaymentChange/PaymentChange";
import PaymentDelete from "./pages/PaymentDelete/PaymentDelete";
import PaymentDeleted from "./pages/PaymentDeleted/PaymentDeleted";
import DeviceDelete from "./pages/DeviceDelete/DeviceDelete";
import ChildDefaultMain from "./pages/ChildDefaultMain/ChildDefaultMain";
import ChildDefaultSubjects from "./pages/ChildDefaultSubjects/ChildDefaultSubjects";
import ChildDefaultInvite from "./pages/ChildDefaultInvite/ChildDefaultInvite";
import ChildDefaultWatchedVideos from "./pages/ChildDefaultWatchedVideos/ChildDefaultWatchedVideos";
import Orders from "./pages/Orders/Orders";
import RetryPayment from "./pages/RetryPayment/RetryPayment";
import RenewPayment from "./pages/RenewPayment/RenewPayment";
import ResetApp from "./pages/ResetApp/ResetApp";
import BugFound from "./pages/BugFound/BugFound";
import CustomerService from "./pages/CustomerService/CustomerService";
import { DataProvider } from './context/DataContext';
import IProfile from "./interfaces/IProfile";
import Profile from "./services/profile";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LostPassword from "./pages/LostPassword/LostPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import MenuWidget from "./components/MenuWidget/MenuWidget";

import { setupConfig } from '@ionic/react'
import NoProfileHome from "./pages/NoProfileHome/NoProfileHome";
import ChildProfiles from "./pages/ChildProfiles/ChildProfiles";
import Devices from "./pages/Devices/Devices";
import Page404 from "./pages/Page404/Page404";
import Content from "./pages/Content/Content";
import PaymentHelp from "./pages/PaymentHelp/PaymentHelp";
import CreateParentProfile from "./pages/CreateParentProfile/CreateParentProfile";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import OrderPremiumPage from "./pages/OrderPremiumPage/OrderPremiumPage";
import RenewPaymentPage from "./pages/RenewPaymentPage/RenewPaymentPage";

setupConfig({ mode: 'ios' })



const App = () => {
    const [searchText, setSearchText] = useState<string>('');
    const [profile, setProfile] = useState<IProfile>({} as IProfile);

    useEffect(() => {
        checkDbInvalidation();
    }, []);

    useEffect(() => {
        if (Profile.getId()) {
            Profile.getProfileData().then(response => {
                setProfile(response);
            });
        }
    }, [])

    const initialData = {
        searchText, setSearchText,
        profile, setProfile
    }


    return (
        <IonApp className={'has-background'}>
            <DataProvider value={initialData}>
                <MenuWidget/>
                <IonReactRouter>
                    <IonRouterOutlet id="menuContent">

                        <Route path="/home" component={Home} exact={true}/>
                        <ProtectedRoute path="/grades" component={Grades}/>
                        <ProtectedRoute path="/subjects" component={Subjects}/>
                        <ProtectedRoute path="/topics" component={Topics} exact={true}/>
                        <ProtectedRoute path="/topics/:topicId" component={Topics}/>
                        <ProtectedRoute path="/videos" component={Videos} exact={true}/>
                        <ProtectedRoute path="/videos/:topicId" component={Videos}/>
                        <ProtectedRoute path="/brain-check/:topic" component={BrainChecks}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/logout" component={Logout}/>
                        <Route path="/login/facebook/callback" component={FacebookLogin}/>
                        <Route path="/login/google/callback" component={GoogleLogin}/>
                        <Route path="/registration" component={Registration}/>
                        <Route path="/email-confirm/:token" component={EmailConfirm}/>
                        <Route path="/lost-password" component={LostPassword}/>
                        <Route path="/reset-password/:token" component={ResetPassword}/>
                        <ProtectedRoute path="/gyik" component={Gyik}/>
                        <ProtectedRoute path="/invite/:token" component={Invite}/>
                        <ProtectedRoute path="/my-account/profile-settings" component={ProfileSettings}/>
                        <ProtectedRoute path="/my-account/profile-select" component={ProfileSelection}/>
                        <ProtectedRoute path="/payment/simplepay/back" component={SimplepayBack}/>
                        <ProtectedRoute path="/my-account/payment-error" component={PaymentError}/>
                        <ProtectedRoute path="/my-account/payment-success" component={PaymentSuccess}/>
                        <ProtectedRoute path="/my-account/payment-change" component={PaymentChange}/>
                        <ProtectedRoute path="/my-account/payment-delete" component={PaymentDelete}/>
                        <ProtectedRoute path="/my-account/payment-deleted" component={PaymentDeleted}/>
                        <ProtectedRoute path="/my-account/payment-help" component={PaymentHelp}/>
                        <ProtectedRoute path="/my-account/order-premium" component={OrderPremium}/>
                        <ProtectedRoute path="/my-account/get-premium" component={GetPremium}/>
                        <ProtectedRoute path="/my-account/default/parent" component={ParentDefaultPage}/>
                        <ProtectedRoute path="/my-account/create-parent-profile" component={CreateParentProfile}/>

                        <ProtectedRoute path="/my-account/child-profiles" component={ChildProfiles}/>

                        <ProtectedRoute path="/my-account/orders" component={Orders} />
                        <ProtectedRoute path="/devices" component={Devices} />

                        <ProtectedRoute path="/my-account/no-profile-home" component={NoProfileHome} />

                        <ProtectedRoute path="/my-account/default/child" component={ChildDefaultMain} />
                        <ProtectedRoute path="/my-account/default/child/subjects" component={ChildDefaultSubjects} />
                        <ProtectedRoute path="/my-account/invite" component={ChildDefaultInvite} />
                        <ProtectedRoute path="/my-account/default/child/watched-videos" component={ChildDefaultWatchedVideos} />

                        <ProtectedRoute path="/my-account/retry-payment/:transactionId" component={RetryPayment} />
                        <ProtectedRoute path="/my-account/renew-payment" component={RenewPayment} />

                        <ProtectedRoute path="/my-account/welcome" component={Welcome} />
                        <ProtectedRoute path="/my-account" component={MyAccount} exact={true} />
                        <ProtectedRoute path="/device-check" component={DeviceCheck} />
                        <ProtectedRoute path="/buy-new-device" component={BuyNewDevice} />
                        <ProtectedRoute path="/new-device" component={NewDevice} />
                        <ProtectedRoute path="/device-delete" component={DeviceDelete} />
                        <ProtectedRoute path="/np" component={NetworkProblem} />
                        <Route path="/reset-app" component={ResetApp} />
                        <ProtectedRoute path="/content/:alias" component={Content} />
                        <ProtectedRoute path="/search" component={Search} />
                        <ProtectedRoute path="/search/:keywords" component={Search} />
                        <ProtectedRoute path="/bugfound" component={BugFound} />
                        <ProtectedRoute path="/404" component={Page404} />
                        <Route path="/customerservice" component={CustomerService} />

                        {/*Új fizetési folyamatok*/}
                        <ProtectedRoute path="/welcome" component={WelcomePage} />
                        <ProtectedRoute path="/order-premium" component={OrderPremiumPage} />
                        <ProtectedRoute path="/renew-payment" component={RenewPaymentPage} />



                        <Route exact path="/" render={() => <Redirect to="/home" />} />
                        <Route component={Page404} />

                    </IonRouterOutlet>
                </IonReactRouter>

            </DataProvider>
        </IonApp>
    )
}

export default App;
