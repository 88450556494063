import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import ContentService from "../../services/ContentService";
import {IonButton, IonCol, IonRow} from "@ionic/react";
import useHasActivePayment from "../../hooks/useHasActivePayment";
import useProduct from "../../hooks/useProduct";
import formatPrice from "../../helpers/formatPrice";

const GetPremiumComponent = () => {
    const {t} = useTranslation();
    let history = useHistory();
    const [content, setContent] = useState<Array<string>>();

    const {canBuy} = useHasActivePayment();

    let isRecurringChecked = false;

    const {period, products, price, priceText} = useProduct(canBuy, isRecurringChecked);

    useEffect(() => {
        if (canBuy) {
            ContentService.get('brainclub-tagsag')
                .then(
                    response => {
                        const content = response.content.split('<!-- pagebreak -->');
                        setContent(content);
                    }
                );
        }
    }, [canBuy])



    const orderPremium = () => {
        history.push('/order-premium');
    }

    return (
        <>
            <div className="ion-margin-top glass">
                <div className="ion-padding-start ion-padding-end">
                    <div className="ion-padding-bottom">
                        {content && <div id="content" dangerouslySetInnerHTML={{__html: content[0]}}/>}
                    </div>
                    <IonRow>
                        <IonCol>
                            <p className="text-large ion-text-center ion-margin-bottom"><span
                                id={"price"}>{formatPrice(price)}{priceText}</span></p>
                            {content && <div dangerouslySetInnerHTML={{__html: content[1]}}/>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={orderPremium}>{t('next')}</IonButton>
                        </IonCol>
                    </IonRow>
                </div>
            </div>
        </>
    );
}

export default GetPremiumComponent;
// import {useTranslation} from "react-i18next";
// import {Redirect, useHistory} from "react-router-dom";
// import React, {useEffect, useState} from "react";
// import config from "../../config/config";
// import Api from "../../api";
// import getData from "../../services/getData";
// import {Content} from "../../interfaces/Content";
// import Storage from "../../services/Storage";
// import IProfile from "../../interfaces/IProfile";
// import {IonButton, IonCol, IonRow, IonSegment, IonSegmentButton} from "@ionic/react";
// import IProduct from "../../interfaces/IProduct";
// import Product from "../../services/product";
//
// const GetPremiumComponent = () => {
//     const {t} = useTranslation();
//     let history = useHistory();
//     const [canBuy, setCanBuy] = useState<boolean>(true);
//     const [content, setContent] = useState<Array<string>>();
//     const [subscription, setSubscription] = useState(false);
//     const [products, setProducts] = useState<Array<IProduct>>([]);
//     const [price, setPrice] = useState<string>();
//     const yearlyDiscount = config("myaccount.yearlyDiscount");
//     let userId = localStorage.getItem('user_id');
//
//     function getProductByRecurrenceAndPeriod(products: Array<IProduct>, is_recurring: number, period: number) {
//         for (let index = 0; index < products!.length; index++) {
//             const item = products![index];
//             if (item.period === period && item.is_recurring === is_recurring) {
//                 return item;
//             }
//         }
//     }
//
//     useEffect(() => {
//         Api.get<boolean>('payment/has-active-payment')
//             .then(response => {
//                 if (response) {
//                     setCanBuy(false);
//                 } else {
//                     getData<Content>({
//                         recordType: 'single',
//                         apiUrl: 'content/brainclub-tagsag',
//                         storageName: 'contents',
//                         storageCallback: (db) => db.table<Content>('contents').where('slug').equals('brainclub-tagsag').first()
//                     })
//                         .then(
//                             response => {
//                                 const content = response.content.split('<!-- pagebreak -->');
//                                 setContent(content);
//                             }
//                         );
//                     Product.getMemberships().then(
//                         (response) => {
//                             let result: IProduct | undefined;
//                             setProducts(response);
//                             if (false && localStorage.hasOwnProperty('yearlyMembership') && localStorage.getItem('yearlyMembership') === 'true') {
//                                 result = getProductByRecurrenceAndPeriod(response, 0, 12);
//                                 setPrice(formatPrice(result!.price, 'year'));
//                             } else {
//                                 result = getProductByRecurrenceAndPeriod(response, 0, 1);
//                                 setPrice(formatPrice(result!.price, 'month'));
//                             }
//                         }
//                     );
//
//                     // if (localStorage.hasOwnProperty('yearlyMembership') && localStorage.getItem('yearlyMembership') === 'true') {
//                     //     setSubscription(true);
//                     //     const price = config("myaccount.yearlyPrice");
//                     //     setPrice(formatPrice(price, 'year'));
//                     // } else {
//                     //     localStorage.setItem('yearlyMembership', 'false');
//                     //     const price = config("myaccount.monthlyPrice");
//                     //     setPrice(formatPrice(price, 'month'));
//                     // }
//                 }
//             })
//     }, []);
//
//     function setMebershipType() {
//         let result: IProduct | undefined;
//         setSubscription(!subscription);
//         localStorage.setItem('yearlyMembership', String(!subscription));
//         if (subscription) {
//             // const price = config("myaccount.monthlyPrice");
//             // setPrice(formatPrice(price, 'month'));
//             result = getProductByRecurrenceAndPeriod(products, 0, 12);
//             setPrice(formatPrice(result!.price, 'year'));
//         } else {
//             // const price = config("myaccount.yearlyPrice");
//             // setPrice(formatPrice(price, 'year'));
//             result = getProductByRecurrenceAndPeriod(products, 0, 1);
//             setPrice(formatPrice(result!.price, 'month'));
//         }
//     }
//
//     function formatPrice(price: number, type: string) {
//         const perType = t('myaccount.' + type);
//         return new Intl.NumberFormat(
//             'hu-HU',
//             {
//                 style: 'currency',
//                 currency: 'HUF',
//                 maximumFractionDigits: 0,
//                 minimumFractionDigits: 0
//             }).format(price) + ' / ' + perType;
//     }
//
//     function redirectToOrderPage(profile_id: string, mode: string) {
//         const data = {
//             storageName: 'profiles',
//             apiUrl: 'profile/save',
//             apiData: {
//                 records: [
//                     {
//                         type: 0,
//                         subscription: subscription ? 1 : 0
//                     }
//                 ]
//             },
//             methodType: 'post'
//         };
//         if (mode === 'update') {
//             Object.assign(data.apiData.records[0], {id: profile_id})
//             Storage.update(data).then(() => {
//                 history.push('/my-account/order-premium');
//             });
//         } else {
//             Storage.create(data).then((response: any) => {
//                 localStorage.setItem('profile_id', String(response[0]));
//                 history.push('/my-account/order-premium');
//             });
//         }
//     }
//
//     function orderPremium() {
//         let mode = 'update';
//         const profile_id = localStorage.getItem('profile_id');
//         if (profile_id !== null) {
//             return redirectToOrderPage(profile_id, mode);
//         }
//         Storage.get<Array<IProfile>>({
//             apiUrl: 'profile/list',
//             storageName: 'profiles',
//             storageCallback: (db) => {
//                 db.table<IProfile>('profiles').where('user_id').equals(userId!).sortBy('created_at');
//             }
//         }).then(response => {
//             if (response.length > 0) {
//                 localStorage.setItem('profile_id', String(response[0].id));
//                 redirectToOrderPage(String(response[0].id), 'update');
//             } else {
//                 redirectToOrderPage('', 'create');
//             }
//         });
//     }
//
//     if (!localStorage.getItem('token')) {
//         return <Redirect to="/login"/>
//     }
//
//     if (!canBuy) {
//         return <Redirect to="/home"/>
//     }
//
//     return (
//         <>
//             <div className="ion-margin-top glass">
//                 <div className="ion-padding-start ion-padding-end">
//                     <div className="ion-padding-bottom">
//                         {content && <div id="content" dangerouslySetInnerHTML={{__html: content[0]}}/>}
//                     </div>
//                     {/*<IonSegment*/}
//                     {/*    className="ion-margin-top"*/}
//                     {/*    onIonChange={e => setMebershipType()}*/}
//                     {/*    value={subscription === true ? 'yearly' : 'monthly'}*/}
//                     {/*>*/}
//                     {/*    <IonSegmentButton value="monthly">*/}
//                     {/*        <p>{t('myaccount.monthlyMembership')}</p>*/}
//                     {/*    </IonSegmentButton>*/}
//                     {/*    <IonSegmentButton value="yearly">*/}
//                     {/*        <p>{t('myaccount.yearlyMembership')} -{yearlyDiscount}%</p>*/}
//                     {/*    </IonSegmentButton>*/}
//                     {/*</IonSegment>*/}
//                     <IonRow>
//                         <IonCol>
//                             <p className="text-large ion-text-center ion-margin-bottom"><span
//                                 id={"price"}>{price}</span></p>
//                             {content && <div dangerouslySetInnerHTML={{__html: content[1]}}/>}
//                         </IonCol>
//                     </IonRow>
//                     <IonRow>
//                         <IonCol className="ion-text-center">
//                             <IonButton onClick={orderPremium}>{t('next')}</IonButton>
//                         </IonCol>
//                     </IonRow>
//                 </div>
//             </div>
//         </>
//     );
// }
//
// export default GetPremiumComponent;