import {
    IonButton, IonCard, IonCardContent,
    IonChip, IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRow,
    IonToggle
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useHistory, useParams } from "react-router-dom";
import { Subject } from "../../interfaces/Subject";
import Storage from "../../services/Storage";
import { useTranslation } from "react-i18next";
import { banOutline, checkmarkOutline, chevronDown, chevronForward, cogOutline, personOutline, searchOutline } from "ionicons/icons";
import { Grade } from "../../interfaces/Grade";

import './Search.scss';
import DataContext from "../../context/DataContext";
import Api from "../../api";
import db from "../../services/db";
import Video from "../../interfaces/Video";
import Topic from "../../interfaces/Topic";
import Page from "../../components/Page/Page";

interface SearchResult {
    id: number;
    name: string;
    description: string
    relevance: number;
    grade_id: number;
    grade_name: string;
    subject_id: number;
    subject_name: string;
    video_id: number;
    thumbnail?: string;
    thumbnailToken?: string;
}

const Search = () => {
    const { t } = useTranslation();
    let { keywords } = useParams();
    const [subjectsIsLoaded, setSubjectsIsLoaded] = useState<boolean>(false);
    const [gradesIsLoaded, setGradesIsLoaded] = useState<boolean>(false);
    const [form, setForm] = useState<boolean>(false);
    const [filterSubjects, setFilterSubjects] = useState<boolean>(true);
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [subjectsFilterStates, setSubjectsFilterStates] = useState<boolean[]>([]);
    const [filterGrades, setFilterGrades] = useState<boolean>(true);
    const [grades, setGrades] = useState<Grade[]>([]);
    const [gradesFilterStates, setGradesFilterStates] = useState<boolean[]>([]);
    const [exactSearch, setExactSearch] = useState<boolean>(false);
    const { searchText, setSearchText } = useContext(DataContext);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    let history = useHistory();

    function getSearchData() {
        let data = {
            searchText: searchText,
            filterSubjects: filterSubjects,
            subjects: [] as number[],
            filterGrades: filterGrades,
            grades: [] as number[],
            exactSearch: exactSearch
        };
        if (filterGrades) {
            grades.forEach((item, index) => {
                if (gradesFilterStates[index]) {
                    data.grades.push(item.id);
                }
            })
        }
        if (filterSubjects) {
            subjects.forEach((item, index) => {
                if (subjectsFilterStates[index]) {
                    data.subjects.push(item.id);
                }
            });
        }
        return data;
    }

    function getGradeName(id: number): string {
        for (let i = 0; i < grades.length; i++) {
            if (grades[i].id === id) {
                return grades[i].name;
            }
        }
        return "";
    }

    function getSubjectName(id: number): string {
        for (let i = 0; i < subjects.length; i++) {
            if (subjects[i].id === id) {
                return subjects[i].name;
            }
        }
        return "";
    }

    const findLocally = () => {
        const videoIds: number[] = [];
        const data = getSearchData();
        let searchText: string[] = [];
        let localSearchResults: SearchResult[] = [];

        if (data.exactSearch) {
            searchText.push(data.searchText);
        } else {
            searchText = data.searchText.split(' ');
        }

        function find(field: string) {
            for (let i = 0; i < searchText.length; i++) {
                if (field.indexOf(searchText[i]) === -1) {
                    return false;
                }
            }
            return true;
        }

        db.transaction("r", 'videos', async () => {
            db.table<Video>('videos').each((video) => {
                if (find(video.description)) {
                    videoIds.push(video.topic_id);
                }
            })
        }).then(() => {
            db.transaction("r", 'topics', async () => {
                db.table<Topic>('topics').each((topic) => {
                    let relevance = 0;
                    if (find(topic.name)) {
                        relevance += 10;
                    }
                    if (find(topic.description)) {
                        relevance += 5;
                    }
                    if (videoIds.indexOf(topic.id) !== -1) {
                        relevance += 1;
                    }
                    if (relevance > 0) {
                        localSearchResults.push({
                            id: topic.id,
                            description: topic.description,
                            grade_id: topic.grade_id,
                            grade_name: getGradeName(topic.grade_id),
                            name: topic.name,
                            relevance: relevance,
                            subject_id: topic.subject_id,
                            subject_name: getSubjectName(topic.subject_id),
                            video_id: topic.video_id,
                            thumbnail: topic.thumbnail,
                            thumbnailToken: topic.thumbnailToken
                        })
                    }
                })
            }).then(() => {
                setSearchResults(localSearchResults);
            })
        })
    }

    const getSearchResults = () => {
        Api.post<SearchResult[]>('search', {
            body: JSON.stringify(getSearchData())
        })
            .then(response => setSearchResults(response))
            .catch(() => {
                // findLocally();
            })
    }


    function setSubjectsFilterState(index: number) {

        let newFilterStates = [...subjectsFilterStates];
        newFilterStates[index] = !newFilterStates[index];

        setSubjectsFilterStates(newFilterStates);
    }

    function setGradesFilterState(index: number) {

        let newFilterStates = [...gradesFilterStates];
        newFilterStates[index] = !newFilterStates[index];

        setGradesFilterStates(newFilterStates);
    }

    useEffect(() => {
        if (!subjectsIsLoaded || !gradesIsLoaded) {
            if (keywords) {
                setSearchText(keywords);
            }
            return;
        }
        getSearchResults();
    }, [subjectsIsLoaded, gradesIsLoaded, keywords, setSearchText]);

    useEffect(() => {
        Storage.get<Subject[]>({
            storageName: 'subjects',
            apiUrl: 'subjects',
            storageCallback: db => db.table<Subject>('subjects').toArray()
        }).then(response => {
            setSubjects(response);
            let newFilterStates = response.map(item => true);
            setSubjectsFilterStates(newFilterStates);
            setSubjectsIsLoaded(true);
        })

        Storage.get<Grade[]>({
            storageName: 'grades',
            apiUrl: 'grades',
            storageCallback: db => db.table<Grade>('grades').toArray()
        }).then(response => {
            setGrades(response);
            let newFilterStates = response.map(item => true);
            setGradesFilterStates(newFilterStates);
            setGradesIsLoaded(true);
        })
    }, [])

    function gotoTopic(id: string, video_id: string, grade_id: string) {
        localStorage.setItem('topic', id);
        localStorage.setItem('grade', grade_id);
        if (video_id === 'null') {
            history.push('/topics/' + id);
        } else {
            history.push('/videos/' + id);
        }
    }

    function goToPage(to: string) {
        history.push(to);
    }

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className="glass">
                                <IonListHeader className="ion-no-padding">
                                    <IonButton
                                        fill="clear"
                                        expand="full"
                                        className="ion-no-margin"
                                        size="large"
                                        onClick={e => setForm(prevState => !prevState)}

                                    >
                                        <IonIcon icon={cogOutline} size="large" />&nbsp;
                                        <span className="ion-text-left width-100">{t('search.searchDetails')}</span>
                                        {form ? <IonIcon icon={chevronDown} /> : <IonIcon icon={chevronForward} />}
                                    </IonButton>
                                </IonListHeader>

                                {form &&
                                    <IonList className="ion-padding" lines="none">
                                        <IonItem className="ion-align-items-baseline">
                                            <IonToggle slot="start" checked={filterSubjects} color="yellow"
                                                onIonChange={e => setFilterSubjects(e.detail.checked)} />
                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>{t('search.filterSubjects')}</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        {filterSubjects && <IonList>
                                                            {subjects.map((subject, index) =>
                                                                <IonChip key={subject.id}
                                                                    style={{ backgroundColor: (subjectsFilterStates[index] ? '#' + subject.color : "grey") }}
                                                                    onClick={e => setSubjectsFilterState(index)}
                                                                >
                                                                    <IonIcon
                                                                        icon={subjectsFilterStates[index] ? checkmarkOutline : banOutline} />
                                                                    <IonLabel>{subject.name}</IonLabel>
                                                                </IonChip>
                                                            )}
                                                        </IonList>
                                                        }
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem className="ion-align-items-baseline">
                                            <IonToggle slot="start" checked={filterGrades} color="yellow"
                                                onIonChange={e => setFilterGrades(e.detail.checked)} />

                                            <IonGrid>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonLabel>{t('search.filterGrades')}</IonLabel>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol>
                                                        {filterGrades && <IonList>
                                                            {grades.map((grade, index) =>
                                                                <IonChip key={grade.id}
                                                                    style={{ backgroundColor: (gradesFilterStates[index] ? '#19cda6' : "grey") }}
                                                                    onClick={e => setGradesFilterState(index)}
                                                                >
                                                                    <IonIcon
                                                                        icon={gradesFilterStates[index] ? checkmarkOutline : banOutline} />
                                                                    <IonLabel>{grade.name}</IonLabel>
                                                                </IonChip>
                                                            )}
                                                        </IonList>
                                                        }
                                                    </IonCol>
                                                </IonRow>
                                            </IonGrid>
                                        </IonItem>

                                        <IonItem className="ion-align-items-baseline">
                                            <IonToggle slot="start" checked={exactSearch} color="yellow"
                                                onIonChange={e => setExactSearch(e.detail.checked)} />
                                            <IonLabel>{t('search.exactSearch')}</IonLabel>
                                        </IonItem>

                                        <IonItem className="ion-margin-top">
                                            <IonButton
                                                onClick={getSearchResults}
                                                size="default"
                                                className="ion-no-margin"
                                            >
                                                {t('search.submit')}
                                            </IonButton>
                                        </IonItem>
                                    </IonList>
                                }
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonRow className={'ion-justify-content-center ion-margin-vertical'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            {searchResults &&
                                <div className="ion-text-center">
                                    <p className="text-large">{t('search.results')}</p>
                                    {searchResults.length === 0 ?
                                        <p>{t('search.noResults')}</p>
                                        : <></>
                                    }
                                </div>
                            }
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        {searchResults &&
                            <>
                                {searchResults.map((record, index) =>
                                    <IonCol
                                        key={index}
                                        size={'12'} sizeMd={'6'} sizeLg={'3'}
                                        onClick={() => goToPage(record.video_id ? '/videos/' + record.id : '/topics/' + record.id)}
                                    >
                                        <div
                                            className={`ion-text-center clickable ${!record.video_id ? "glass sub-topic-on-list" : ""}`}
                                        >
                                            <div
                                                className={`search-result-details ${!record.video_id ? "ion-padding-horizontal" : ""}`}
                                            >
                                                {record.thumbnail && <img className="topic-thumbnail ion-margin-end"
                                                    src={"https://file.brainclub.hu/uploads/" + record.thumbnail + '/' + record.thumbnailToken + '?size=thumb'}
                                                    alt={record.name} />}
                                                <p className={record.video_id ? "" : "text-x-large"}>
                                                    {record.name}
                                                    <br /><span className="text-small">{record.grade_name} - {record.subject_name}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </IonCol>
                                )}
                            </>
                        }
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page >
    );
}

export default Search;