import Header from "../../components/Header/Header";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonGrid, IonIcon, IonItem, IonList,
    IonRow,
    IonTextarea
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Content as IContent } from "../../interfaces/Content";
import Page from "../../components/Page/Page";
import Storage from "../../services/Storage";
import './ChildDefaultInvite.scss';
import '../../i18';
import { useTranslation } from 'react-i18next';
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DataContext from "../../context/DataContext";
import Profile from "../../services/profile";
import Toast from "../../components/Toast";
import { logoFacebook, logoInstagram, logoSkype, logoWhatsapp, mailOutline } from "ionicons/icons";


const ChildDefaultInvite = () => {

    const { t } = useTranslation();
    const [content, setContent] = useState<IContent>();
    const [text, setText] = useState<string>('');
    const { profile } = useContext(DataContext);
    const slug = 'child-invite';
    const crumbs = [
        { 'text': profile.firstname + ' ' + t('page.homepage'), 'href': '/my-account/default/child' },
        { 'text': t('page.invite') }
    ];
    const [toastColor, setToastColor] = useState<string>('danger');
    const [toastMessage, setToastMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        Storage.get<IContent>({
            storageCallback: db => db.table<IContent>('contents').where('slug').equals(slug).first(),
            storageName: 'contents',
            apiUrl: 'content/' + slug,
            recordType: 'single'
        })
            .then(response => setContent(response));
    }, [slug]);

    useEffect(() => {

        Storage.get<string>({
            apiUrl: 'invite-url'
        }).then(url => {
            Profile.getProfileData().then(profile => {
                setText(t('childdefaultinvite.defaultText', {
                    ide: '<a href="' + url + '">ide</a>',
                    email: '<a href="mailto:' + profile.email + '">' + profile.email + '</a>',
                }));
            });
        });

    }, [setText]);

    function copyText() {
        navigator.clipboard.writeText(text).then(function () {
            setToastMessage(t('videos.copiedSuccessfully'));
            setToastColor('success');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        }, function () {
            setToastMessage(t('videos.copyFailed'));
            setToastColor('danger');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        });
    }

    return (
        <Page>
            <Header menu="defaultChildMenu" menuSlot="end" />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <Toast message={toastMessage} onHide={() => setShowToast(false)} show={showToast}
                                color={toastColor} />
                            {content && <>
                                <h1 dangerouslySetInnerHTML={{ __html: content?.title }} />
                                <div className="ion-margin-vertical"
                                    dangerouslySetInnerHTML={{ __html: content?.content }} />
                            </>}
                            <IonTextarea
                                rows={8}
                                value={text}
                                onIonChange={e => setText(e.detail.value!)}
                                className="ion-no-margin ion-margin-end"
                                placeholder={t("childdefaultinvite.placeholder")}
                            />
                            <IonButton
                                size="default"
                                onClick={copyText}
                                className="ion-no-margin ion-margin-bottom"
                            >
                                {t('childdefaultinvite.copy')}</IonButton>

                            {/*<InviteByLink/>*/}
                            {/*<InviteByEmail/>*/}
                            {/*<InviteByFacebook/>*/}
                            <p className="ion-margin-top">{t('childdefaultinvite.share')}</p>
                            <div className="invite-icons">
                                <IonIcon icon={mailOutline} />
                                <IonIcon icon={logoFacebook} />
                                <IonIcon icon={logoInstagram} />
                                <IonIcon icon={logoSkype} />
                                <IonIcon icon={logoWhatsapp} />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default ChildDefaultInvite;