import db from "./db";
import IOptions from "../interfaces/IOptions";

const Options = {
    get: (name: string, defaultValue?: string) => {
        return new Promise<string>((resolve, reject) => {
            let user_id_string = localStorage.getItem('user_id');

            if (!user_id_string) {
                user_id_string = '0';
            }

            const user_id = parseInt(user_id_string);
            db.table<IOptions>('options').where('name').equals(name).and(item => item.user_id === user_id).first().then(data => {
                if (typeof data === 'undefined') {
                    if (typeof defaultValue === 'undefined') {
                        reject();
                    } else {
                        resolve(defaultValue);
                    }
                } else {
                    resolve(data.value);
                }
            }).catch(reason => reject(reason));
        });
    },
    set: (name: string, value: string) => {
        function get() {
            return new Promise<IOptions>((resolve, reject) => {
                let user_id_string = localStorage.getItem('user_id');

                if (!user_id_string) {
                    user_id_string = '0';
                }

                const user_id = parseInt(user_id_string);

                let record: IOptions = {
                    name,
                    user_id,
                    value
                }
                db.table<IOptions>('options').where('name').equals(name).and(item => item.user_id === user_id).first().then(data => {
                    if (typeof data !== 'undefined') {
                        record.id = data.id;
                    }
                    resolve(record);
                }).catch(reason => reject(reason));
            });
        }

        return new Promise((resolve, reject) => {
            get()
                .then(record => {
                    db.transaction('rw', 'options', () => {
                        db.table('options').put(record)
                            .then(() => resolve(true))
                            .catch(reason => reject(reason));
                    });
                })
                .catch(reason => reject(reason));

        })
    }
}
export default Options;