import {
    IonButton, IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "../../interfaces/Content";
import Api from "../../api";
import Header from "../../components/Header/Header";
import Page from "../../components/Page/Page";
import { chevronDownOutline, chevronForward } from "ionicons/icons";

const Gyik = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>();
    const [contents, setContents] = useState<Array<Content>>();

    // function displayModal(modalContent: Content) {
    //     setModalContent(modalContent);
    //     setShowModal(true);
    // }

    // function showContent(content: number) {
    //     Api.get<Content>('content-id/' + content)
    //         .then(response => {
    //             displayModal(response);
    //         });
    // }

    function showContent(id: number) {
        const iconOpened = document.getElementById('title-' + id)?.getElementsByClassName('opened')[0];
        const iconClosed = document.getElementById('title-' + id)?.getElementsByClassName('closed')[0];
        const content = document.getElementById('content-' + id);
        if (content?.classList.contains('ion-hide')) {
            content?.classList.remove('ion-hide');
            iconOpened?.classList.remove('ion-hide');
            iconClosed?.classList.add('ion-hide');
        } else {
            content?.classList.add('ion-hide');
            iconOpened?.classList.add('ion-hide');
            iconClosed?.classList.remove('ion-hide');
        }
    }

    useEffect(() => {
        Api.get<Array<Content>>('contents/gyik')
            .then(response => setContents(response));
    }, []);

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle slot="start">{modalContent?.title}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton slot="end"
                                    onClick={() => setShowModal(false)}>{t('registration.closeModal')}</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <h1>{modalContent?.title}</h1>
                        {modalContent && <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />}
                    </IonContent>
                </IonModal>

                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="ion-text-center text-large">{t('menu.gyik')}</p>
                        </IonCol>
                    </IonRow>
                    {contents?.map(content =>
                        <IonRow className={'ion-justify-content-center'} key={content.id}>
                            <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                                <div
                                    id={'title-' + content.id}
                                    className="accordion-title clickable"
                                    onClick={() => showContent(content.id)}>
                                    {content.title}
                                    <IonIcon className="closed" icon={chevronForward} />
                                    <IonIcon className="opened ion-hide" icon={chevronDownOutline} />
                                </div>
                                <div
                                    id={'content-' + content.id}
                                    className="accordion-content ion-hide"
                                    dangerouslySetInnerHTML={{ __html: content.content }}
                                />
                            </IonCol>
                        </IonRow>)}
                </IonGrid>
            </IonContent>
        </Page>
    )
}

export default Gyik;