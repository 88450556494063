const configData = {
    "myaccount": {
        "monthlyPrice": 5900,
        "yearlyPrice": 49990,
        "yearlyDiscount": 15
    },
    "brainchecks": {
        "questionCount": 5
    }
}

export default function config(name:string) {
    const path = name.split('.');
    let result:any = Object.assign({}, configData);
    for (let i = 0; i < path.length; i++) {
        let resultType = typeof result[path[i]];
        switch (resultType) {
            case "undefined":
                result = null;
                break;
            case "object":
                result = Object.assign({}, result[path[i]]);
                break;
            default:
                result = result[path[i]];
        }
    }
    return result;
}
