import {WatchedVideo} from "../interfaces/WatchedVideo";
import Storage from "../services/Storage";

const useWatchVideo = () => {

    return (data: WatchedVideo) => {

        let mode = 'create';
        const watchedVideoId = localStorage.getItem('watchedVideoId');
        if (data.id === "-1" && watchedVideoId && typeof watchedVideoId !== "undefined") {
            mode = 'update';
            data.id = localStorage.getItem('watchedVideoId')!;
        }

        const saveProps = {
            apiUrl: 'watchedvideos',
            apiData: {
                records: [data]
            },
            methodType: 'post',
            storageName: 'watchedvideos'
        };

        if (mode === 'create') {
            Storage.create<WatchedVideo>(saveProps).then(response => {
                // @ts-ignore
                localStorage.setItem('watchedVideoId', response[1].records[0].id);
            })
        } else {
            Storage.update<WatchedVideo>(saveProps).then(response => {
                localStorage.removeItem('watchedVideoId');
            })
        }

    };
}

export default useWatchVideo;