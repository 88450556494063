import db from "../db";
import Api from "../../api";
import {IData} from "../Storage";
import {hideSpinner, showSpinner} from "../../components/Spinner/Spinner";

export default function get<T>(props: IData) {
    return new Promise<T>((resolve, reject) => {

        function resolveWithSippner(data: any) {
            hideSpinner();
            resolve(data);
        }

        function getFromStorage() {
            return new Promise<T>(async (resolveStorage, rejectSorage) => {
                if (props.storageCallback) {
                    let data = {} as T;
                    await db.transaction('r', props.storageName!, async () => {
                        data = await props.storageCallback!(db);
                    }).catch(reason => console.log(reason));

                    if (typeof data === 'undefined' || (Array.isArray(data) && data.length === 0)) {
                        rejectSorage();
                    } else {
                        let downloadDate: number;
                        if (Array.isArray(data)) {
                            downloadDate = data.reduce((accumulator, currentValue) => {
                                return Math.min(accumulator, parseInt(currentValue.downloadDate));
                            }, Date.now());
                        } else {
                            // @ts-ignore
                            downloadDate = parseInt(data.downloadDate);
                        }
                        let invalidationDate = localStorage.getItem('invalidationDate');
                        if (invalidationDate && downloadDate < parseInt(invalidationDate)) {
                            rejectSorage();
                        }
                        resolveStorage(data);
                    }
                }
            });
        }

        function getFromApi() {
            showSpinner();
            Api.get<T>(props.apiUrl).then(response => {
                if (props.storageName) {
                    db.transaction('rw', props.storageName, function () {
                        if (props.recordType === 'single') {
                            response.downloadDate = Date.now();
                            db.table(props.storageName!).put(response);
                        } else {
                            response.forEach((record: any) => {
                                record.downloadDate = Date.now()
                            });
                            db.table(props.storageName!).bulkPut(response);
                        }
                    }).catch(reason => console.log(reason))
                }
                resolveWithSippner(response)
            }).catch(reason => {

                if (reason.response) {
                    // window.location.replace('/np');
                }
                // hideSpinner();
                reject(reason)
            });
        }

        
        // if (props.storageName || props.storageCallback) {
        //     getFromStorage().then((records: T) => {
        //         resolveWithSippner(records);
        //     }).catch(() => getFromApi());
        // } else {
            getFromApi();
        // }
    })
}