import React from "react";
import {Redirect, Route} from "react-router-dom";
import {RouteProps} from "react-router";

const token = localStorage.getItem('token');

const ProtectedRoute = ({...rest }: RouteProps) => {
        return token ?
            <Route {...rest} />
            : <Redirect to={'/login'}/>;
}

export default ProtectedRoute;