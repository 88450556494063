import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import {IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {Redirect, useHistory} from "react-router-dom";
import ContentService from "../../services/ContentService";

const WelcomePage = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const [content, setContent] = useState<string>();

    useEffect(() => {
        ContentService.get('kezdd-el-a-tagsagot').then(response=> {
            setContent(response.content)
        })
    })

    // Ha nem szülő profil van kiválasztva, átirányítás
    if (localStorage.getItem('profile_type') !== '0') {
        return <Redirect to="/my-account/profile-select" />
    }

    const getPremium = () => {
        history.push('/my-account/get-premium');
    }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                            <p className="text-large ion-margin-vertical">
                                {t('welcomePage.premiumMembership')}
                            </p>
                            {content && <div id="content" dangerouslySetInnerHTML={{ __html: content }} />}
                            <IonButton className="ion-margin-top" onClick={getPremium}>{t('next')}</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default WelcomePage;