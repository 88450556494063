import Header from "../../components/Header/Header";
import React, {useEffect, useState} from "react";
import {
    IonCol,
    IonContent,
    IonRow,
    IonTextarea,
    IonButton,
    IonItem, IonLabel, IonRadioGroup, IonRadio, IonGrid, IonIcon, IonList, IonListHeader
} from "@ionic/react";
import {useTranslation} from "react-i18next";
import Api from "../../api";
import {useHistory} from "react-router-dom";
import Page from "../../components/Page/Page";
import {cardOutline, chevronDown, chevronForward, sadOutline} from "ionicons/icons";

const PaymentDelete = () => {
    const {t} = useTranslation();
    let history = useHistory();
    const [active, setActive] = useState(1);

    const crumbs = [
        {'text': t('page.parentHomepage'), 'href': '/my-account/profile-settings'},
        {'text': t('page.myOrders'), 'href': '/my-account/orders'},
        {'text': t('page.deletePremiumAccount')}
    ];
    const [reason, setReason] = useState<number>(0);
    const [message, setMessage] = useState<string>('');
    const [isRecurring, setIsRecurring] = useState<boolean>(false);
    const reasons = [
        t('paymentdelete.reason1'),
        t('paymentdelete.reason2'),
        t('paymentdelete.reason3'),
        t('paymentdelete.reason4'),
    ];

    useEffect(() => {
        Api.get('payment/is-recurring')
            .then(response => {
                if (response) {
                    setIsRecurring(true);
                }
            })
    }, []);

    function submitTextarea() {
        Api.post('payment-help', {
            body: JSON.stringify({
                message: message
            })

        }).then(() => {
            history.push('/my-account/payment-help');
        })
    }

    function submitReason() {
        Api.post('payment-delete', {
            body: JSON.stringify({
                reason: reasons[reason - 1] ? reasons[reason - 1] : 0
            })
        }).then(() => {
            localStorage.setItem('membership', '3');
            history.push('/my-account/payment-deleted');
        })
    }

    return (
        <Page>
            <Header/>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <h2 className="ion-text-center">
                                {t('paymentdelete.weAreSad')}...
                                <br/><IonIcon icon={sadOutline} size="large"/>
                            </h2>
                            <IonListHeader className="ion-no-padding">
                                <IonButton
                                    expand="full"
                                    className="ion-no-margin"
                                    onClick={(e) => setActive(1)}
                                    size="large"
                                >
                                    <IonIcon icon={cardOutline} size="large"/>&nbsp;
                                    <span className="ion-text-left width-100">{t("paymentdelete.textareaDescriptionShort")}</span>
                                    {active === 1 ? <IonIcon icon={chevronDown}/> :
                                        <IonIcon icon={chevronForward}/>}
                                </IonButton>
                            </IonListHeader>
                            <IonList className={active === 1 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                                <IonItem className="ion-margin-bottom">
                                    {t('paymentdelete.textareaDescription')}
                                </IonItem>
                                <IonItem className="ion-margin-bottom">
                                    <IonTextarea
                                        rows={3}
                                        placeholder={t('paymentdelete.textareaDescription')}
                                        value={message}
                                        onIonChange={e => setMessage(e.detail.value!)}
                                        className="ion-margin-bottom"
                                    />
                                </IonItem>
                                <IonItem className="ion-margin-bottom">
                                    <IonButton
                                        className="ion-margin-top width-100"
                                        size="default"
                                        onClick={submitTextarea}>
                                        {t('paymentdelete.submit')}
                                    </IonButton>
                                </IonItem>
                            </IonList>
                            <IonListHeader className="ion-no-padding">
                                <IonButton
                                    expand="full"
                                    className="ion-no-margin"
                                    onClick={(e) => setActive(2)}
                                    size="large"
                                >
                                    <IonIcon icon={cardOutline} size="large"/>&nbsp;
                                    <span className="ion-text-left width-100">{t("paymentdelete.tellUsWhyShort")}</span>
                                    {active === 2 ? <IonIcon icon={chevronDown}/> :
                                        <IonIcon icon={chevronForward}/>}
                                </IonButton>
                            </IonListHeader>
                            <IonList className={active === 2 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                                <IonItem className="ion-margin-bottom">
                                    <div className="ion-margin-vertical ion-padding-top"
                                         dangerouslySetInnerHTML={{__html: t('paymentdelete.tellUsWhy')}}></div>
                                </IonItem>
                                <IonItem className="ion-margin-bottom">
                                    <IonRadioGroup value={reason} onIonChange={e => setReason(e.detail.value)}
                                                   className="ion-margin-bottom">
                                        {reasons.map((reason, index) => (
                                            <IonItem key={index} className="ion-no-padding">
                                                <IonRadio
                                                    slot="start"
                                                    className="ion-no-margin ion-margin-end"
                                                    value={index + 1}
                                                />
                                                <IonLabel>{reason}</IonLabel>
                                            </IonItem>
                                        ))}
                                    </IonRadioGroup>
                                </IonItem>
                                <IonItem className="ion-margin-bottom">
                                    <IonButton
                                        onClick={submitReason}
                                        className="ion-margin-top width-100"
                                        size="default">
                                        {t('paymentdelete.submitDelete')}
                                        {isRecurring ? ' ' + t('paymentdelete.cancelCard') : ''}
                                    </IonButton>
                                </IonItem>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default PaymentDelete;