import React from "react";
import Header from "../../components/Header/Header";
import Page from "../../components/Page/Page";
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import db from "../../services/db";
import {useHistory} from "react-router-dom";

const ResetApp = () => {
    const {t} = useTranslation();
    let history = useHistory();

    function resetConfirmed() {
        const deviceToken = localStorage.getItem('device_token');
        localStorage.clear();
        if (deviceToken) {
            localStorage.setItem('device_token', deviceToken);
        }
        db.close();
        db.delete().then(() => {
            console.log('sikerült');

        }).catch(() => {
            console.log('nem nyert');
        }).finally(() => {
            redirectHome();
        });
    }

    function redirectHome() {
        history.push('/home');
    }

    return (<Page>
        <Header/>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonCard>
                            <IonCardHeader>
                                {t('resetapp.title')}
                            </IonCardHeader>
                            <IonCardContent>
                                {t('resetapp.question')}
                                <div>
                                    <IonButton
                                        onClick={resetConfirmed}>
                                        {t('resetapp.yes')}
                                    </IonButton>
                                    <IonButton
                                        fill="clear"
                                        color="secondary"
                                        className="ion-text-wrap ion-text-left height-100 label ion-no-padding"
                                        onClick={redirectHome}>
                                        {t('resetapp.no')}
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </Page>);
}

export default ResetApp;