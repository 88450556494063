import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Api from "../../api";

const RetryPayment = () => {
    let {transactionId} = useParams();
    const [simpleURL, setSimpleURL] = useState<string>();
    useEffect(() => {
        Api.get<string>('payment/retry/' + transactionId)
            .then(response => {
                setSimpleURL(response);
            })
    }, [transactionId]);

    if (typeof simpleURL !== 'undefined') {
        window.location.href = simpleURL;
    }

    return (<>{transactionId}</>);
}

export default RetryPayment;