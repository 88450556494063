import DateFormat from "../helpers/DateFormat";
import React from "react";
import {useTranslation} from "react-i18next";

const DisplayDate = ({date, format}: {
    date: string;
    format: string;
}) => {
    const {t} = useTranslation();
    const dateObject = new Date(date);
    const monthNames = [
        t('monthnames.jan'),
        t('monthnames.feb'),
        t('monthnames.mar'),
        t('monthnames.apr'),
        t('monthnames.may'),
        t('monthnames.jun'),
        t('monthnames.jul'),
        t('monthnames.aug'),
        t('monthnames.sep'),
        t('monthnames.oct'),
        t('monthnames.nov'),
        t('monthnames.dec')
    ];

    const monthName = monthNames[dateObject.getMonth()];

    return <span>{DateFormat(format ? format : 'Y-m-d', dateObject).replace('F', monthName)}</span>
}

export default DisplayDate;