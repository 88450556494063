import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton
} from "@ionic/react";

import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Redirect, useHistory } from "react-router-dom";
import Profile from "../../services/profile";
import IProfile from "../../interfaces/IProfile";
import '../../i18';
import { useTranslation } from 'react-i18next';
import Page from "../../components/Page/Page";
import Avatar from "../../components/Avatar/Avatar";
import DataContext from "../../context/DataContext";

const ProfileSelect = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const [profiles, setProfiles] = useState<Array<IProfile>>();
    const [profileId, setProfileId] = useState<string>();
    const { setProfile } = useContext(DataContext);
    const hasProfiles = localStorage.getItem('hasProfiles');
    const membership = localStorage.getItem('membership');

    const crumbs = [
        { 'text': t('page.profileSelect') }
    ];

    useEffect(() => {
        Profile.getProfiles().then(
            response => {
                if (response.length === 0) {
                    history.push('/my-account/welcome');
                } else {
                    setProfiles(response);
                }
            }
        );
        getCurrentProfileId();
    }, [history]);

    useEffect(() => {
        if (profiles && profiles.length > 1 && (!hasProfiles || hasProfiles === '0')) {
            localStorage.setItem('hasProfiles', '1');
        }
    }, [profiles]);

    function getCurrentProfileId() {
        if (localStorage.hasOwnProperty('profile_id')) {
            const currentProfileId = localStorage.getItem('profile_id')?.toString();
            setProfileId(currentProfileId);
        }
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />;
    }

    if (!membership || parseInt(membership) < 2) {
        return <Redirect to="/my-account/no-profile-home" />;
    }

    function selectProfile(profileSelected: IProfile) {
        localStorage.setItem('profile_id', String(profileSelected.id));
        localStorage.setItem('profile_type', String(profileSelected.type));
        setProfile(profileSelected);
        if (profileSelected.grade) {
            localStorage.setItem('grade', String(profileSelected.grade));
        } else if (localStorage.getItem('grade')) {
            localStorage.removeItem('grade');
        }
        setProfileId(String(profileSelected.id));

        if (profileSelected.type === 0) {
            history.push('/my-account/profile-settings');
        } else {
            history.push('/my-account/default/child');
        }
        // history.push('/my-account/default/' + (profile.type === 0 ? 'parent' : 'child'));
    }

    function gotoProfileSettings() {
        localStorage.setItem('profile_id', String(profiles![0].id));
        setProfile(profiles![0]);
        setTimeout(() => {
            history.push('/my-account/child-profiles');
        }, 300);
    }

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        {/* <IonCol size="12">
                            <Breadcrumb crumbs={crumbs} />
                        </IonCol> */}
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                            {/* <IonCard> */}
                                {/* <IonCardHeader> */}
                                {/* </IonCardHeader> */}
                                {/* <IonCardContent> */}
                                    {profiles?.map(profile =>
                                        <IonCard
                                            className="clickable"
                                            key={profile.id}
                                            onClick={() => selectProfile(profile)}
                                            color={profileId === profile.id.toString() ? 'primary' : ''}
                                        >
                                            <IonCardContent>
                                                <IonCardSubtitle>
                                                    {profile.type === 0 ? t('myaccount.parentProfile') : ''}
                                                </IonCardSubtitle>
                                                <div className="ion-justify-content-center iconLarge">
                                                    <Avatar profileId={profile.id} profileImage={profile.image} />
                                                </div>
                                                <IonCardTitle>{profile.firstname}</IonCardTitle>
                                            </IonCardContent>
                                        </IonCard>
                                    )}
                                    {profiles?.length === 1 ?
                                        <>
                                            <IonButton onClick={gotoProfileSettings}>
                                                {t('myaccount.addChildProfile')}
                                            </IonButton>
                                        </> : <></>
                                    }
                                {/* </IonCardContent> */}
                            {/* </IonCard> */}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default ProfileSelect;