import getData from "./getData";
import {Content} from "../interfaces/Content";

const ContentService = {
    get: (url: string, relative?: boolean):Promise<Content> => {
        return getData<Content>({
            recordType: 'single',
            apiUrl: relative === false ? url : 'content/' + url,
            storageName: 'contents',
            storageCallback: (db) => db.table<Content>('contents').where('slug').equals('kezdd-el-a-tagsagot').first()
        })
    }
}
export default ContentService