import React, {useEffect, useState} from "react";
import Storage from "../../services/Storage";
import {Content as IContent} from "../../interfaces/Content";

const Widget404 = ({force} : {force?: boolean}) => {
    const [content, setContent] = useState<IContent>();
    function loadContent() {
        Storage.get<IContent>({
            storageCallback: db => db.table<IContent>('contents').where('slug').equals('404').first(),
            storageName: 'contents',
            apiUrl: 'content/404',
            recordType: 'single'
        })
            .then(response => setContent(response));
    }
    useEffect(() => {
        window.addEventListener('unhandledrejection', function(event) {
            loadContent();
        });
    }, []);

    useEffect(() => {
        if (force) {
            loadContent();
        }
    }, [force]);

    if (content) {
        return <div dangerouslySetInnerHTML={{__html: content.content}}></div>;
    }

    return (<></>);
}

export default Widget404;