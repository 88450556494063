import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import {closeOutline} from "ionicons/icons";
import React, {Dispatch, SetStateAction} from "react";
import {Content} from "../../interfaces/Content";

const Modal = (
    {
        content,
        show,
        setShow
    }: {
        content: Content,
        show: boolean,
        setShow: Dispatch<SetStateAction<boolean>>
    }
) => {

    return (
        <IonModal
            isOpen={show}
            onDidDismiss={() => setShow(false)}
        >
            <IonHeader>
                <IonToolbar mode="ios">
                    <IonTitle slot="start">{content?.title}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            slot="end"
                            onClick={() => setShow(false)}
                        >
                            <IonIcon icon={closeOutline} size="large" color="white"/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <h1>{content?.title}</h1>
                {content && <div dangerouslySetInnerHTML={{__html: content.content}}/>}
            </IonContent>
        </IonModal>
    );
}
export default Modal;