import React from "react";
import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import Header from "../../components/Header/Header";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";

const BuyNewDevice = () => {
    const { t } = useTranslation();
    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="text-large ion-margin-vertical ion-text-center">
                                {t('buynewdevice.wedontknowthisdevice')}
                            </p>
                            <p>{t('buynewdevice.explaindeviceproblem')}</p>
                            <p>{t('buynewdevice.tousethisdevice')}</p>
                            <ul>
                                <li dangerouslySetInnerHTML={{
                                    __html: t('buynewdevice.buynewdevice', {
                                        buylink: '<a href="/new-device" class="text-white">',
                                        buylinkclose: '</a>',
                                    })
                                }} />
                                <li dangerouslySetInnerHTML={{
                                    __html: t('buynewdevice.deletedevicetext', {
                                        deletelink: '<a href="/devices" class="text-white">',
                                        deletelinkclose: '</a>',
                                    })
                                }} />
                            </ul>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default BuyNewDevice;