import React, { useEffect, useState } from "react";
import Api from "../../api";
import '../../i18';
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";

const SimplepayBack = () => {
    const { t } = useTranslation();
    const [transactionId, setTransactionId] = useState<string>('');
    const [result, setResult] = useState<number>(0);
    const [responseText, setResponseText] = useState<string>()
    const [responseTitle, setResponseTitle] = useState<string>()
    const [type, setType] = useState<number>(0);
    const [details, setDetails] = useState<any>();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        Api.post<any>('payment/simplepay/back', {
            body: JSON.stringify({
                r: urlParams.get('r'),
                s: urlParams.get('s')
            })
        }).then((response) => {
            setType(response.type);
            if (response.status === 'success') {
                setTransactionId(response.transaction_id);
                setDetails(response);
                setResult(1);
            } else {
                switch (response.status) {
                    case 'cancel':
                        setResponseTitle(t('myaccount.cancelledPaymentTitle'));
                        setResponseText(t('myaccount.cancelledPayment'));
                        break;
                    case 'timeout':
                        setResponseTitle(t('myaccount.timeoutPaymentTitle'));
                        setResponseText(t('myaccount.timeoutPayment'));
                        break;
                    case 'fail':
                        setResponseTitle(t('myaccount.failedPaymentTitle'));
                        setResponseText(t('myaccount.failedPayment'));
                        break;
                }
                setTransactionId(response.transaction_id);
                setResult(-1);
            }
        });
    }, [t]);

    if (result === -1) {
        return <Redirect to={
            {
                pathname: "/my-account/payment-error",
                state: {
                    transactionId: transactionId,
                    responseText: responseText,
                    responseTitle: responseTitle,
                    type: type
                }
            }
        } />
    }

    if (result === 1) {
        return <Redirect to={
            {
                pathname: "/my-account/payment-success",
                state: {
                    transactionId: transactionId,
                    details: details,
                    type: type
                }
            }
        } />
    }

    return (
        <>Feldolgozás...</>
    );

}

export default SimplepayBack;