import { IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import Header from "../../components/Header/Header";
import React, { useEffect } from "react";
import './Subjects.scss';
import { Redirect } from "react-router-dom";
import SubjectList from "../../components/SubjectList/SubjectList";
import Page from "../../components/Page/Page";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import Widget404 from "../../components/Widget404/Widget404";
// import DataContext from "../../context/DataContext";

const Subjects = () => {
    const { t } = useTranslation();
    const grade = localStorage.getItem('grade');
    // const { profile } = useContext(DataContext);
    const crumbs = [
        // { 'text': profile.firstname + ' ' + t('page.homepage'), 'href': '/my-account/default/child' },
        { 'text': t('page.grades'), 'href': '/grades' },
        { 'text': grade + '. ' + t('page.grade') },
    ];

    useEffect(() => {
        if (localStorage.getItem('topic')) {
            localStorage.removeItem('topic');
        }
    }, []);

    if (!grade) {
        return <Redirect to="/grades" />
    }

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <Breadcrumb crumbs={crumbs} />
                <Widget404/>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <SubjectList />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default Subjects;