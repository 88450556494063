import Dexie from 'dexie';
import 'dexie-observable';

const db = new Dexie('BrainclubDB');
db.version(16).stores({
    videos: 'id, topicId',
    grades: 'id',
    subjects: '[id+grade_id], grade_id',
    contents: 'id, slug',
    profiles: '$$id, user_id, type',
    brainchecks: 'id, topic_id',
    braincheckresults: '$$id, topic_id',
    topics: 'id, subject_id, grade_id',
    files: 'id, parent',
    braincheckcommentrate: '$$id, brainchek_id',
    videofeedbacks: '$$id, type, video_id',
    watchedvideos:'$$id, profile_id, topic_id, video_id',
    products:'id, period',
    options: '$$id, name'
});

export default db;
