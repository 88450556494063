import React, { useEffect, useState } from "react";
import '../../i18';
import { useTranslation } from 'react-i18next';
import { accessibilitySharp, addCircle, chevronDown, chevronForward } from "ionicons/icons";
import Profile from "../../services/profile";
import IProfile from "../../interfaces/IProfile";
import {IonListHeader, IonButton, IonIcon, IonList, IonItem, IonToast} from "@ionic/react";
import ChildProfile from "../ChildProfile/ChildProfile";
import {useHistory} from "react-router-dom";

const ChildProfiles = ({opened} : {opened?: number}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [childProfiles, setChildProfiles] = useState<Array<IProfile>>([]);
    const [active, setActive] = useState<number>();
    const [showToast, setShowToast] = useState(false);

    const eventHandler = (e: any, index: number) => {
        e.preventDefault();
        active === index ? setActive(0) : setActive(index);
    }

    function loadProfiles(reload?: boolean) {
        Profile.getProfileDataByType(1).then(
            data => {
                if (data.length > 0) {
                    setChildProfiles(data);
                } else {
                    setChildProfiles([]);
                    if (reload !== true) {
                        addChild();
                    }
                }
            }
        ).catch(response => console.log(response));
    }

    useEffect(() => {
        loadProfiles();
    }, []);

    useEffect(() => {
        if (opened !== null) {
            setActive(opened);
        }
    }, [opened]);

    const addChild = () => {
        const unfilled = childProfiles.filter((item) => !item.firstname);
        if (unfilled.length > 0) {
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        } else {

            const newProfile = {
                'user_id': 0,
                'type': 1,
                'lastname': '',
                'firstname': '',
                'grade': 0,
                'grade_name': '',
                'school_city': '',
                'school_name': '',
                'image': '',
            } as IProfile;
            setChildProfiles([...childProfiles!, newProfile]);
        }
    }

    const getOpened = (profile: IProfile): number => {
        // if (childProfiles?.length === 1) {
        //     return 1;
        // }

        if (!profile.id) {
            return 1;
        }

        return 0;
    }

    const onSave = (profile: IProfile, index: number) => {
        setChildProfiles(prevState => {
            prevState[index] = profile;
            return prevState;
        })
    }

    const deleteProfile = (profile: IProfile, index: number) => {
        loadProfiles(true);
    }

    return (
        <>
            <IonListHeader className="form-block-heading">
                <IonButton
                    fill="clear"
                    expand="full"
                    onClick={(e) => eventHandler(e, 1)}
                    size="large"
                >
                    <IonIcon icon={accessibilitySharp} size="large" />&nbsp;
                    <span className="ion-text-left width-100">{t("myaccount.childData")}</span>
                    {active === 1 ? <IonIcon icon={chevronDown} /> : <IonIcon icon={chevronForward} />}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 1 ? 'max-height-none ion-margin-bottom' : 'ion-hide'} lines="none">
                {childProfiles?.map((profile, index) =>
                    <ChildProfile profile={profile} key={profile.id ? profile.id : '1'} index={index} opened={getOpened(profile)} onSave={onSave} onDelete={deleteProfile} />
                )}
                <IonItem className="ion-padding-end">
                    <IonIcon className="add-new-profile clickable color-yellow" icon={addCircle} color="primary" onClick={addChild} />
                </IonItem>
            </IonList>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={t('childProfile.fillingInProgress')}
                position="top"
                color={'warning'}
            />
        </>
    );
};

export default ChildProfiles;