import { IonCol, IonItem, IonList, IonListHeader, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Subject } from "../../interfaces/Subject";
import { useHistory } from "react-router-dom";
import getData from "../../services/getData";

const SubjectList = () => {

    const { t } = useTranslation();
    const [subjects, setSubjects] = useState<Array<Subject>>();
    let history = useHistory();
    const grade = localStorage.getItem('grade');

    function pickSubject(id: number, name: string) {
        localStorage.setItem('subject', String(id));
        history.push('/topics');
    }

    useEffect(() => {
        if (grade) {
            getData<Array<Subject>>({
                apiUrl: 'subjects?grade=' + grade,
                storageName: 'subjects',
                storageCallback: (db) => db.table<Subject>('subjects').where('grade_id').equals(grade).toArray()
            })
                .then(response => {
                    setSubjects(response)
                });
        }
    }, [grade]);

    return (
        <div className="glass ion-margin-bottom">
            <IonListHeader className="form-block-heading ion-align-items-center">
                <div className="ion-padding-horizontal">
                    <p className="text-white">
                        {grade ?
                            grade + t('childdefaultmain.gradeSubjects')
                            :
                            t('childdefaultmain.Subjects')
                        }
                    </p>
                </div>
            </IonListHeader>
            <IonList className="ion-padding-horizontal" lines="none">
                <IonItem>
                    <IonRow className="width-100">
                        {subjects && subjects.length ? subjects.map(subject =>
                            <IonCol
                                className="ion-margin-bottom ion-justify-content-center clickable"
                                test-id={'subject_' + subject.id} key={'subject_' + subject.id}
                                onClick={e => pickSubject(subject.id, subject.name)}
                            >
                                <div className="logo" style={{ backgroundColor: '#' + subject.color }}>
                                    <img alt="" src={'/uploads/subject/' + subject.logo} />
                                </div>
                                <div className="details ion-text-center">
                                    <div className="subject-name">{subject.name}</div>
                                    <div className="topics">
                                        {t('subjects.topics')}: {subject.topics}
                                    </div>
                                </div>
                            </IonCol>
                        )
                            :
                            t('subjects.noSubjects')
                        }
                    </IonRow>
                </IonItem>
            </IonList>
        </div>
    );
}

export default SubjectList;