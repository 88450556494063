import Header from "../../components/Header/Header";
import React, { useEffect, useState } from "react";
import Page from "../../components/Page/Page";
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid, IonInput,
    IonLabel,
    IonRow, IonTextarea
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import Toast from "../../components/Toast";
import Profile from "../../services/profile";
import Api from "../../api";
import IProfile from "../../interfaces/IProfile";

const BugFound = () => {
    const { t } = useTranslation();
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [comment, setComment] = useState<string>();
    const [color, setColor] = useState<string>('danger');
    const [message, setMessage] = useState<string>(t('bugfound.errorValidateComment'));
    const profileId = Profile.getId();
    const [profile, setProfile] = useState<IProfile>({} as IProfile);

    const [showToast, setShowToast] = useState<boolean>(false);

    const crumbs = [
        { 'text': t('page.bugFound') }
    ];

    useEffect(() => {
        if (profileId) {
            Profile.getProfileData().then(response => {
                setProfile(response);
            });
        }
    }, [profileId]);

    function validateComment() {
        Api.post('feedbacks', {
            body: JSON.stringify({
                type: 2,
                profile_id: profileId,
                name: typeof name !== 'undefined' ? name : (profile.lastname ? profile.lastname + " " : "") + profile.firstname,
                bugplace: title,
                email: email ? email : profile.email,
                bugcomment: comment
            })
        }).then(response => {
            resetForm();
            setMessage(t('bugfound.commentSaved'));
            setColor('success');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        }).catch(error => {
            let message = '';
            Object.keys(error.errors).map(key =>
                message += error.errors[key] + '<br />'
            )
            setMessage(message);
            setColor('danger');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        });
    }

    function resetForm() {
        setName('');
        setEmail('');
        setTitle('');
        setComment('');
    }

    return (
        <Page>
            <Header />
            <IonContent>
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <Toast message={message} show={showToast} color={color} onHide={() => setShowToast(false)} />
                            <p className="text-large ion-text-center">
                                {t(profileId ? 'bugfound.listHeader' : 'bugfound.listHeaderUnAuthenticated')}
                            </p>
                            {!profileId && <>
                                <IonLabel>
                                    {t('bugfound.name')}
                                </IonLabel>
                                <IonInput
                                    role="text-input"
                                    name="name"
                                    onIonChange={e => setName(e.detail.value!)}
                                    inputmode="text"
                                />
                                <IonLabel>
                                    {t('bugfound.email')}
                                </IonLabel>
                                <IonInput
                                    role="text-input"
                                    name="email"
                                    onIonChange={e => setEmail(e.detail.value!)}
                                    inputmode="text"
                                />
                            </>}

                            <IonLabel>
                                {t('bugfound.title')}
                            </IonLabel>
                            <IonInput value={title}
                                role="text-input"
                                name="title"
                                onIonChange={e => setTitle(e.detail.value!)}
                                inputmode="text"
                            />
                            <IonLabel>
                                {t('bugfound.comment')}
                            </IonLabel>
                            <IonTextarea value={comment}
                                role="text-input"
                                name="comment"
                                rows={5}
                                onIonChange={e => setComment(e.detail.value!)}
                                inputmode="text"
                            />

                            <IonButton
                                size="default"
                                onClick={validateComment}
                            >
                                {t('bugfound.saveComment')}
                            </IonButton>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}
export default BugFound;