import {IonToast} from "@ionic/react";
import React from "react";

const Toast = ({message, show, onHide, color}: {
    message: string | undefined;
    show: boolean,
    onHide: () => void,
    color?: string
}) => {
    return (
        <IonToast
            isOpen={show}
            onDidDismiss={() => onHide()}
            message={message}
            position="top"
            color={color ? color : "danger"}
        />
    );
}

export default Toast;