import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonPage, IonSpinner,
} from "@ionic/react";

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import formatPrice from "../../helpers/formatPrice";
import './PaymentSuccess.scss';
import Api from "../../api";
import {useHistory} from "react-router";

const PaymentSuccess = (props: any) => {
    const {t} = useTranslation();

    const details = props.location.state?.details;
    const history = useHistory();

    useEffect(() => {
        const getActivePayment = () => {
            Api.get<boolean>('payment/has-active-payment')
                .then(response => {
                    if (response) {
                        localStorage.setItem('membership', '2');
                        history.push('/');
                    } else {
                        setTimeout(getActivePayment, 5000);
                    }
                });
        };
        getActivePayment();
    }, [history]);

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="text-large ion-text-center ion-margin-vertical text-bold">
                                {t('paymentsuccess.title')}
                            </p>
                            <p className="ion-text-center ion-margin-vertical">
                                {t('myaccount.simplepayTransactionId')}: {details?.transaction_id}
                            </p>
                            <p className="ion-text-center">
                                {t('paymentsuccess.products')}:<br/>
                                {details?.product[0].product.name}:{" "}
                                {formatPrice(details?.product[0].price)}<br/><br/>
                                {t('paymentsuccess.paymentMode')}:<br/>
                                {t('paymentsuccess.' + (details?.period === 12 ? 'yearly' : 'monthly'))}<br/>
                                {t('paymentsuccess.' + (details?.is_recurring === 1 ? 'recurring' : 'notRecurring'))}<br/>
                            </p>

                            {<div className="active-payment-spinner">
                                <IonSpinner name="lines" color="success"></IonSpinner>
                                <IonSpinner name="lines" color="secondary"></IonSpinner>
                                <IonSpinner name="lines" color="tertiary"></IonSpinner>
                                <br/>
                                {t('paymentsuccess.wait')}
                                <br/>
                                <IonSpinner name="lines" color="success"></IonSpinner>
                                <IonSpinner name="lines" color="secondary"></IonSpinner>
                                <IonSpinner name="lines" color="tertiary"></IonSpinner>
                            </div>}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default PaymentSuccess;