import {IDates, ISetData} from "../Storage";
import save from "./save";
import DateFormat from "../../helpers/DateFormat";
import db from "../db";

export default function update<T>(props: ISetData<T>) {
    return save<T>(props, (record) => {
        let date = DateFormat('Y-m-d H:i:s');

        record.downloadDate = Date.now();
        record.updated_at = date;

        return new Promise<T & IDates>((resolve, reject) => {
            db.table(props.storageName!).update(record.id, record)
                .then(value => {
                    resolve(record);
                });
        });
    });
}