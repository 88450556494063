const DateFormat = (format: string, date?: Date) : string => {

    if (typeof date === "undefined") {
        date = new Date();
    }

    format = format.replace('Y', date.getFullYear().toString());
    format = format.replace('m', (date.getMonth() + 1).toString().padStart(2, '0'));
    format = format.replace('d', date.getDate().toString().padStart(2, '0'));
    format = format.replace('H', date.getHours().toString().padStart(2, '0'));
    format = format.replace('i', date.getMinutes().toString().padStart(2, '0'));
    format = format.replace('s', date.getSeconds().toString().padStart(2, '0'));

    return format;
}

export default DateFormat;