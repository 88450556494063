import { IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import SubjectList from "../../components/SubjectList/SubjectList";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Page from "../../components/Page/Page";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DataContext from "../../context/DataContext";

const ChildDefaultSubjects = () => {
    const { t } = useTranslation();
    const { profile } = useContext(DataContext);
    const grade = localStorage.getItem('grade');
    const crumbs = [
        { 'text': t('page.yourSubjects') }
    ];

    return (
        <Page>
            <Header menu="defaultChildMenu" menuSlot="end" />
            <IonContent>
                <Breadcrumb crumbs={crumbs} />
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <SubjectList />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    )
}

export default ChildDefaultSubjects;
