import React, {useState} from "react";
import {IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRow} from "@ionic/react";
import {chevronDown, chevronForward, flagOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import IProfile from "../../interfaces/IProfile";
import formatPrice from "../../helpers/formatPrice";
import DisplayDate from "../DisplayDate";
import {Membership} from "../../interfaces/Membership";

const OrdersProducts = ({
                            membership,
                            childProfiles
                        }: { membership: Membership, childProfiles: IProfile[] }) => {
    const {t} = useTranslation();
    const [active, setActive] = useState<boolean>(true);
    return (
        <>
            <IonListHeader className="ion-no-padding">
                <IonButton
                    size="large"
                    fill="clear"
                    expand="full"
                    onClick={(e) => setActive(prevState => !prevState)}
                >
                    <IonIcon icon={flagOutline} size="large"/>&nbsp;
                    <span className="ion-text-left width-100">{t("parentdefaultproducts.title")}</span>
                    {active ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active ? 'ion-padding-horizontal list-condensed ion-margin-bottom' : 'ion-hide'}
                     lines="none">
                <IonItem>
                    <IonLabel>{t("parentdefaultproducts.period")}</IonLabel>
                    {membership.id && t(membership.product.period === 1 ? 'parentdefaultproducts.monthly' : 'parentdefaultproducts.yearly')}
                    {!membership.id && t('parentdefaultproducts.nopayment')}
                </IonItem>
                <IonItem>
                    <IonLabel>{t("parentdefaultproducts.price")}</IonLabel>
                    {membership.id && formatPrice(membership.product.price)}
                    {!membership.id && t('parentdefaultproducts.nopayment')}
                </IonItem>
                <IonItem className="ion-align-items-start">
                    <IonLabel>{t("parentdefaultproducts.activeProfiles")}</IonLabel>
                    {membership.id && <div>
                        {childProfiles.length > 0 && (
                            <ul>{childProfiles.map(profile => (
                                <li key={profile.id}>{profile.firstname}</li>
                            ))}
                            </ul>)}
                        {childProfiles.length === 0 && t('parentdefaultproducts.noprofile')}
                    </div>}
                    {!membership.id && t('parentdefaultproducts.nopayment')}
                </IonItem>
                <IonItem>
                    <IonLabel>{t("parentdefaultproducts.nextPayment")}</IonLabel>
                    {membership.id && <DisplayDate date={membership.expires_at} format="Y. F d."/>}
                    {!membership.id && t('parentdefaultproducts.nopayment')}
                </IonItem>
                <IonItem className="ion-align-items-start ion-margin-top">
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                            {((membership.id && membership.product.period === 1) || !membership.id) &&
                                <IonCol size={'12'} sizeMd={'6'} className="ion-no-padding ion-padding-end">
                                    {!membership.id &&
                                        <>
                                            <IonButton
                                                href="/renew-payment"
                                            >
                                                {t('parentdefaultproducts.getPremium')}
                                            </IonButton>
                                        </>
                                    }
                                </IonCol>
                            }
                            <IonCol size={'12'}
                                    sizeMd={((membership.id && membership.product.period === 1) || !membership.id) ? '6' : '12'}
                                    className="ion-no-padding ion-text-sm-left ion-text-md-right">
                                {membership.id &&
                                    <>
                                        <IonButton
                                            fill="clear"
                                            href="/my-account/payment-delete"
                                            className="ion-no-padding ion-no-margin text-yellow"
                                        >
                                            {t('parentdefaultproducts.deletePayment')}
                                        </IonButton>
                                    </>
                                }
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                <IonItem className="ion-align-items-start ion-margin-top">
                    <IonLabel>{t('parentdefaultproducts.devices')}</IonLabel>
                    {membership.devices} {t('parentdefaultproducts.piece')}
                </IonItem>
                <IonItem>
                    <IonGrid className="ion-no-padding">
                        <IonRow>
                            <IonCol size={'12'} sizeMd={'6'} className="ion-no-padding ion-padding-end">
                                <IonButton href="/new-device">
                                    {t('parentdefaultproducts.newDevice')}
                                </IonButton>
                            </IonCol>
                            {membership.devices > 0 && (
                                <IonCol size={'12'} sizeMd={'6'}
                                        className="ion-no-padding ion-text-sm-left ion-text-md-right">
                                    <IonButton
                                        href="/device-delete"
                                        fill="clear"
                                        className="ion-no-padding ion-no-margin text-yellow"
                                    >
                                        {t('parentdefaultproducts.deleteDevice')}
                                    </IonButton>
                                </IonCol>
                            )}
                        </IonRow>
                    </IonGrid>
                </IonItem>
            </IonList>
        </>
    );
}
export default OrdersProducts;