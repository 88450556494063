import { IBrainCheck } from "../../interfaces/IBrainCheck";
import React, { useEffect, useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCheckbox, IonCol, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import Toast from "../Toast";
import getData from "../../services/getData";
import "./BrainCheck.scss";
import setData from "../../services/setData";
import Profile from "../../services/profile";

const BRAINCHECK_SHOW_QUESTION = 0;
const BRAINCHECK_SHOW_RESULT = 1;
const BRAINCHECK_SHOW_COMMENT = 2;
const BRAINCHECK_SHOW_COMMENT_RATE = 3;

interface IFile {
    id: number;
    is_active: number;
    file_name: string;
    name: string;
}

const BrainCheck = ({ brainCheck, current, all, onSelect, onNext }: {
    brainCheck: IBrainCheck;
    current: number;
    all: number;
    onSelect: (result: boolean) => void
    onNext: () => void
}) => {

    const { t } = useTranslation();
    const [mode, setMode] = useState<number>(BRAINCHECK_SHOW_QUESTION);
    const [answers, setAnswers] = useState<Array<boolean>>([]);
    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [resultImage, setResultImage] = useState<string>('');
    const [result, setResult] = useState<boolean>(false);
    const [successMemes, setSuccessMemes] = useState<IFile[]>();
    const [failMemes, setFailMemes] = useState<IFile[]>();

    //Download memes
    useEffect(() => {
        getData<IFile[]>({
            apiUrl: 'files/1',
            storageName: 'files',
            storageCallback: db => db.table<IFile>('files').where('parent').equals(1).toArray()
        }).then(response => setSuccessMemes(response))

        getData<IFile[]>({
            apiUrl: 'files/2',
            storageName: 'files',
            storageCallback: db => db.table<IFile>('files').where('parent').equals(2).toArray()
        }).then(response => setFailMemes(response))
    }, []);

    useEffect(() => {
        let newAnswers: Array<boolean> = [];
        for (let i = 0; i < brainCheck.answers.length; i++) {
            newAnswers.push(false);
        }
        setAnswers(newAnswers);
    }, [brainCheck]);

    function pickAnswer(index: number) {
        let newAnswers = [];
        for (let i = 0; i < answers.length; i++) {
            newAnswers[i] = i === index;
        }
        setAnswers(newAnswers);
    }

    function Question() {
        return (
            <div className="text-large text-center bc-question" dangerouslySetInnerHTML={{ __html: brainCheck.question }} />
        );
    }

    function pickCheckbox(id: number) {
        const element = document.getElementById('answer-' + id) as HTMLInputElement;
        element.checked = true;
    }

    function Answers() {
        return (
            <IonRow className="ion-justify-content-around ion-margin-top">
                {brainCheck.answers.map((answer, index) =>
                    <IonCol
                        size={'12'} sizeMd={'6'} sizeLg={'3'}
                        key={answer.id}
                        className="clickable bc-answer"
                        onClick={() => pickCheckbox(answer.id)}
                    >
                        <IonCard className={`bc-answer-card glass ${answers![index] ? 'ion-color-primary' : ''}`}>
                            <IonCardContent>
                                <div>
                                    <IonCheckbox
                                        id={'answer-' + answer.id}
                                        className="ion-hide"
                                        checked={answers![index]}
                                        onIonChange={e => pickAnswer(index)}
                                    />
                                    <div className="bc-answer-html">
                                        <span dangerouslySetInnerHTML={{ __html: answer.answer }} />
                                    </div>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                )
                }
            </IonRow >
            // <IonList lines="none" className="answers">
            //     {brainCheck.answers.map((answer, index) =>
            //         <IonItem key={answer.id} className="answer ion-margin-vertical">
            //             <IonCheckbox
            //                 id={'answer-' + answer.id}
            //                 className="bc-input"
            //                 checked={answers![index]}
            //                 onIonChange={e => pickAnswer(index)}
            //             />
            //             <IonLabel>
            //                 <span dangerouslySetInnerHTML={{ __html: answer.answer }} />
            //             </IonLabel>
            //         </IonItem>
            //     )}
            // </IonList>
        );
    }

    function AnswerButton() {

        const submitAnswer = () => {
            const picked = answers.indexOf(true);
            if (picked === -1) {
                setErrorMessage(t('brainchecks.answerRequired'));
                setShowToast(true);
                setTimeout(() => setShowToast(false), 5000);
            } else {
                const isGood = brainCheck!.answers[picked].is_good;
                onSelect(isGood);

                const meme = isGood ? successMemes![Math.floor(Math.random() * (successMemes!.length))] : failMemes![Math.floor(Math.random() * (failMemes!.length))];
                setResultImage('/uploads/' + meme.id);
                setResult(isGood);
                setMode(BRAINCHECK_SHOW_RESULT);
                setTimeout(() => setMode(BRAINCHECK_SHOW_COMMENT_RATE), 3000);
                // if (isGood) {
                //     if (current + 1 < all) {
                //         setMode(BRAINCHECK_SHOW_QUESTION);
                //     }
                //     onNext();
                // } else {
                //     setMode(BRAINCHECK_SHOW_COMMENT_RATE);
                // }
            }
        };

        return (
            <IonButton
                onClick={submitAnswer}
                size="default"
            >
                {t('brainchecks.submit')}
            </IonButton>
        );
    }

    function ResultImage() {
        return (
            <div className="result-image ion-text-center ion-margin-vertical">

                <p className="text-large">{t(result ? 'brainchecks.resultSuccessTitle' : 'brainchecks.resultFailTitle')}</p>

                <img src={resultImage} alt="" />
            </div>
        );
    }

    function ResultComment() {
        function next() {
            if (current + 1 < all) {
                setMode(BRAINCHECK_SHOW_QUESTION);
            }
            onNext();
            // setMode(BRAINCHECK_SHOW_COMMENT_RATE);
        }

        return (
            <IonRow className="ion-justify-content-center">
                <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                    <div className="result-text ion-text-left" dangerouslySetInnerHTML={{
                        __html:
                            result ?
                                brainCheck!.good_answer :
                                brainCheck!.bad_answer
                    }} />
                    {<div className="ion-text-center">
                        <IonButton
                            onClick={next}
                            size="default"
                        >
                            {t('brainchecks.next')}
                        </IonButton>
                    </div>}
                </IonCol>
            </IonRow>
        );
    }

    function ResultCommentRate() {
        function next(rate: number) {
            if (current + 1 < all) {
                setMode(BRAINCHECK_SHOW_QUESTION);
            }
            setData({
                apiUrl: 'braincheckcommentrate',
                apiData: {
                    records: [
                        {
                            braincheck_id: brainCheck.id,
                            comment_type: result ? 1 : 0,
                            rate: rate,
                            profile_id: Profile.getId()
                        }
                    ]
                },
                methodType: 'post',
                storageName: 'braincheckcommentrate'
            })
            onNext();
        }

        return (
            <div className="comment-rate ion-text-center ion-margin-vertical">
                <p className="text-large">
                    {t('brainchecks.commentRate')}
                </p>

                <div className="rate-icons ion-margin-top">
                    <div>
                        <img src="assets/rateicon1.png" alt="" onClick={e => next(1)} />
                    </div>
                    <div>
                        <img src="assets/rateicon2.png" alt="" onClick={e => next(2)} />
                    </div>
                    <div>
                        <img src="assets/rateicon3.png" alt="" onClick={e => next(3)} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Toast message={errorMessage} show={showToast} onHide={() => setShowToast(false)} />
            <Question />
            {mode === BRAINCHECK_SHOW_QUESTION &&
                (
                    <>
                        <Answers />
                        <div className="ion-text-center ion-padding-top">
                            <AnswerButton />
                        </div>
                    </>
                )
            }
            {mode === BRAINCHECK_SHOW_RESULT &&
                    <ResultImage />
            }
            {
                // mode === BRAINCHECK_SHOW_COMMENT &&
                // <ResultComment />
            }
            {
                mode === BRAINCHECK_SHOW_COMMENT_RATE &&
                <>
                    <ResultComment />
                    {!result && ((Math.random() * 10) < 3) &&
                    <ResultCommentRate />}
                </>
            }
        </>
    );
}

export default BrainCheck;