import Header from "../../components/Header/Header";
import {IonCol, IonContent, IonGrid, IonPage, IonRow} from "@ionic/react";
import GetPremiumComponent from "../../components/GetPremiumComponent/GetPremiumComponent";
import React from "react";
import GradesComponent from "../../components/GradesComponent/GradesComponent";
import CheckMembership from "../../components/CheckMembership/CheckMembership";

const NoProfileHome = () => {
    return (
        <IonPage>
            <CheckMembership/>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <GradesComponent />
                            <GetPremiumComponent />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}
export default NoProfileHome;