import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonRouterLink,
    IonCardContent,
    IonCard
} from "@ionic/react";

import React from "react";
import Header from "../../components/Header/Header";
import '../../i18';
import { useTranslation } from 'react-i18next';
import Page from "../../components/Page/Page";

const PaymentError = (props: any) => {
    const { t } = useTranslation();
    const transactionId = props.location.state.transactionId;
    const responseTitle = props.location.state.responseTitle;
    const responseText = props.location.state.responseText;

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="text-large ion-text-center ion-margin-vertical" dangerouslySetInnerHTML={
                                {
                                    __html:
                                        responseTitle
                                }
                            } />
                            {
                                <p
                                    id="content"
                                    dangerouslySetInnerHTML={
                                        {
                                            __html:
                                                responseText
                                        }
                                    }
                                />
                            }
                            <p className="ion-margin-vertical">
                                {t('myaccount.simplepayTransactionId')}: {transactionId}
                            </p>
                            <IonRouterLink href={"/my-account/retry-payment/" + transactionId} className="ion-text-center">
                                <p>{t('myaccount.retryPayment')}</p>
                            </IonRouterLink>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent >
        </Page >
    );
}

export default PaymentError;