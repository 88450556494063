import {
    IonContent,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle, IonPage
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import { Content } from "../../interfaces/Content";
import Header from "../../components/Header/Header";
import '../../i18';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from "react-router-dom";
import getData from "../../services/getData";

const Welcome = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const [content, setContent] = useState<Content>();

    useEffect(() => {
        getData<Content>({
            recordType: 'single',
            apiUrl: 'content/kezdd-el-a-tagsagot',
            storageName: 'contents',
            storageCallback: (db) => db.table<Content>('contents').where('slug').equals('kezdd-el-a-tagsagot').first()
        })
            .then(
                response => setContent(response)
            );
    }, []);

    function getPremium() {
        history.push('/my-account/get-premium');
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    if (localStorage.getItem('grade')) {
        return <Redirect to="/my-account/profile-select" />
    }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                            <p className="text-large ion-margin-vertical">
                                {t('myaccount.premiumMebership')}
                            </p>
                            {content && <div id="content" dangerouslySetInnerHTML={{ __html: content.content }} />}
                            <IonButton className="ion-margin-top" onClick={getPremium}>{t('next')}</IonButton>
                        </IonCol>
                    </IonRow>
                    {/*
                    <IonRow>
                        <IonCol className="ion-text-center text-white">
                            {content && <div id="content" dangerouslySetInnerHTML={{ __html: content.content }} />}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonButton onClick={getPremium}>{t('next')}</IonButton>
                        </IonCol>
                    </IonRow>
                    */}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Welcome;