import {IonCol, IonContent, IonGrid, IonPage, IonRow} from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";

const LoadingComponent = () => {
    const {t} = useTranslation();

    return <IonPage>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                        {t('common.loading')}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </IonPage>
}
export default LoadingComponent;