import {IonIcon, IonLabel, IonPage} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import "./Page.scss";
import {
    basketOutline,
    homeOutline,
    peopleOutline,
    personAddOutline
} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import Profile from "../../services/profile";
import CheckMembership from "../CheckMembership/CheckMembership";
import {Redirect} from "react-router-dom";
import DataContext from "../../context/DataContext";
import User from "../../User";

const Page = ({children}: { children?: React.ReactNode }) => {
    const {t} = useTranslation();

    const {profile} = useContext(DataContext);
    const [profileChecked, setProfileChecked] = useState<boolean>(true);

    const membership = localStorage.getItem('membership');
    const hasMembership = membership === "2" || membership === "3" || membership === "4";

    const isChild = User.isAuthenticated() && hasMembership && profile.type === 1;

    const isParent = User.isAuthenticated() && hasMembership && profile.type === 0;

    if (!profileChecked) {
        if (localStorage.getItem('token') && !Profile.getId()) {
            if (window.location.pathname !== "/my-account/profile-select") {
                return <Redirect to="/my-account/profile-select"/>;
            } else {
                setProfileChecked(true);
            }
        } else {
            setProfileChecked(true);
        }
    }

    return (
        <IonPage>
            <CheckMembership/>
            {profileChecked && <>
                {isParent && <div id="sticky-menu">
                    <a href="/my-account/profile-settings">
                        <IonIcon icon={homeOutline}/>
                    </a>
                    <a href="/my-account/profile-select">
                        <IonIcon icon={peopleOutline}/>
                    </a>
                    {membership === "2" && <a href="/my-account/invite">
                        <IonIcon icon={personAddOutline}/>
                    </a>}
                    <a href="/my-account/orders">
                        <IonIcon icon={basketOutline}/>
                    </a>
                </div>}
                {isChild && <div id="sticky-menu">
                    <a href="/my-account/default/child">
                        <IonIcon icon={homeOutline}/>
                    </a>
                    {membership === "2" && <a href="/my-account/invite">
                        <IonIcon icon={personAddOutline}/>
                    </a>}
                    <a href="/my-account/profile-select">
                        <IonIcon icon={peopleOutline}/>
                    </a>
                </div>}
                {children}
            </>}

        </IonPage>
    )
}
export default Page;
