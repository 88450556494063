import {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonLabel,
    IonCheckbox,
    IonList,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonListHeader,
    IonToast,
    IonRouterLink,
    IonPage
} from "@ionic/react";

import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import '../../i18';
import {useTranslation} from 'react-i18next';
import {Redirect} from "react-router-dom";
import Api from "../../api";
import {Content} from "../../interfaces/Content";
import {cardOutline, chevronDown, chevronForward, closeOutline, personOutline, readerOutline} from "ionicons/icons";
import Profile from "../../services/profile";
import IProfile from "../../interfaces/IProfile";
import Product from "../../services/product";
import IProduct from "../../interfaces/IProduct";
import ISimplepay from "../../interfaces/ISimplepay";
import Storage from "../../services/Storage";
import formatPrice from "../../helpers/formatPrice";
import {convertToDate, getAge} from "../../services/dateHelper";

const OrderPremium = () => {
    const {t} = useTranslation();
    const [canBuy, setCanBuy] = useState<boolean>(true);
    const [billingDataChecked, setBillingDataChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(false);
    const [birthdayMax, setBirthdayMax] = useState<string>('');
    const [requiredMissing, setRequiredMissing] = useState<boolean>(true);
    const [profileDetails, setProfileDetails] = useState<IProfile>({} as IProfile);
    const [birthday, setBirthday] = useState<Date>();
    const [priceText, setPriceText] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const [simpleURL, setSimpleURL] = useState<string>();
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [product, setProduct] = useState<IProduct>({'id': 0} as IProduct);
    const perMonthText = ' / ' + t('myaccount.month');
    const perYearText = ' / ' + t('myaccount.year');
    const yearlyMembership = localStorage.getItem('yearlyMembership');

    const [active, setActive] = useState(1);

    function typedKeys<T>(o: T): (keyof T)[] {
        // type cast should be safe because that's what really Object.keys() does
        return Object.keys(o) as (keyof T)[];
    }

    const getInputPage = (input: keyof IProfile) => {
        switch (input) {
            case 'lastname':
            case 'firstname':
            case 'phone':
            case 'birthday':
                return 1;
            case 'city':
            case 'postcode':
            case 'street':
            case 'housenumber':
            case 'floor':
            case 'billingname':
            case 'vat':
                return 2
            default:
                return 0
        }
    }

    function showErrorMessage(message: string) {
        setErrorMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);

        return false;
    }

    function checkRequiredActive(index: number) {
        const requiredInputs: any = [
            [
                'lastname',
                'firstname',
                'phone',
                'birthday'
            ],
            [
                'city',
                'postcode',
                'street',
                'housenumber',
            ],
            []
            // profileDetails?.floor
        ];

        // let errors: string[] = [];
        // typedKeys(profileDetails).forEach(key => {
        //     if (requiredInputs[active-1].indexOf(key) !== -1 && !profileDetails[key]) {
        //         errors.push(t('myaccount.error' + key));
        //     }
        // });
        let errors = requiredInputs[index - 1].reduce((result: string[], item: keyof IProfile) => {
            if (profileDetails && !profileDetails[item]) {
                result.push(t('myaccount.' + item));
            }
            return result;
        }, []);

        if (errors.length) {
            showErrorMessage(t('myaccount.validationError') + "\n" + errors.join("\n"));
            return false;
        }
        return true;
    }



    const eventHandler = (e: any, index: number) => {
        e.preventDefault();
        if (checkRequiredActive(active)) {
            setActive(index);
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>();

    useEffect(() => {
        Api.get<boolean>('payment/has-active-payment')
            .then(response => {
                if (response) {
                    setCanBuy(false);
                } else {
                    Product.getMemberships().then(
                        (response) => {
                            setProductAndPricetext(response);
                            setProducts(response);
                        }
                    );

                    Profile.getProfileDataByType(0).then(
                        data => {
                            const profileData = data[0];
                            setProfileDetails(profileData);
                            if (profileData.billingname || profileData.vat) {
                                setBillingDataChecked(true);
                            }
                            if (profileData.birthday) {
                                setBirthday(new Date(profileData.birthday));
                            }
                        }
                    );
                }
            })
    }, []);

    useEffect(() => {
        if (products.length) {
            setProductAndPricetext(products);
        }
    }, [isRecurringChecked])

    if (products.length && product['id'] === 0) {
        setProductAndPricetext(products);
    }

    function setRecurringChecked(checked: boolean) {
        setIsRecurringChecked(checked);
    }

    function setProductAndPricetext(products: Array<IProduct>) {
        const checked = isRecurringChecked === false ? 0 : 1;
        let result: any = {};
        if (yearlyMembership === 'true') {
            result = getProductByRecurrenceAndPeriod(products, checked, 12);
            setProduct(result);
            setPriceText(formatPrice(result.price) + perYearText);
        } else {
            result = getProductByRecurrenceAndPeriod(products, checked, 1);
            setProduct(result);
            setPriceText(formatPrice(result.price) + perMonthText);
        }
    }

    function getProductByRecurrenceAndPeriod(products: Array<IProduct>, is_recurring: number, period: number) {
        for (let index = 0; index < products!.length; index++) {
            const item = products![index];
            if (item.period === period && item.is_recurring === is_recurring) {
                return item;
            }
        }
    }

    if (birthdayMax === '') {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDate();
        const max = new Date(year - 18, month, day);
        setBirthdayMax(convertToDate(max));
    }

    function setProfileDetailByKey(key: string, value: any) {
        if (typeof value !== "undefined" && value !== null) {
            if (key === "birthday") {
                const age = getAge(value);
                if (age < 18) {
                    showErrorMessage(t('myaccount.over18'));
                    value = null;
                }
            }
            setProfileDetails(prevState => ({...prevState, [key]: value}));
            checkRequiredInputs();
        }
    }

    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    function showContent(content: string) {
        Api.get<Content>('content/' + content)
            .then(response => {
                displayModal(response);
            });
    }

    function storeData(inputName: keyof IProfile) {
        const data = {
            storageName: 'profiles',
            apiUrl: 'profile/save',
            apiData: {
                records: [
                    {
                        id: profileDetails!.id,
                        [inputName]: profileDetails![inputName as keyof IProfile]
                    }
                ]
            },
            methodType: 'post'
        };
        Storage.update(data).catch(error => {
            let message = '';
            error[0].errors.forEach((errorString: string) => {
                if (message) {
                    message += '<br />';
                }
                message += errorString;
            });
            // setProfileDetailByKey(inputName, '')
            showErrorMessage(message);
            setActive(getInputPage(inputName));
        });

        checkRequiredInputs();
    }

    function checkRequiredInputs() {
        const requiredInputs: any = [
            profileDetails?.lastname,
            profileDetails?.firstname,
            profileDetails?.phone,
            profileDetails?.birthday,
            profileDetails?.city,
            profileDetails?.postcode,
            profileDetails?.street,
            profileDetails?.housenumber,
            // profileDetails?.floor
        ];
        if (requiredInputs.includes(null) || requiredInputs.includes(undefined) || requiredInputs.includes('')) {
            setRequiredMissing(true);
        } else {
            setRequiredMissing(false);
        }
    }

    function simplepayPayment() {
        if (!privacyChecked) {
            showErrorMessage(t('myaccount.privacyRequired'));
            return false;
        }
        if (!termsChecked) {
            showErrorMessage(t('myaccount.termsRequired'));
            return false;
        }

        for(let index = 1; index < 3; index++) {
            if (!checkRequiredActive(index)) {
                setActive(index);
                return false;
            }
        }

        Api.post<ISimplepay>('payment/simplepay', {
            body: JSON.stringify({
                product_id: product.id,
                user_id: localStorage.getItem('user_id'),
            })
        }).then(response => {
            setSimpleURL(response);
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login"/>
    }

    if (typeof simpleURL !== 'undefined') {
        window.location.href = simpleURL
    }

    if (!canBuy) {
        return <Redirect to="/home"/>
    }

    return (
        <IonPage>
            <Header/>
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={errorMessage}
                    position="top"
                    color="danger"
                />

                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <IonHeader>
                        <IonToolbar mode="ios">
                            <IonTitle slot="start">{modalContent?.title}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    slot="end"
                                    onClick={() => setShowModal(false)}
                                >
                                    <IonIcon icon={closeOutline} size="large" color="white"/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <h1>{modalContent?.title}</h1>
                        {modalContent && <div dangerouslySetInnerHTML={{__html: modalContent.content}}/>}
                    </IonContent>
                </IonModal>

                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="text-large ion-text-center ion-margin-top">
                                {t('myaccount.selectedMembership')}:&nbsp;
                                {yearlyMembership === 'true' ? t('myaccount.yearly') : t('myaccount.monthly')}, {t('myaccount.price')}:&nbsp;{priceText}
                            </p>
                            <div className="glass">
                                <IonListHeader className="ion-no-padding">
                                    <IonButton
                                        fill="clear"
                                        expand="full"
                                        className="ion-no-margin"
                                        onClick={(e) => eventHandler(e, 1)}
                                        size="large"
                                    >
                                        <IonIcon icon={personOutline} size="large"/>&nbsp;
                                        <span className="ion-text-left width-100">{t("myaccount.personalData")}</span>
                                        {active === 1 ? <IonIcon icon={chevronDown}/> :
                                            <IonIcon icon={chevronForward}/>}
                                    </IonButton>
                                </IonListHeader>
                                <IonList className={active === 1 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                                    <IonItem className="ion-margin-bottom" key="lastname">
                                        <IonLabel position="stacked">
                                            {t("myaccount.lastName")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.lastname}
                                            onIonChange={e => setProfileDetailByKey('lastname', e.detail.value!)}
                                            onIonBlur={() => storeData('lastname')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="firstname">
                                        <IonLabel position="stacked">
                                            {t("myaccount.firstName")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.firstname}
                                            onIonChange={e => setProfileDetailByKey('firstname', e.detail.value!)}
                                            onIonBlur={() => storeData('firstname')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="phone">
                                        <IonLabel position="stacked">
                                            {t("myaccount.phonePlaceholder")}*
                                        </IonLabel>
                                        <IonInput
                                            type="tel"
                                            value={profileDetails?.phone}
                                            onIonChange={e => setProfileDetailByKey('phone', e.detail.value!)}
                                            onIonBlur={() => storeData('phone')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="birthday">
                                        <IonCol size={'4'} sizeMd={'3'}>
                                            <label htmlFor="birthday">
                                                {t('myaccount.birthday')}:*
                                            </label>
                                        </IonCol>
                                        <IonCol size={'8'} sizeMd={'9'}>
                                            <input
                                                className="width-100"
                                                name="birthday"
                                                placeholder={t('myaccount.birthdayPlaceholder')}
                                                type="date"
                                                max={birthdayMax}
                                                value={profileDetails.birthday ? profileDetails.birthday : ''}
                                                onChange={e => setProfileDetailByKey('birthday', e.target.value)}
                                                onBlur={() => storeData('birthday')}
                                            />
                                        </IonCol>
                                    </IonItem>
                                    <IonItem lines="none" key="over18">
                                        {t('myaccount.over18')}
                                    </IonItem>
                                </IonList>
                            </div>

                            <div className="ion-margin-vertical glass">
                                <IonListHeader className="ion-no-padding">
                                    <IonButton
                                        fill="clear"
                                        expand="full"
                                        className="ion-no-margin"
                                        onClick={(e) => eventHandler(e, 2)}
                                        size="large"
                                    >
                                        <IonIcon icon={readerOutline} size="large"/>&nbsp;
                                        <span className="ion-text-left width-100">{t("myaccount.billingData")}</span>
                                        {active === 2 ? <IonIcon icon={chevronDown}/> :
                                            <IonIcon icon={chevronForward}/>}
                                    </IonButton>
                                </IonListHeader>
                                <IonList className={active === 2 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                                    <IonItem className="ion-margin-bottom" key="city">
                                        <IonLabel position="stacked">
                                            {t("myaccount.city")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.city}
                                            onIonChange={e => setProfileDetailByKey('city', e.detail.value!)}
                                            onIonBlur={() => storeData('city')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="postcode">
                                        <IonLabel position="stacked">
                                            {t("myaccount.postcode")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.postcode}
                                            onIonChange={e => setProfileDetailByKey('postcode', e.detail.value!)}
                                            onIonBlur={() => storeData('postcode')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="street">
                                        <IonLabel position="stacked">
                                            {t("myaccount.address1")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.street}
                                            onIonChange={e => setProfileDetailByKey('street', e.detail.value!)}
                                            onIonBlur={() => storeData('street')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="housenumber">
                                        <IonLabel position="stacked">
                                            {t("myaccount.address2")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.housenumber}
                                            onIonChange={e => setProfileDetailByKey('housenumber', e.detail.value!)}
                                            onIonBlur={() => storeData('housenumber')}
                                        />
                                    </IonItem>
                                    <IonItem key="floor">
                                        <IonLabel position="stacked">
                                            {t("myaccount.address3")}
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.floor}
                                            onIonChange={e => setProfileDetailByKey('floor', e.detail.value!)}
                                            onIonBlur={() => storeData('floor')}
                                        />
                                    </IonItem>
                                    <IonItem key="billing">
                                        <IonLabel className="ion-text-wrap">{t('myaccount.billingDifferent')}</IonLabel>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={billingDataChecked}
                                            onIonChange={e => setBillingDataChecked(e.detail.checked)}/>
                                    </IonItem>
                                    <div className={billingDataChecked === true ? 'ion-padding-start' : 'ion-hide'}>
                                        <IonItem className="ion-margin-bottom" key="billingname">
                                            <IonLabel position="stacked">
                                                {t("myaccount.billingName")}
                                            </IonLabel>
                                            <IonInput
                                                value={profileDetails?.billingname}
                                                onIonChange={e => setProfileDetailByKey('billingname', e.detail.value!)}
                                                onIonBlur={() => storeData('billingname')}
                                            />
                                        </IonItem>
                                        <IonItem className="ion-margin-bottom" key="vat">
                                            <IonLabel position="stacked">
                                                {t("myaccount.VAT")}
                                            </IonLabel>
                                            <IonInput
                                                value={profileDetails?.vat}
                                                onIonChange={e => setProfileDetailByKey('vat', e.detail.value!)}
                                                onIonBlur={() => storeData('vat')}
                                            />
                                        </IonItem>
                                    </div>
                                </IonList>
                            </div>

                            <div className="glass">
                                <IonListHeader className="ion-no-padding">
                                    <IonButton
                                        expand="full"
                                        className="ion-no-margin"
                                        onClick={(e) => eventHandler(e, 3)}
                                        size="large"
                                    >
                                        <IonIcon icon={cardOutline} size="large"/>&nbsp;
                                        <span className="ion-text-left width-100">{t("myaccount.onlinePayment")}</span>
                                        {active === 3 ? <IonIcon icon={chevronDown}/> :
                                            <IonIcon icon={chevronForward}/>}
                                    </IonButton>
                                </IonListHeader>
                                <IonList
                                    className={active === 3 ? 'ion-padding-horizontal ion-margin-bottom' : 'ion-hide'}
                                    lines="none">
                                    <IonItem key='privacy'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={privacyChecked}
                                            onIonChange={e => setPrivacyChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.chkAccept')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('adatkezelesi-tajekoztato')}
                                            >
                                                {t('myaccount.acceptPP')}
                                            </IonRouterLink>.
                                        </div>
                                    </IonItem>
                                    <IonItem key='terms'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={termsChecked} onIonChange={e => setTermsChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.chkAccept')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('altalanos-szerzodesi-feltetelek')}
                                            >
                                                {t('myaccount.acceptTOS')}
                                            </IonRouterLink>.
                                        </div>
                                    </IonItem>
                                    <IonItem key='recurring'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={isRecurringChecked}
                                            onIonChange={e => setRecurringChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.acceptRecurring')}&nbsp;
                                            (<IonRouterLink
                                            onClick={e => showContent('tajekoztato-az-ismetlodo-fizetesrol')}
                                        >
                                            {t('myaccount.recurringConditions')}
                                        </IonRouterLink>)
                                        </div>
                                    </IonItem>
                                    <IonItem key="button" className="ion-margin-top">
                                        <IonButton
                                            // disabled={!requiredMissing && privacyChecked && termsChecked ? false : true}
                                            size="default"
                                            className="ion-text-wrap"
                                            onClick={simplepayPayment}
                                        >
                                            {t('myaccount.paymentWithCreditCard')}
                                        </IonButton>
                                        <IonRouterLink
                                            href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                                            target="_blank"
                                            slot="end"
                                        >
                                            <img src="/images/simplepay_logo_color.png"/>
                                        </IonRouterLink>
                                    </IonItem>
                                </IonList>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default OrderPremium;