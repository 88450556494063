import {
    IonContent,
    IonList,
    IonPage,
    IonItem,
    IonInput,
    IonButton,
    IonToast,
    IonCol,
    IonGrid,
    IonRow,
    IonCard,
    IonCardContent,
    IonLabel,
} from '@ionic/react';
import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Api from "../../api";
import TextWithBorder from '../../components/TextWithBorder/TextWithBorder';
import Header from "../../components/Header/Header";

const ResetPassword = () => {
    const { t } = useTranslation();

    let { token } = useParams();

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordConfirm, setPasswordConfirm] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    let history = useHistory();

    if (localStorage.getItem('token')) {
        return <Redirect to="/home" />
    }

    function displayToast(message: string) {
        setErrorMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);
    }

    const submitResetPassword = () => {
        Api.post<string>('reset-password', {
            body: JSON.stringify({
                email: email,
                password: password,
                password_confirmation: passwordConfirm,
                token: token
            })
        })
            .then(response => {
                history.push('/login');
            })
            .catch(error => {
                let message = "";
                if (typeof error.errors !== "undefined") {
                    if (typeof error.errors.email !== 'undefined') {
                        message = error.errors.email.join('<br />');
                    }
                    if (typeof error.errors.password !== 'undefined') {
                        if (message) {
                            message += '<br />';
                        }
                        message += error.errors.password.join('<br />');
                    }
                } else {
                    message = error.message;
                }
                displayToast(message);
            })
            ;
    }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={errorMessage}
                    position="top"
                    color="danger"
                />

                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className="ion-margin-vertical ion-padding-top">
                                <TextWithBorder text={t('resetpassword.title')} key="label" />
                            </div>
                            <IonLabel>
                                {t('resetpassword.Email')}
                            </IonLabel>
                            <IonInput
                                value={email}
                                onIonChange={e => setEmail(e.detail.value!)}
                            />
                            <IonLabel>
                                {t('resetpassword.Password')}
                            </IonLabel>
                            <IonInput
                                type="password"
                                value={password}
                                onIonChange={e => setPassword(e.detail.value!)}
                            />
                            <IonLabel>
                                {t('resetpassword.PasswordConfirm')}
                            </IonLabel>
                            <IonInput
                                type="password"
                                value={passwordConfirm}
                                onIonChange={e => setPasswordConfirm(e.detail.value!)}
                            />
                            <IonButton
                                className="ion-margin-top width-100"
                                size="default"
                                onClick={submitResetPassword}>
                                {t('resetpassword.submit')}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ResetPassword;