import { IonAlert, IonCol, IonContent, IonGrid, IonItem, IonList, IonRow, IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useTranslation } from "react-i18next";
import Api from "../../api";
import Page from "../../components/Page/Page";

interface Payment {
    is_active: number;
    id: number;
    created_at: string;
    quantity: number;
}

const DeviceDelete = () => {
    const { t } = useTranslation();
    const [payments, setPayments] = useState<Payment[]>([]);
    const [payment, setPayment] = useState<Payment>();
    const [showAlert, setShowAlert] = useState(false);

    function deleteDevice(deletablePayment: Payment) {
        setPayment(deletablePayment);
        setShowAlert(true);
    }

    function getPayments() {
        Api.get<Payment>('payment/devices')
            .then(response => {
                setPayments(response);
            });
    }

    useEffect(() => {
        getPayments();
    }, []);

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <IonAlert
                                isOpen={showAlert}
                                onDidDismiss={() => setShowAlert(false)}
                                cssClass='my-custom-class'
                                header={t('deviceslist.alerttitle')}
                                message={t('deviceslist.alertmessage')}
                                buttons={[
                                    {
                                        text: t('deviceslist.cancel'),
                                        role: 'cancel',
                                        cssClass: 'secondary',
                                        handler: () => {
                                            setShowAlert(false);
                                        }
                                    },
                                    {
                                        text: t('deviceslist.ok'),
                                        handler: () => {
                                            Api.delete('payment/device/' + payment!.id
                                            ).then(() => {
                                                getPayments();
                                            })
                                        }
                                    }
                                ]}
                            />

                            <p className="text-large ion-text-center ion-margin-vertical text-white">
                                {t('devicedelete.title')}
                            </p>
                            {payments.length > 0 &&
                                <IonList className="glass" lines="none">
                                    {payments.map(payment =>
                                        <IonItem key={payment.id} className="ion-padding-start ion-padding-end ion-margin-bottom">
                                            {payment.quantity} {t('devicedelete.piece')}&nbsp;
                                            {t('devicedelete.created_at')}: {payment.created_at}
                                            {payment.is_active === 0 && <> <br />{t('devicedelete.expired')} {payment.created_at}</>}
                                            {payment.is_active === 1 &&
                                                <IonButton
                                                    fill="clear"
                                                    slot="end"
                                                    onClick={() => deleteDevice(payment)}
                                                    className="clickable ion-no-margin"
                                                >
                                                    {t('devicedelete.delete')}
                                                </IonButton>
                                            }
                                        </IonItem>
                                    )}
                                </IonList>
                            }
                            {payments.length === 0 &&
                                <p className="ion-text-center">
                                    {t('devicedelete.noDevice')}
                                </p>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    )
}

export default DeviceDelete;