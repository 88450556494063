import React from "react";
import {useTranslation} from "react-i18next";
import formatPrice from "../../helpers/formatPrice";
import IProduct from "../../interfaces/IProduct";

const Product = ({period, price, priceText, product}: {
    period: number,
    price: number,
    priceText: string,
    product: IProduct
}) => {
    const {t} = useTranslation();

    return (
        <>
            <p className="text-large ion-text-center ion-margin-top">
                {t('myaccount.selectedMembership')}:&nbsp;
                {period === 12 ? t('myaccount.yearly') : t('myaccount.monthly')}, {t('myaccount.price')}:&nbsp;{formatPrice(price)}{priceText}
            </p>
            <p>{product.name}</p>
        </>
    )
}
export default Product