import {
    IonButton,
    IonCheckbox,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonToast
} from "@ionic/react";
import {chevronDown, chevronForward, newspaperOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import IProfile from "../../interfaces/IProfile";
import React, {useEffect, useState} from "react";
import Storage from "../../services/Storage";

const OrdersInvoiceData = ({profile}: { profile: IProfile }) => {
    const {t} = useTranslation();
    const [active, setActive] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [oldProfileDetails, setOldProfileDetails] = useState<IProfile>({} as IProfile);
    const [profileDetails, setProfileDetails] = useState<IProfile>({} as IProfile);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const [requiredMissing, setRequiredMissing] = useState<boolean>(true);
    const [billingDataChecked, setBillingDataChecked] = useState(false);

    function setProfileDetailByKey(key: string, value: any) {
        if (typeof value !== "undefined" && value !== null) {
            setProfileDetails(prevState => ({...prevState, [key]: value}));
            checkRequiredInputs();
        }
    }

    useEffect(() => {
        if (!profileDetails.id) {
            setProfileDetails(profile);
        }
    }, [profile, profileDetails, setProfileDetails])

    function checkRequiredInputs() {
        const requiredInputs: any = [
            profileDetails?.lastname,
            profileDetails?.firstname,
            profileDetails?.phone,
            profileDetails?.birthday,
            profileDetails?.city,
            profileDetails?.postcode,
            profileDetails?.street,
            profileDetails?.housenumber,
            // profileDetails?.floor
        ];
        if (requiredInputs.includes(null) || requiredInputs.includes(undefined) || requiredInputs.includes('')) {
            setRequiredMissing(true);
        } else {
            setRequiredMissing(false);
        }
    }

    function storeData() {
        const data = {
            storageName: 'profiles',
            apiUrl: 'profile/save',
            apiData: {
                records: [
                    profileDetails
                ]
            },
            methodType: 'post'
        };
        Storage.update(data)
            .then(() => {
                setEditMode(false);
            })
            .catch(error => {
            let message = '';
            error[0].errors.forEach((errorString: string) => {
                if (message) {
                    message += '<br />';
                }
                message += errorString;
            });
            setErrorMessage(message);
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        });

        checkRequiredInputs();
    }

    return (
        <>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={errorMessage}
                position="top"
                color="danger"
            />
            <IonListHeader className="ion-no-padding">
                <IonButton
                    size="large"
                    fill="clear"
                    expand="full"
                    onClick={(e) => setActive(prevState => !prevState)}
                >
                    <IonIcon icon={newspaperOutline} size="large"/>&nbsp;
                    <span className="ion-text-left width-100">{t("parentdefaultinvoicedata.title")}</span>
                    {active ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === true ? 'ion-padding-start ion-margin-bottom' : 'ion-hide'} lines="none">
                {!editMode && <IonItem>
                    <div>
                        <div>{profileDetails.lastname} {profileDetails.firstname}</div>
                        <div>{profileDetails.city}</div>
                        <div>{profileDetails.street} {profileDetails.housenumber} {profileDetails.floor}</div>
                        <div>{profileDetails.postcode}</div>
                        <IonButton
                            onClick={(e) => {
                                setOldProfileDetails(profileDetails);
                                setEditMode(true);
                            }}
                            fill="clear"
                            size="small"
                            className="text-yellow ion-no-margin ion-no-padding"
                        >
                            {t('parentdefaultinvoicedata.changeAddress')}
                        </IonButton>
                    </div>
                </IonItem>}
                {editMode && <>
                    <IonItem className="ion-margin-bottom" key="city">
                        <IonLabel position="stacked">
                            {t('myaccount.city')}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.city}
                            onIonChange={e => setProfileDetailByKey('city', e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem className="ion-margin-bottom" key="postcode">
                        <IonLabel position="stacked">
                            {t('myaccount.postcode')}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.postcode}
                            onIonChange={e => setProfileDetailByKey('postcode', e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem className="ion-margin-bottom" key="street">
                        <IonLabel position="stacked">
                            {t('myaccount.address1')}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.street}
                            onIonChange={e => setProfileDetailByKey('street', e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem className="ion-margin-bottom" key="housenumber">
                        <IonLabel position="stacked">
                            {t('myaccount.address2')}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.housenumber}
                            onIonChange={e => setProfileDetailByKey('housenumber', e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem key="floor">
                        <IonLabel position="stacked">
                            {t('myaccount.address3')}
                        </IonLabel>
                        <IonInput
                            value={profileDetails?.floor}
                            onIonChange={e => setProfileDetailByKey('floor', e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem key="billing">
                        <IonLabel className="ion-text-wrap">{t('myaccount.billingDifferent')}</IonLabel>
                        <IonCheckbox
                            slot="start"
                            className="ion-no-margin ion-margin-end"
                            checked={billingDataChecked}
                            onIonChange={e => setBillingDataChecked(e.detail.checked)}/>
                    </IonItem>
                    <div className={billingDataChecked ? 'ion-padding-start' : 'ion-hide'}>
                        <IonItem className="ion-margin-bottom" key="billingname">
                            <IonLabel position="stacked">
                                {t('myaccount.billingName')}
                            </IonLabel>
                            <IonInput
                                value={profileDetails?.billingname}
                                onIonChange={e => setProfileDetailByKey('billingname', e.detail.value!)}
                            />
                        </IonItem>
                        <IonItem className="ion-margin-bottom" key="vat">
                            <IonLabel position="stacked">
                                {t('myaccount.VAT')}
                            </IonLabel>
                            <IonInput
                                value={profileDetails?.vat}
                                onIonChange={e => setProfileDetailByKey('vat', e.detail.value!)}
                            />
                        </IonItem>
                    </div>
                    <IonItem className="ion-margin-bottom" key="submit">
                        <IonButton
                            slot="start"
                            className="ion-margin-top"
                            onClick={(e) => {storeData()}}
                            size="default"
                        >
                            {t('Save')}
                        </IonButton>
                        <IonButton
                            slot="end"
                            color="light"
                            className="ion-margin-top"
                            onClick={(e) => {setProfileDetails(oldProfileDetails); setEditMode(false)}}
                            size="default"
                        >
                            {t('cancel')}
                        </IonButton>
                    </IonItem>
                </>}

            </IonList>
        </>
    );
}
export default OrdersInvoiceData;