import React, {useEffect} from "react";
import {Redirect, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonRow} from "@ionic/react";
import Header from "../../components/Header/Header";
import Page from "../../components/Page/Page";

const Invite = () => {
    const {t} = useTranslation();
    let { token } = useParams();

    useEffect(() => {

    }, [token]);

    if (token) {
        localStorage.setItem('invitation-token', token);
        return <Redirect to="/registration"/>
    }


    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent>
                                    {token}
                                    {t('invite.badToken')}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default Invite;