import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import '../../i18';
import {useTranslation} from 'react-i18next';
import Api from "../../api";
import {LoginToken} from "../../interfaces/auth/LoginToken";
import handleLogin from "../../services/handleLogin";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const FacebookLogin = () => {
    const {t} = useTranslation();
    let query = useQuery();
    const history = useHistory();
    const [apiCalled, setApiCalled] = useState(false);

    useEffect(() => {
        if (apiCalled) {
            return;
        }
        setApiCalled(true);
        Api.get<LoginToken>('login/facebook/callback?code=' + query.get('code'))
            .then(response => {
                handleLogin(response);
            })
            .catch(error => {
                console.error(error);
            });
    }, [query, history, apiCalled]);

    return (
        <div>
            {t('login.redirecting')}
        </div>
    );
}

export default FacebookLogin;