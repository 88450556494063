import React, { useEffect, useState } from "react";
import Page from "../../components/Page/Page";
import Header from "../../components/Header/Header";
import {
    IonButton, IonButtons,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonModal,
    IonRouterLink,
    IonRow,
    IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import config from "../../config/config";
import formatPrice from "../../helpers/formatPrice";
import getData from "../../services/getData";
import { Content } from "../../interfaces/Content";
import IProduct from "../../interfaces/IProduct";
import Product from "../../services/product";
import { cardOutline, closeOutline } from "ionicons/icons";
import Api from "../../api";
import ISimplepay from "../../interfaces/ISimplepay";

const RenewPayment = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState<Array<string>>();
    const [subscription, setSubscription] = useState(false);
    const [price, setPrice] = useState<string>("");
    const [devicePrice, setDevicePrice] = useState<string>("");
    const [totalPrice, setTotalPrice] = useState<string>("");
    const yearlyDiscount = config("myaccount.yearlyDiscount");
    const [mode, setMode] = useState<boolean>(false);
    const [monthlyDiscount, setMonthlyDiscount] = useState<any>();
    const [devices, setDevices] = useState<Array<IProduct>>([]);
    const [device, setDevice] = useState<IProduct>({} as IProduct);
    const [deviceCount, setDeviceCount] = useState<number>(0);
    const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(false);
    const [products, setProducts] = useState<Array<IProduct>>([]);
    const [product, setProduct] = useState<IProduct>({ 'id': 0 } as IProduct);
    const perYearText = ' / ' + t('myaccount.year');
    const perMonthText = ' / ' + t('myaccount.month');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>();
    const [requiredMissing, setRequiredMissing] = useState<boolean>(true);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [redirectURL, setRedirectURL] = useState<string>();

    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    function showContent(content: string) {
        Api.get<Content>('content/' + content)
            .then(response => {
                displayModal(response);
            });
    }

    useEffect(() => {
        localStorage.setItem('yearlyMembership', String(subscription));
        setProductAndPricetext();
    }, [subscription]);

    useEffect(() => {
        setProductAndPricetext();
    }, [mode]);

    function redirectpayPayment() {
        Api.post<ISimplepay>('payment/renew-membership', {
            body: JSON.stringify({
                product_id: product.id,
                mode: mode,
                device_id: mode ? device.id : 0,
                device_quantity: mode ? deviceCount : 0,
                user_id: localStorage.getItem('user_id'),
            })
        }).then(response => {
            setRedirectURL(response);
        })
    }

    function getProductByRecurrenceAndPeriod(is_recurring: number, period: number) {
        for (let index = 0; index < products!.length; index++) {
            const item = products![index];
            if (item.period === period && item.is_recurring === is_recurring) {
                return item;
            }
        }
    }

    function getDeviceByPeriod(period: number) {
        for (let index = 0; index < devices!.length; index++) {
            const item = devices![index];
            if (item.period === period) {
                return item;
            }
        }
    }

    function setProductAndPricetext() {
        const checked = isRecurringChecked === false ? 0 : 1;
        let localProduct: any = {};
        let localDevice: any = {};
        let total: number;
        if (products.length === 0) {
            return;
        }
        if (subscription) {
            localProduct = getProductByRecurrenceAndPeriod(checked, 12);
            setProduct(localProduct);
            total = parseInt(localProduct.price);
            setPrice(formatPrice(localProduct.price) + perYearText);
            if (deviceCount && devices.length && mode) {
                localDevice = getDeviceByPeriod(12);
                setDevice(localDevice);
                total += parseInt(localDevice.price);
                setDevicePrice(formatPrice(localDevice.price) + perYearText)
            }
        } else {
            localProduct = getProductByRecurrenceAndPeriod(checked, 1);
            setProduct(localProduct);
            total = parseInt(localProduct.price);
            setPrice(formatPrice(localProduct.price) + perMonthText);
            if (deviceCount && devices.length && mode) {
                localDevice = getDeviceByPeriod(1);
                setDevice(localDevice);
                total += parseInt(localDevice.price);
                setDevicePrice(formatPrice(localDevice.price) + perMonthText)
            }
        }
        setTotalPrice(formatPrice(total) + perMonthText);
    }


    useEffect(() => {
        getData<Content>({
            recordType: 'single',
            apiUrl: 'content/brainclub-tagsag-hosszabbitas',
            storageName: 'contents',
            storageCallback: (db) => db.table<Content>('contents').where('slug').equals('brainclub-tagsag-hosszabbitas').first()
        })
            .then(
                response => {
                    const content = response.content.split('<!-- pagebreak -->');
                    setContent(content);
                }
            );

    }, []);

    useEffect(() => {
        getData<any>({
            apiUrl: 'payment/get-last-membership',
        }).then(response => {
            if (response.details.devices > 0) {
                setMode(true);
                setDeviceCount(response.details.devices)
            }
            if (response.details.membership == 0) {
                setRedirectURL('/my-account/welcome');
            }
            setMonthlyDiscount(response.monthlyDiscount);
        });
    }, []);

    useEffect(() => {
        Product.getMemberships().then(
            (response) => {
                setProducts(response);
            }
        );

        Product.getDevices().then(
            (response) => {
                setDevices(response);
            }
        );
    }, []);

    useEffect(() => {
        if (products.length && devices.length) {
            setProductAndPricetext();
        }
    }, [products, devices]);

    if (typeof redirectURL !== 'undefined') {
        window.location.href = redirectURL
    }

    return <Page>
        <Header />
        <IonContent fullscreen>
            <IonModal
                isOpen={showModal}
                onDidDismiss={() => setShowModal(false)}
            >
                <IonHeader>
                    <IonToolbar mode="ios">
                        <IonTitle slot="start">{modalContent?.title}</IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                slot="end"
                                onClick={() => setShowModal(false)}
                            >
                                <IonIcon icon={closeOutline} size="large" color="white" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <h1>{modalContent?.title}</h1>
                    {modalContent && <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />}
                </IonContent>
            </IonModal>

            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                        <IonCard>
                            <IonCardContent className={"ion-text-center"}>
                                {content && <div id="content" dangerouslySetInnerHTML={{ __html: content[0] }} />}
                                {/*<IonSegment*/}
                                {/*    mode="ios"*/}
                                {/*    className="ion-margin-top"*/}
                                {/*    onIonChange={e => setSubscription(prevState => !prevState)}*/}
                                {/*    value={subscription === true ? 'yearly' : 'monthly'}*/}
                                {/*>*/}
                                {/*    <IonSegmentButton value="monthly">*/}
                                {/*        <IonLabel>*/}
                                {/*            <h2>{t('myaccount.monthlyMembership')}</h2>*/}
                                {/*        </IonLabel>*/}
                                {/*    </IonSegmentButton>*/}
                                {/*    <IonSegmentButton value="yearly">*/}
                                {/*        <IonLabel>*/}
                                {/*            <h2>{t('myaccount.yearlyMembership')} -{yearlyDiscount}%</h2>*/}
                                {/*        </IonLabel>*/}
                                {/*    </IonSegmentButton>*/}
                                {/*</IonSegment>*/}
                                <IonRow>
                                    <IonCol>
                                        <IonText color="primary">
                                            <h2><b><span id={"price"}>{price}</span></b></h2>
                                        </IonText>
                                        {content && <div dangerouslySetInnerHTML={{ __html: content[1] }} />}
                                    </IonCol>
                                </IonRow>
                                {deviceCount ? <>
                                    <IonRow>
                                        <IonCol>
                                            {content && <div dangerouslySetInnerHTML={{ __html: content[2] }} />}
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonText color="primary">
                                                <h2><b><span id={"device-price"}>{devicePrice}</span></b></h2>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonSegment
                                        mode="ios"
                                        className="ion-margin-top"
                                        onIonChange={e => setMode(prevState => !prevState)}
                                        value={mode ? 'withDevices' : 'withoutDevices'}
                                    >
                                        <IonSegmentButton value="withDevices">
                                            <IonLabel>
                                                <h2>{t('renewpayment.withDevices')}</h2>
                                            </IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="withoutDevices">
                                            <IonLabel>
                                                <h2>{t('renewpayment.withoutDevices')}</h2>
                                            </IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                    <IonRow>
                                        <h2 className="ion-text-center width-100"><b><span id={"total-price"}>{totalPrice}</span></b></h2>
                                    </IonRow>
                                </> : <></>}
                                <IonListHeader className="ion-no-padding">
                                    <IonButton
                                        fill="clear"
                                        expand="full"
                                        className="ion-no-padding"
                                    >
                                        <IonIcon icon={cardOutline} />&nbsp;
                                        <IonLabel className="ion-text-left width-100">{t("myaccount.onlinePayment")}</IonLabel>
                                    </IonButton>
                                </IonListHeader>
                                <IonList lines="none">
                                    <IonItem key='privacy'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={privacyChecked}
                                            onIonChange={e => setPrivacyChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.chkAccept')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('adatkezelesi-tajekoztato')}
                                            >
                                                {t('myaccount.acceptPP')}
                                            </IonRouterLink>.
                                        </div>
                                    </IonItem>
                                    <IonItem key='terms'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={termsChecked} onIonChange={e => setTermsChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.chkAccept')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('altalanos-szerzodesi-feltetelek')}
                                            >
                                                {t('myaccount.acceptTOS')}
                                            </IonRouterLink>.
                                        </div>
                                    </IonItem>
                                    <IonItem key='recurring'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={isRecurringChecked}
                                            onIonChange={e => setIsRecurringChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.acceptRecurring')}&nbsp;
                                            (<IonRouterLink
                                                onClick={e => showContent('tajekoztato-az-ismetlodo-fizetesrol')}
                                            >
                                                {t('myaccount.recurringConditions')}
                                            </IonRouterLink>)
                                        </div>
                                    </IonItem>
                                    <IonItem key="button" className="ion-margin-top">
                                        <IonButton
                                            disabled={privacyChecked && termsChecked ? false : true}
                                            size="default"
                                            className="ion-text-wrap"
                                            onClick={redirectpayPayment}
                                        >
                                            {t('myaccount.paymentWithCreditCard')}
                                        </IonButton>
                                        <IonRouterLink
                                            href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                                            target="_blank"
                                            slot="end"
                                        >
                                            <img src="/images/simplepay_logo_color.png" />
                                        </IonRouterLink>
                                    </IonItem>
                                </IonList>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>

        </IonContent>
    </Page>
}

export default RenewPayment;