import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as hu from "./langs/hu.json";
import * as en from "./langs/en.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: en.translation
    },

    hu: {
        translation: hu.translation
    }
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "hu",

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;