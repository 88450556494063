import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    IonButton, IonButtons,
    IonCheckbox,
    IonCol,
    IonContent, IonGrid, IonHeader, IonIcon,
    IonItem,
    IonList,
    IonListHeader,
    IonModal,
    IonRouterLink,
    IonRow,
    IonSelect, IonSelectOption, IonTitle, IonToolbar
} from "@ionic/react";
import Header from "../../components/Header/Header";
import Api from "../../api";
import {closeOutline} from "ionicons/icons";
import {Content} from "../../interfaces/Content";
import ISimplepay from "../../interfaces/ISimplepay";
import Page from "../../components/Page/Page";

interface DevicePrice {
    product_id: number;
    name: string;
    original_price: number;
    nextPayment: number;
    price: number;
    period: number;
}

const NewDevice = () => {
    const {t} = useTranslation();
    const [canBuy, setCanBuy] = useState<boolean>(true);
    const [deviceCount, setDeviceCount] = useState<number>(1);
    const [devicePrice, setDevicePrice] = useState<DevicePrice>({} as DevicePrice);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [isRecurringChecked, setIsRecurringChecked] = useState<boolean>(false);
    const [apiCall, setApiCall] = useState<boolean>(false);


    let numbers: number[] = [];
    for (let i = 1; i <= 10; i++) {
        numbers.push(i);
    }

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<Content>();
    const [simpleURL, setSimpleURL] = useState<string>('');


    function displayModal(modalContent: Content) {
        setModalContent(modalContent);
        setShowModal(true);
    }

    function showContent(content: string) {
        Api.get<Content>('content/' + content)
            .then(response => {
                displayModal(response);
            });
    }

    function simplepayPayment() {
        setApiCall(true);
        Api.post<ISimplepay>('payment/simplepay', {
            body: JSON.stringify({
                product_id: devicePrice.product_id,
                quantity: deviceCount,
                user_id: localStorage.getItem('user_id'),
            })
        }).then(response => {
            setSimpleURL(response);
        })
    }

    useEffect(() => {
        Api.get<DevicePrice>('get-device-price-by-membership').then(response => {
            setDevicePrice(response);
        }).catch(response => {
            setCanBuy(false);
        })
    }, [setDevicePrice, setCanBuy]);

    if (simpleURL !== '') {
        window.location.href = simpleURL

        return <Page>
            <Header/>
            <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="ion-text-center text-large ion-margin-vertical">
                                <a href={simpleURL}>{t('newdevice.simplepayLink')}</a>
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    }

    if (!canBuy) {
        return <Page>
            <Header/>
            <IonContent>
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="ion-text-center text-large ion-margin-vertical">
                                {t('page.newDevice')}
                            </p>

                            <div className="ion-padding-horizontal">
                                <p className="text-white">
                                    {t('newdevice.canNotBuy')}
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    }

    return (
        <Page>
            <Header/>
            <IonContent>

                <IonModal
                    isOpen={showModal}
                    onDidDismiss={() => setShowModal(false)}
                >
                    <IonHeader>
                        <IonToolbar mode="ios">
                            <IonTitle slot="start">{modalContent?.title}</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    slot="end"
                                    onClick={() => setShowModal(false)}
                                >
                                    <IonIcon icon={closeOutline} size="large" color="white"/>
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent className="ion-padding">
                        <h1>{modalContent?.title}</h1>
                        {modalContent && <div dangerouslySetInnerHTML={{__html: modalContent.content}}/>}
                    </IonContent>
                </IonModal>

                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="ion-text-center text-large ion-margin-vertical">
                                {t('page.newDevice')}
                            </p>
                            <div className="glass">
                                <IonListHeader className="form-block-heading ion-align-items-center">
                                    <div className="ion-padding-horizontal">
                                        <p className="text-white">
                                            {t('newdevice.title')} {devicePrice.name}
                                        </p>
                                    </div>
                                </IonListHeader>
                                <IonList className="ion-padding-horizontal" lines="none">
                                    <IonItem
                                        className="ion-no-padding ion-no-margin no-min-height"
                                        lines="none"
                                        button={false}
                                    >
                                        {t('newdevice.numberofdevices')}:&nbsp;
                                        <IonSelect
                                            value={deviceCount}
                                            onIonChange={e => setDeviceCount(e.detail.value)}
                                            className="ion-no-padding"
                                            cancelText={t('cancel')}
                                        >
                                            {numbers.map(item => (
                                                <IonSelectOption key={item} value={item}>{item}</IonSelectOption>
                                            ))}
                                        </IonSelect>
                                    </IonItem>
                                </IonList>
                                <div className="ion-padding-start ion-padding-end">
                                    {devicePrice.period === 1 && t('newdevice.priceMonthlyTitle')}
                                    {devicePrice.period === 12 && t('newdevice.priceYearlyTitle')}&nbsp;
                                    <em>
                                        {devicePrice.original_price} {t('newdevice.money')}&nbsp;/&nbsp;
                                        {devicePrice.period === 1 && t('newdevice.priceMonthlyText')}
                                        {devicePrice.period === 12 && t('newdevice.priceYearlyText')}
                                    </em>
                                    <br/>
                                    {t('newdevice.priceDescription').replace('__', devicePrice.nextPayment?.toString())}
                                    {deviceCount > 1 &&
                                        <>
                                            <br/>
                                            {t('newdevice.priceForOne')}:&nbsp;
                                            <em>{devicePrice.price} {t('newdevice.money')}</em>
                                        </>
                                    }
                                    <br/>
                                    <b>{t('newdevice.price')}:&nbsp;</b>
                                    {deviceCount > 1 && <> ({deviceCount} X {devicePrice.price} {t('newdevice.money')})</>}
                                    <em><b>{devicePrice.price * deviceCount} {t('newdevice.money')}</b></em>
                                </div>

                                <IonListHeader className="ion-margin-top ion-no-padding ion-padding-start">
                                    <p className="text-white">{t("myaccount.onlinePayment")}</p>
                                </IonListHeader>
                                <IonList lines="none" className="ion-padding-start ion-margin-bottom">
                                    <IonItem key='privacy'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={privacyChecked}
                                            onIonChange={e => setPrivacyChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.chkAccept')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('adatkezelesi-tajekoztato')}
                                            >
                                                {t('myaccount.acceptPP')}
                                            </IonRouterLink>.
                                        </div>
                                    </IonItem>
                                    <IonItem key='terms'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={termsChecked}
                                            onIonChange={e => setTermsChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.chkAccept')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('altalanos-szerzodesi-feltetelek')}
                                            >
                                                {t('myaccount.acceptTOS')}
                                            </IonRouterLink>.
                                        </div>
                                    </IonItem>
                                    <IonItem key='recurring'>
                                        <IonCheckbox
                                            slot="start"
                                            className="ion-no-margin ion-margin-end"
                                            checked={isRecurringChecked}
                                            onIonChange={e => setIsRecurringChecked(e.detail.checked)}
                                        />
                                        <div className="toplayer">
                                            {t('myaccount.acceptRecurring')}&nbsp;
                                            <IonRouterLink
                                                onClick={e => showContent('tajekoztato-az-ismetlodo-fizetesrol')}
                                            >
                                                ({t('myaccount.recurringConditions')})
                                            </IonRouterLink>
                                        </div>
                                    </IonItem>
                                    <IonItem>
                                        <IonButton
                                            disabled={!(privacyChecked && termsChecked) || apiCall}
                                            size="default"
                                            className="ion-text-wrap"
                                            onClick={simplepayPayment}
                                        >
                                            {t('myaccount.paymentWithCreditCard')}
                                        </IonButton>
                                        <IonRouterLink
                                            href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
                                            target="_blank"
                                            slot="end"
                                        >
                                            <img src="/images/simplepay_logo_color.png"  alt={t('myaccount.simplepayTerms')} title={t('myaccount.simplepayTerms')}/>
                                        </IonRouterLink>
                                    </IonItem>
                                </IonList>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    );
}

export default NewDevice;