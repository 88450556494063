import { IonAvatar, IonIcon } from "@ionic/react";
import { personCircleOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";

const Avatar = (props: any) => {
    const [hasImage, setHasImage] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        if (props.profileId && props.profileImage) {
            setHasImage(true);
            setImageUrl("/uploads/profile/" + props.profileId);
        }
    }, [props.profileId, props.profileImage]);



    return (
        <>
            {hasImage === false ?
                <>
                    <IonIcon className="profile-default" icon={personCircleOutline} />
                </>
                :
                <>
                    <IonAvatar>
                        <img src={imageUrl + '?' + Date.now()} alt="" />
                    </IonAvatar>
                </>
            }
        </>
    );
};

export default Avatar;
