import WatchedVideos from "../../components/WatchedVideos/WatchedVideos";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonGrid,
    IonItem,
    IonList,
    IonRow
} from "@ionic/react";
import SubjectList from "../../components/SubjectList/SubjectList";
import Header from "../../components/Header/Header";
import Page from "../../components/Page/Page";
import "./ChildDefaultMain.scss";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DataContext from "../../context/DataContext";

const ChildDefaultMain = () => {
    const { t } = useTranslation();
    const grade = localStorage.getItem('grade');
    const { profile } = useContext(DataContext);

    return (
        <Page>
            <Header />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            {profile &&
                                <p className="text-large ion-text-center ion-margin-bottom">
                                    {t('childdefaultmain.hi')} {profile!.firstname}!
                                </p>
                            }
                            {/*<div className="glass ion-margin-bottom">*/}
                                <WatchedVideos />
                            {/*</div>*/}
                            {/*<div className="glass ion-margin-bottom">*/}
                                <SubjectList />
                            {/*</div>*/}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    )
}

export default ChildDefaultMain;