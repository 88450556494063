import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import setData from "../../services/setData";
import Profile from "../../services/profile";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPopover, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import TextWithBorder from "../TextWithBorder/TextWithBorder";
import './VideoPopup.scss';
import Toast from "../Toast";
import { closeOutline } from "ionicons/icons";

const VideoPopup = ({ type, video_id, visible, onHide }: {
    type: number;
    video_id: number | undefined;
    visible: boolean;
    onHide: (e: any) => void;
}) => {
    const { t } = useTranslation();
    const [videoComment, setVideoComment] = useState<string>('');
    const [toggleLike, setToggleLike] = useState<boolean>(false);
    const [showToast, setShowToast] = useState<boolean>(false);

    function saveFeedback(like: number, comment: string) {
        setData({
            apiUrl: 'videofeedbacks',
            apiData: {
                records: [
                    {
                        type: type,
                        video_id: video_id,
                        profile_id: Profile.getId(),
                        like: like,
                        comment: comment
                    }
                ]
            },
            storageName: 'videofeedbacks',
            methodType: 'post'
        });
    }

    function closePopover(e: any) {
        if (toggleLike) {
            saveFeedback(1, '');
        }
            setVideoComment('');
        onHide(e);
    }

    function validateComment(e: any) {
        if (!videoComment && !toggleLike) {
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        } else {
            saveFeedback(toggleLike ? 1 : 0, videoComment);
            closePopover(e);
        }
    }

    return (
        <IonPopover
            cssClass="feedback-popover"
            isOpen={visible}
            onDidDismiss={closePopover}
        >
            <Toast message={t('videos.errorValidateComment')} show={showToast} onHide={() => setShowToast(false)} />

            <IonHeader>
                <IonToolbar mode="ios">
                    <IonTitle slot="start">
                        {type === 0 ?
                            <>
                                {t('videos.pushLike')}
                            </>
                            :
                            <>
                                {t('videos.descriptionComment')}
                            </>
                        }
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton slot="end"
                            onClick={closePopover}>
                            <IonIcon icon={closeOutline} size="large" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {type === 0 ?
                    <>
                        <img
                            src={toggleLike ? 'assets/unlike.png' : 'assets/like.png'}
                            alt=""
                            onClick={() => setToggleLike(prevState => !prevState)}
                            className="clickable"
                        />
                        <TextWithBorder text={t('videos.or')} />
                        <p>{t('videos.videoComment')}</p>
                    </> : <></>
                }
                <IonTextarea
                    placeholder={t('videos.commentPlaceholder')}
                    className="ion-text-left"
                    rows={3}
                    value={videoComment}
                    onIonChange={e => setVideoComment(e.detail.value!)}
                />
                <IonButton
                    size="default"
                    onClick={validateComment}
                >
                    {t('videos.saveComment')}</IonButton>
            </IonContent>
            {/* {type === 0 ?
                <>
                    <p className="ion-no-margin">{t('videos.pushLike')}</p>
                    <img src={toggleLike ? 'assets/unlike.png' : 'assets/like.png'} alt="" onClick={() => setToggleLike(prevState => !prevState)} />
                    <TextWithBorder text={t('videos.or')} />
                    <p>{t('videos.videoComment')}</p>
                </>
                :
                <>
                    <p className="ion-no-margin">{t('videos.descriptionComment')}</p>
                </>
            }
            <IonTextarea className="ion-text-left" rows={3} value={videoComment} onIonChange={e => setVideoComment(e.detail.value!)} />
            <IonButton
                color="primary"
                size="default"
                onClick={validateComment}
            >
                {t('videos.saveComment')}</IonButton> */}
        </IonPopover>
    );
}

export default VideoPopup;