import React, { useEffect } from "react";
import {IonContent} from "@ionic/react";
import Header from "../../components/Header/Header";
import Api from "../../api";
import Page from "../../components/Page/Page";

const MyAccount = () => {
    useEffect(() => {
        Api.get<any>('get-device-price-by-membership/' + localStorage.getItem('user_id'))
            .then(response => {
                // console.log(response);
            });
    }, []);


    return (
        <Page>
            <Header/>
            <IonContent fullscreen>
                <div>my account</div>
            </IonContent>
        </Page>
    );
};

export default MyAccount;