import Header from "../../components/Header/Header";
import React from "react";
import Widget404 from "../../components/Widget404/Widget404";
import Page from "../../components/Page/Page";
import {IonContent} from "@ionic/react";

const Page404 = () => {

    return <Page>
        <Header/>
        <IonContent>
            <Widget404 force={true} />
        </IonContent>
    </Page>;
}
export default Page404;