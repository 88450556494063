import {
    IonContent,
    IonPage,
    IonInput,
    IonButton,
    IonNote,
    IonRouterLink,
    IonToast,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel
} from '@ionic/react';
import React, { useState } from 'react';
import './Login.scss';
import '../../i18';
import { useTranslation } from 'react-i18next';
import Api from "../../api";
// import { SocialiteUrl } from "../../interfaces/auth/SocialiteUrl";
import TextWithBorder from "../../components/TextWithBorder/TextWithBorder";
import { LoginToken } from "../../interfaces/auth/LoginToken";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header/Header";
import handleLogin from "../../services/handleLogin";

const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);

    if (localStorage.getItem('token')) {
        return <Redirect to="/home" />
    }

    const submitLogin = () => {
        const data = {
            email: email,
            password: password,
        }
        //Some password manager does not handle well the onchange event,
        //so we do have to check if is one of the fields filled
        if (!email) {
            const el = document.getElementById('email');
            if (el && el.nodeValue) {
                data.email = el.nodeValue;
            }
        }
        if (!password) {
            const el = document.getElementById('password');
            if (el && el.nodeValue) {
                data.password = el.nodeValue;
            }
        }
        Api.post<LoginToken>('login', {
            body: JSON.stringify(data)
        })
            .then(response => {
                console.log(response);
                handleLogin(response);
            })
            .catch(error => {
                let message = "";
                if (typeof error.errors.email !== 'undefined') {
                    message = error.errors.email.join('<br />');
                }
                if (typeof error.errors.password !== 'undefined') {
                    if (message) {
                        message += '<br />';
                    }
                    message += error.errors.password.join('<br />');
                }
                setErrorMessage(message);
                setShowToast(true);
                setTimeout(() => setShowToast(false), 5000);
            })
            ;
    }

    // function loginWithFacebook() {
    //     Api.get<SocialiteUrl>('login/facebook')
    //         .then(response => {
    //             window.location.replace(response.url);
    //         });
    // }
    //
    // function loginWithGoogle() {
    //     Api.get<SocialiteUrl>('login/google')
    //         .then(response => {
    //             window.location.replace(response.url);
    //         });
    // }

    return (
        <IonPage>
            <Header />
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={errorMessage}
                    position="top"
                    color="danger"
                />

                <IonGrid>
                    <IonRow className={'ion-justify-content-center'}>
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <div className='ion-margin-vertical'>
                                <IonNote className={'ion-text-left'}>
                                    {t('login.registrationNote1')}<br />
                                    <IonRouterLink href="/registration">
                                        {t('login.registrationLink')}
                                    </IonRouterLink>
                                    {t('login.registrationNote2')}
                                </IonNote>
                            </div>
                            <div className="ion-padding-top">
                                <TextWithBorder text={t('login.formTitle')} />
                                <p className="ion-text-center">
                                    {t('login.formDescription')}
                                </p>
                            </div>
                            <IonLabel>
                                {t('myaccount.email')}
                            </IonLabel>
                            <IonInput value={email}
                                role="email-input"
                                name="email"
                                onIonChange={e => setEmail(e.detail.value!)}
                                inputmode="email"
                                id="email"
                            />
                            <IonLabel>
                                {t('myaccount.password')}
                            </IonLabel>
                            <IonInput type="password"
                                value={password}
                                name="password"
                                onIonChange={e => setPassword(e.detail.value!)}
                                id="password"
                            />
                            <IonRouterLink href="/lost-password" className="pull-right">
                                {t('login.lostPassword')}
                            </IonRouterLink>
                            <IonButton
                                id="btn-login"
                                onClick={submitLogin}
                                size="default"
                            >
                                {t('login.submit')}
                            </IonButton>
                            {/*<IonButton*/}
                            {/*    className="ion-margin-top"*/}
                            {/*    expand="full"*/}
                            {/*    onClick={loginWithFacebook}*/}
                            {/*    size="default"*/}
                            {/*>*/}
                            {/*    <IonIcon slot="start" icon={logoFacebook}/>*/}
                            {/*    Facebook*/}
                            {/*</IonButton>*/}
                            {/*<IonButton*/}
                            {/*    className="ion-margin-top"*/}
                            {/*    expand="full"*/}
                            {/*    onClick={loginWithGoogle}*/}
                            {/*    size="default"*/}
                            {/*>*/}
                            {/*    <IonIcon slot="start" icon={logoGoogle}/>*/}
                            {/*    Google*/}
                            {/*</IonButton>*/}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Login;
