import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {IonCol, IonLabel, IonRow, IonSegment, IonSegmentButton, IonText} from "@ionic/react";
import ContentService from "../../services/ContentService";
import {useTranslation} from "react-i18next";
import formatPrice from "../../helpers/formatPrice";
import IProduct from "../../interfaces/IProduct";

const Content = (
    {
        price,
        priceText,
        device,
        deviceCount,
        devicePrice,
        totalPrice,
        withDevices,
        setWithDevices
    }:
        {
            price: number,
            priceText: string,
            device: IProduct,
            deviceCount:number,
            devicePrice:number,
            totalPrice:number,
            withDevices:boolean,
            setWithDevices:Dispatch<SetStateAction<boolean>>,
        }
) => {
    const {t} = useTranslation();
    const [content, setContent] = useState<Array<string>>();

    useEffect(() => {
        ContentService.get('brainclub-tagsag-hosszabbitas')
            .then(response => {
                const content = response.content.split('<!-- pagebreak -->');
                setContent(content);
            });
    }, [])

    return <>
        {content && <div id="content" dangerouslySetInnerHTML={{__html: content[0]}}/>}
        <IonRow>
            <IonCol>
                <IonText color="primary">

                    <h2><b><span id={"price"}>{formatPrice(price)}{priceText}</span></b></h2>
                </IonText>
                {content && <div dangerouslySetInnerHTML={{__html: content[1]}}/>}
            </IonCol>
        </IonRow>

        {deviceCount && <>
            <IonRow>
                <IonCol>
                    {content && <div dangerouslySetInnerHTML={{__html: content[2]}}/>}
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonText color="primary">
                        {deviceCount} {t('renewpayment.device')} ({formatPrice(device.price)}{priceText})<br />
                        <h2><b><span id={"device-price"}>{`${formatPrice(devicePrice)} ${priceText}`}</span></b></h2>
                    </IonText>
                </IonCol>
            </IonRow>
            <IonSegment
                mode="ios"
                className="ion-margin-top"
                onIonChange={e => setWithDevices(prevState => !prevState)}
                value={withDevices ? 'withDevices' : 'withoutDevices'}
            >
                <IonSegmentButton value="withDevices">
                    <IonLabel>
                        <h2>{t('renewpayment.withDevices')}</h2>
                    </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="withoutDevices">
                    <IonLabel>
                        <h2>{t('renewpayment.withoutDevices')}</h2>
                    </IonLabel>
                </IonSegmentButton>
            </IonSegment>
            <IonRow>
                <h2 className="ion-text-center width-100"><b><span id={"total-price"}>{formatPrice(totalPrice)}{priceText}</span></b></h2>
            </IonRow>
        </>}
    </>
}

export default Content;
