import React, {useState} from "react";
import {IonButton, IonIcon, IonItem, IonList, IonListHeader} from "@ionic/react";
import {chevronDown, chevronForward, desktopOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import DevicesList from "../DevicesList/DevicesList";
import {useHistory} from "react-router-dom";
// import Api from "../../api";

const Devices = () => {
    const {t} = useTranslation();
    let history = useHistory();

    const [active, setActive] = useState<number>(0);
    // const [hasMembership, setHasMembership] = useState<boolean>(false);
    const membership = localStorage.getItem('membership');
    const eventHandler = (e: any, index: number) => {
        e.preventDefault();
        active === index ? setActive(0) : setActive(index);
    }

    function newDevice() {
        history.push('/new-device');
    }

    // useEffect(() => {
    //     Api.get<boolean[]>('has-membership').then(response => {
    //         setHasMembership(response);
    //     })
    // }, []);

    return (
        <>
            <IonListHeader className="ion-no-padding">
                <IonButton
                    size="large"
                    fill="clear"
                    expand="full"
                    onClick={(e) => eventHandler(e, 1)}
                >
                    <IonIcon icon={desktopOutline} size="large" />
                    <span className="ion-text-left width-100">{t("devices.profileTitle")}</span>
                    {active === 1 ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 1 ? 'ion-padding-start ion-margin-bottom' : 'ion-hide'} lines="none">
                <IonItem>
                    <DevicesList/>
                </IonItem>
                {membership === "2" && <IonItem>
                    <IonButton size="default" onClick={newDevice}>
                        <span className="break-spaces">{t('devices.newdevice')}</span>
                    </IonButton>
                </IonItem>}
            </IonList>
        </>
    );
}

export default Devices;