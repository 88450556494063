import React, {useEffect, useState} from "react";
import {IonItem, IonList, IonListHeader} from "@ionic/react";
import getData from "../../services/getData";
import {Grade} from "../../interfaces/Grade";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import "./GradesComponent.scss";

const GradesComponent = () => {

    const { t } = useTranslation();
    const [grades, setGrades] = useState<Array<Grade>>();
    let history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('topic')) {
            localStorage.removeItem('topic');
        }
        getData<Array<Grade>>({
            apiUrl: 'grades',
            storageName: 'grades',
            storageCallback: (db) => db.table<Grade>('grades').toArray()
        })
            .then(response => {
                setGrades(response)
            });

    }, []);

    function pickGrade(id: number) {
        localStorage.setItem('grade', String(id));
        history.push('/subjects');
    }

    function pickSubject(subject_id: number, grade_id: number) {
        localStorage.setItem('subject', String(subject_id));
        localStorage.setItem('grade', String(grade_id));
        history.push('/topics');
    }

    return (<>
        <div className="glass ion-margin-top">
            <IonListHeader className="form-block-heading ion-align-items-center width-100">
                <div className="ion-padding-horizontal width-100">
                        <h2 className="text-white  ion-text-center">{t('page.grades')}</h2>
                </div>
            </IonListHeader>
            <IonList lines="none" className="ion-padding-start ion-padding-end">
                {grades?.map(grade =>
                    <IonItem className="ion-margin-bottom clickable" test-id={'grade_' + grade.id} key={'grade_' + grade.id}
                             onClick={e => pickGrade(grade.id)}>
                        {/* <div className="icon">
                                                <span className="grade-number">{grade.number}</span>
                                            </div> */}
                        <div className="details  ion-text-center ion-margin-bottom">
                            <div className="grade-name ion-margin-bottom"><span>{grade.name}</span></div>
                            <div className="subjects">
                                {t('grades.subjects')}:
                                {grade.subjects?.map((subject, index) => (
                                    <span key={"subject_" + subject.id}>
                                                            {(index ? ', ' : ' ')}
                                        <span
                                            className="subject-link"
                                            onClick={e => {
                                                e.stopPropagation();
                                                pickSubject(subject.id, grade.id)
                                            }}>
                                                                {subject.name}
                                                            </span>
                                                        </span>
                                ))}
                            </div>
                            <div className="topics">
                                {t('grades.topics')}: {grade.topics}
                            </div>
                        </div>
                    </IonItem>)
                }
            </IonList>
        </div>
    </>)
}

export default GradesComponent;