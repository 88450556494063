import {createContext} from "react";
import IProfile from "../interfaces/IProfile";

const DataContext = createContext({
    searchText: "",
    setSearchText: (newSearchText: string) => {},
    profile: {} as IProfile,
    setProfile: (newProfile: IProfile) => {}
});
export const DataProvider = DataContext.Provider;

export default DataContext;