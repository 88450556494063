import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/Page/Page";
import { Redirect, useHistory, useParams } from "react-router-dom";
import {
    IonCol,
    IonContent,
    IonGrid,
    IonRow
} from "@ionic/react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Header from "../../components/Header/Header";
import Topic from "../../interfaces/Topic";
import Storage from "../../services/Storage";
import { useTranslation } from "react-i18next";

import "./Topics.scss";

import DataContext from "../../context/DataContext";
import Widget404 from "../../components/Widget404/Widget404";

const Topics = () => {
    const { t } = useTranslation();
    const { topicId } = useParams();
    const grade = localStorage.getItem('grade');
    const subject = localStorage.getItem('subject');
    const { profile } = useContext(DataContext);

    const [crumbs, setCrumbs] = useState<object[]>([{}]);
    const [topics, setTopics] = useState<Topic[]>([]);
    const [topic, setTopic] = useState<number>(0);
    const [oTopic, setOTopic] = useState<Topic>({} as Topic);
    const [redirectUrl, setRedirectUrl] = useState<string>('');
    let history = useHistory();

    useEffect(() => {
        if (grade && subject && topics.length === 0) {
            Storage.get<Topic[]>({
                apiUrl: 'topics?subject=' + subject + '&grade=' + grade,
                storageName: 'topics',
                storageCallback: db => db.table<Topic>('topics').where('subject_id').equals(subject).and(item => item.grade_id === parseInt(grade)).toArray()
            })
                .then(response => {
                    setTopics(response);
                })
        } else {
            if (topicId) {

                Storage.get<Topic>({
                    apiUrl: 'topic/' + topicId,
                    storageName: 'topics',
                    storageCallback: db => db.table<Topic>('topics').where('id').equals(topicId).toArray()
                })
                    .then(response => {
                        localStorage.setItem('grade', response.grade_id.toString());
                        localStorage.setItem('subject', response.subject_id.toString());
                        // window.location.reload();
                    })


                // if (!grade) {
                //     localStorage.setItem('grade', topicObject.grade_id.toString());
                //     // grade = topicObject.grade_id.toString();
                // }
                // if (!subject) {
                //     localStorage.setItem('subject', topicObject.subject_id.toString());
                //     // subject = topicObject.subject_id.toString();
                // }
            }
        }
    }, [grade, subject, topics]);

    useEffect(() => {
        if (topics.length > 0) {
            if (topicId) {
                const topicObject = getTopicById(parseInt(topicId));
                if (topicObject) {
                    setOTopic(topicObject);
                    setTopic(parseInt(topicId));


                }
            }
        }
    }, [topics, topicId]);

    useEffect(() => {
        if (topics.length === 0) {
            return;
        }
        let crumbs = [
            { 'text': t('page.grades'), 'href': '/grades' },
            { 'text': grade + '. ' + t('page.grade'), 'href': '/subjects' },
            { 'text': topics[0].subject_name, 'href': '/topics' }
        ];
        if (topic) {
            if (oTopic) {
                for (let i = 0; i < oTopic.breadcrumb.length; i++) {
                    const element = oTopic.breadcrumb[i];
                    crumbs.push({ 'text': element.name, 'href': '/topics/' + element.id });
                }
            }
        }
        setCrumbs(crumbs);
    }, [topics, topic, oTopic])

    function getTopicById(id: number): Topic | null {
        if (topics.length === 0) {
            return null;
        }
        for (let i = 0; i < topics.length; i++) {
            if (topics![i].id === id) {
                return topics![i];
            }
        }
        return null;
    }

    function pickTopic(id: number) {
        if (!id) {
            return;
        }

        const topicObject = getTopicById(id);
        if (!topicObject || !topicObject.topics) {
            setRedirectUrl('/videos/' + id);
        } else {
            setRedirectUrl('/topics/' + id);
            setTopic(id);
            setOTopic(topicObject);
        }
    }

    // if (!grade) {
    //     return <Redirect to="/grades"/>
    // }
    //
    // if (!subject) {
    //     return <Redirect to="/subjects"/>
    // }

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />
    }

    function goToPage(to: string) {
        history.push(to);
    }

    return (<Page>

        <Header />
        <IonContent fullscreen>
            <Breadcrumb crumbs={crumbs} />
            <Widget404/>

            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'} className="ion-text-center">
                        {oTopic && oTopic.breadcrumb && <div className="ion-margin-bottom">
                            <p className="text-large">
                                {oTopic.breadcrumb[oTopic.breadcrumb.length - 1].name}
                            </p>
                        </div>
                        }
                    </IonCol>
                </IonRow>
                <IonRow>
                    {topics?.filter((item: Topic) => {
                        return item.parent === topic;
                    }).map(oTopic =>
                        <IonCol
                            size={'12'} sizeMd={'6'} sizeLg={'3'}
                            className="ion-text-center ion-margin-bottom clickable"
                            key={'topic_' + oTopic.id}
                            onClick={() => goToPage((oTopic.topics ? '/topics/' : '/videos/') + oTopic.id)}
                        >
                            <div className={oTopic.topics && oTopic.topics > 0 ? 'glass sub-topic-on-list' : ''}>
                                <div className={oTopic.topics && oTopic.topics > 0 ? 'ion-padding-horizontal' : ''}>
                                    {oTopic.thumbnail && <img className="topic-thumbnail ion-margin-end"
                                        src={"https://file.brainclub.hu/uploads/" + oTopic.thumbnail + '/' + oTopic.thumbnailToken + '?size=thumb'}
                                        alt={oTopic.name} />}
                                    <p className={oTopic.topics && oTopic.topics > 0 ? 'text-x-large' : ''}>
                                        {oTopic.name}
                                    </p>
                                    {oTopic.topics && oTopic.topics > 0 ?
                                        <div className="topics">
                                            {t('topics.videos')}: {oTopic.topics}
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </IonCol>
                    )}
                </IonRow>
            </IonGrid>
        </IonContent>
    </Page >);
}

export default Topics;
