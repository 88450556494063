import React, { useEffect, useState } from "react";
import '../../i18';
import { useTranslation } from 'react-i18next';
import { chevronDown, chevronForward, personOutline } from "ionicons/icons";
import { IonListHeader, IonButton, IonIcon, IonList, IonInput, IonItem, IonToast, IonLabel } from "@ionic/react";
import IProfile from "../../interfaces/IProfile";
import Profile from "../../services/profile";
import Storage from "../../services/Storage";

const ParentProfile = () => {
    const { t } = useTranslation();
    const [profileDetails, setProfileDetails] = useState<IProfile>({} as IProfile);
    const [message, setMessage] = useState<string>();
    const [toastType, setToastType] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const [active, setActive] = useState<number>();
    const eventHandler = (e: any, index: number) => {
        e.preventDefault();
        active === index ? setActive(0) : setActive(index);
    }

    useEffect(() => {
        Profile.getProfileDataByType(0).then(
            data => {
                setProfileDetails(data[0]);
            }
        );
    }, []);

    function setProfileDetailByKey(key: string, value: any) {
        setProfileDetails({ ...profileDetails, [key]: value });
    }

    function SubmitButton() {
        let disabled: boolean = false;
        if (profileDetails?.lastname === '' || profileDetails?.lastname === null
            || profileDetails?.firstname === '' || profileDetails?.firstname === null
            || profileDetails?.phone === '' || profileDetails?.phone === null
        ) {
            disabled = true;
        }
        return <IonButton size="default" disabled={disabled} onClick={storeData}>{t('Save')}</IonButton>;
    }

    function storeData() {
        const data = {
            storageName: 'profiles',
            apiUrl: 'profile/save',
            apiData: {
                records: [
                    profileDetails!
                ]
            },
            methodType: 'post'
        };
        Storage.update(data).then(() => {
            setMessage(t('apiPostSuccess'));
            setToastType('success');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        }).catch(error => {
            let message = '';
            error[0].errors.forEach((errorString: string) => {
                if (message) {
                    message += '<br />';
                }
                message += errorString;
            });
            setMessage(message);
            setToastType('danger');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        });
    }

    return (
        <>
            <IonListHeader className="form-block-heading">
                <IonButton
                    fill="clear"
                    expand="full"
                    onClick={(e) => eventHandler(e, 1)}
                    size="large"
                >
                    <IonIcon icon={personOutline} size="large" />&nbsp;
                    <span className="ion-text-left width-100">{t("myaccount.parentData")}</span>
                    {active === 1 ? <IonIcon icon={chevronDown} /> : <IonIcon icon={chevronForward} />}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 1 ? 'ion-padding-horizontal' : 'ion-hide'} lines="none">
                <IonItem key="lastname">
                    <IonLabel position="stacked">
                        {t('myaccount.lastName')}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.lastname}
                        onIonChange={e => setProfileDetailByKey('lastname', e.detail.value!)}
                    />
                </IonItem>
                <IonItem key="firstname">
                    <IonLabel position="stacked">
                        {t('myaccount.firstName')}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.firstname}
                        onIonChange={e => setProfileDetailByKey('firstname', e.detail.value!)}
                    />
                </IonItem>
                <IonItem key="email">
                    <IonLabel position="stacked">
                        {t('myaccount.email')} ({t('readonly')})*
                    </IonLabel>
                    <IonInput
                        readonly
                        value={profileDetails?.email}
                    />
                </IonItem>
                <IonItem key="phone">
                    <IonLabel position="stacked">
                        {t('myaccount.phonePlaceholder')}*
                    </IonLabel>
                    <IonInput
                        value={profileDetails?.phone}
                        onIonChange={e => setProfileDetailByKey('phone', e.detail.value!)}
                    />
                </IonItem>
                <IonItem className="ion-margin-vertical" key="button">
                    <SubmitButton />
                </IonItem>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={message}
                    position="top"
                    color={toastType}
                />
            </IonList>
        </>
    );
};

export default ParentProfile;