import React, {useEffect, useState} from "react";
import Api from "../../api";
import Device from "../../interfaces/Device";
import {IonIcon, IonItem, IonList, IonAlert} from "@ionic/react";
import {trashOutline} from "ionicons/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const DevicesList = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const [devices, setDevices] = useState<Device[]>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [device, setDevice] = useState<number>(0);

    useEffect(() => {
        Api.get<Device[]>('device').then(response => {
            setDevices(response);
        })
    }, [setDevices]);

    function deleteDevice(id: number) {
        setShowAlert(true);
        setDevice(id);
    }

    return (
        <>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={t('deviceslist.alerttitle')}
                message={t('deviceslist.alertmessage')}
                buttons={[
                    {
                        text: t('deviceslist.cancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: t('deviceslist.ok'),
                        handler: () => {
                            Api.put<Device>('device/' + device, {
                                body: JSON.stringify({
                                    device_name: '',
                                    token: ''
                                })
                            }).then(() => {
                                setShowAlert(false);
                                setDevices(prevState => prevState.map(item => {
                                    if (item.id === device) {
                                        item.device_name = '';
                                        item.token = '';
                                    }
                                    return item;
                                }));
                                if (localStorage.getItem('device_valid') === '0') {
                                    history.push('/device-check')
                                }
                            });
                        }
                    }
                ]}
            />

            <IonList>
                {devices.map(device => (
                    <IonItem key={device.id} className="ion-margin-bottom">
                        {device.device_name ? device.device_name : t('deviceslist.unknownDevice')}
                        {device.device_name &&
                        <IonIcon className="clickable text-yellow" slot="end" icon={trashOutline}
                                 onClick={e => deleteDevice(device.id)}/>
                        }
                    </IonItem>
                ))}
            </IonList>
        </>
    )
}

export default DevicesList;