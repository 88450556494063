import React, {useEffect, useState} from "react";
import {IonCol, IonContent, IonGrid, IonIcon, IonRow} from "@ionic/react";
import {useTranslation} from "react-i18next";
import Page from "../../components/Page/Page";
import Header from "../../components/Header/Header";
import Storage from "../../services/Storage";

const PaymentDeleted = () => {
    const {t} = useTranslation();
    const [days, setDays] = useState<string>('0');
    useEffect(() => {
        Storage.get<string>({apiUrl:'payment/payment-days'}).then(response => setDays(response));
    }, [])

    return <Page>
        <Header/>
        <IonContent fullscreen>
            <IonGrid>
                <IonRow className={'ion-justify-content-center'}>
                    <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                        <h2 className="ion-text-center">
                            {t('paymentdeleted.successTitle')}
                        </h2>
                        <p className="ion-text-center">
                            {t('paymentdeleted.success').replace(/%s/, days)}
                        </p>
                        <p className="ion-text-center">
                            <a href="/home">{t('paymentdeleted.home')}</a>
                        </p>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </Page>
}
export default PaymentDeleted;