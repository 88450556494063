import Header from "../../components/Header/Header";
import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonRow,
    IonCol,
    IonGrid,
    IonList,
    IonItem,
    IonLabel, IonInput, IonToast, IonButton, IonRouterLink
} from "@ionic/react";
import Page from "../../components/Page/Page";
import {useTranslation} from "react-i18next";
import IProfile from "../../interfaces/IProfile";
import {convertToDate, getAge} from "../../services/dateHelper";
import Storage from "../../services/Storage";
import {useHistory} from "react-router-dom";
import Profile from "../../services/profile";

const CreateParentProfile = () => {
    const {t} = useTranslation();
    let history = useHistory();
    const [profileDetails, setProfileDetails] = useState<IProfile>({} as IProfile);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const [birthdayMax, setBirthdayMax] = useState<string>('');

    useEffect(() => {
        Profile.getProfileDataByType(0).then(
            data => {
                const profileData = data[0];
                if (typeof profileData !== 'undefined') {
                    setProfileDetails(profileData);
                }
            }
        );
    }, []);

    function storeData(inputName: keyof IProfile) {

        let record = {} as any;
        if (profileDetails.id) {
            record.id = profileDetails.id;
        } else {
            record.type = 0;
            record.subscription = 0;
        }

        record[inputName] = profileDetails![inputName];

        const data = {
            storageName: 'profiles',
            apiUrl: 'profile/save',
            apiData: {
                records: [
                    record
                ]
            },
            methodType: 'post'
        };
        const errorHandler = (error: any) => {
            showErrorMessage(error[0].errors.join('<br />'));
        }
        if (record.id) {
            Storage.update(data).catch(errorHandler);
        } else {
            Storage.create(data)
                .then((response: any) => {
                    localStorage.setItem('profile_id', String(response[0]));
                    localStorage.setItem('hasProfiles', String(1));
                    setProfileDetailByKey('id', response[0]);
                })
                .catch(errorHandler);
        }
    }

    function showErrorMessage(message: string) {
        setErrorMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);

        return false;
    }

    if (birthdayMax === '') {
        let max = new Date();
        max.setFullYear(max.getFullYear() - 18);
        setBirthdayMax(convertToDate(max));
    }

    function setProfileDetailByKey(key: string, value: any) {
        if (typeof value === "undefined" || value === null) {
            return;
        }
        if (key === "birthday" && getAge(value) < 18) {
            showErrorMessage(t('myaccount.over18'));
            return;
        }
        setProfileDetails(prevState => ({...prevState, [key]: value}));
    }

    function redirectToParentHome() {
        const requiredInputs: any = [
            profileDetails?.lastname,
            profileDetails?.firstname,
            profileDetails?.phone,
            profileDetails?.birthday
        ];
        if (requiredInputs.includes(null) || requiredInputs.includes(undefined) || requiredInputs.includes('')) {
            showErrorMessage(t('myaccount.allRequired'));
        } else {
            history.push('/home');
        }
    }

    return (
        <Page>
            <Header/>
            <IonContent fullscreen>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    message={errorMessage}
                    position="top"
                    color="danger"
                />
                <IonGrid>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size={'12'} sizeMd={'8'} sizeLg={'6'}>
                            <p className="text-large ion-text-center ion-margin-top">
                                {t('myaccount.trialProfileCreateLead')}
                            </p>
                            <div className="glass">

                                <IonList className="ion-padding-horizontal" lines="none">
                                    <IonItem className="ion-margin-bottom" key="lastname">
                                        <IonLabel position="stacked">
                                            {t("myaccount.lastName")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.lastname}
                                            onIonChange={e => setProfileDetailByKey('lastname', e.detail.value!)}
                                            onIonBlur={() => storeData('lastname')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="firstname">
                                        <IonLabel position="stacked">
                                            {t("myaccount.firstName")}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.firstname}
                                            onIonChange={e => setProfileDetailByKey('firstname', e.detail.value!)}
                                            onIonBlur={() => storeData('firstname')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="phone">
                                        <IonLabel position="stacked">
                                            {t("myaccount.phonePlaceholder")}*
                                        </IonLabel>
                                        <IonInput
                                            type="tel"
                                            value={profileDetails?.phone}
                                            onIonChange={e => setProfileDetailByKey('phone', e.detail.value!)}
                                            onIonBlur={() => storeData('phone')}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-margin-bottom" key="birthday">
                                        <IonCol size={'4'} sizeMd={'3'}>
                                            <label htmlFor="birthday">
                                                {t('myaccount.birthday')}:*
                                            </label>
                                        </IonCol>
                                        <IonCol size={'8'} sizeMd={'9'}>
                                            <input
                                                className="width-100"
                                                name="birthday"
                                                placeholder={t('myaccount.birthdayPlaceholder')}
                                                type="date"
                                                max={birthdayMax}
                                                value={profileDetails.birthday ? profileDetails.birthday : ''}
                                                onChange={e => setProfileDetailByKey('birthday', e.target.value)}
                                                onBlur={() => storeData('birthday')}
                                            />
                                        </IonCol>
                                    </IonItem>
                                    <IonItem lines="none" key="over18">
                                        {t('createParentProfile.over18')}
                                    </IonItem>
                                    <IonItem key="button" className="ion-margin-top">
                                        <IonButton
                                            size="default"
                                            className="ion-text-wrap"
                                            onClick={redirectToParentHome}
                                        >
                                            {t('myaccount.save')}
                                        </IonButton>
                                    </IonItem>
                                </IonList>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </Page>
    )
}

export default CreateParentProfile;