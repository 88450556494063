import {IDates, ISetData} from "../Storage";
import Api from "../../api";

export default function save<T>(props: ISetData<T>, callback: (record: T & IDates) => Promise<T & IDates>) {

    const storagePut = new Promise((resolve, reject) => {

        let apiData = props.apiData;
        if (props.storageName) {

            let data = props.apiData.records.map(async (record: T & IDates, index) => {

                callback(record).then(result => {
                    record = {...result}
                    apiData.records[index] = record;
                });
            });
            Promise.all(data).then(() => {
                resolve(apiData);
            })
        } else {
            resolve(apiData);
        }
    });


    return new Promise((resolve, reject) => {
        if (props.apiUrl) {
            // storagePut.then(apiData => {
                // @ts-ignore
                Api[props.methodType]<T>(props.apiUrl, {
                    body: JSON.stringify(props.apiData)
                })
                    .then((response: T) => {
                        resolve([response, props.apiData])
                    })

                    .catch((error: any) => {
                        reject([error, props.apiData]);
                    });
            // })
        }
    });
}