import {Redirect} from "react-router-dom";
import React from "react";

const Logout = () => {
    const deviceToken = localStorage.getItem('device_token');
    localStorage.clear();
    if (deviceToken) {
        localStorage.setItem('device_token', deviceToken);
    }
    return (<Redirect to="/home" />);
}

export default Logout;