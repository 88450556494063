import React, {useEffect, useRef, useState} from "react";
import '../../i18';
import {useTranslation} from 'react-i18next';
import {chevronDown, chevronForward, imageOutline} from "ionicons/icons";
import {
    IonListHeader,
    IonButton,
    IonIcon,
    IonList,
    IonInput,
    IonToast,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonCol,
    IonRow,
    IonItem,
    IonLabel, IonAlert
} from "@ionic/react";
import IProfile from "../../interfaces/IProfile";
import Api from "../../api";
import Storage from "../../services/Storage";
import Avatar from "../../components/Avatar/Avatar";
import './ChildProfile.scss';
import Device from "../../interfaces/Device";
interface ChildProfileProps {
    profile: IProfile,
    opened?: number,
    index:number,
    onSave: (profile: IProfile, index: number) => void,
    onDelete: (profile: IProfile, index: number) => void
}
const ChildProfile = ({profile, opened, index, onSave, onDelete}: ChildProfileProps) => {
    const {t} = useTranslation();
    const [profileDetails, setProfileDetails] = useState<IProfile>(profile);
    const [message, setMessage] = useState<string>();
    const [toastType, setToastType] = useState<string>();
    const [showToast, setShowToast] = useState(false);
    const [active, setActive] = useState<number>(0);
    const [fileSelected, setFileSelected] = useState<number>(0);
    const [showAlert, setShowAlert] = useState(false);

    // const [check, setCHeck] = useState<boolean>(false);
    const eventHandler = (e: any, index: number) => {
        e.preventDefault();
        active === index ? setActive(0) : setActive(index);
    }
    const imageRef = useRef<HTMLInputElement>(null);

    function setProfileDetailByKey(key: string, value: any) {
        setProfileDetails({...profileDetails, [key]: value});
    }

    // useEffect(() => {
    //     if (profileDetails && profileDetails.user_id === 0) {
    //         setActive(1);
    //     } else {
    //         setActive(0);
    //     }
    // }, [profileDetails]);

    useEffect(() => {
        if (opened !== null) {
            setActive(opened!);
        }
    }, [opened]);



    function SubmitButton() {
        // let disabled: boolean = false;
        // if (
        //     profileDetails?.firstname === '' || profileDetails?.firstname === null
        //     || profileDetails?.grade === 0 || profileDetails?.grade === null
        // ) {
        //     disabled = true;
        // }
        return <IonButton size="default" onClick={validateData}>{t('Save')}</IonButton>;
    }

    function validateData() {
        let message: string[] = [];
        if (profileDetails?.lastname === '' || profileDetails?.lastname === null) {
            message.push(t('childProfile.lastnameError'));
        }
        if (profileDetails?.firstname === '' || profileDetails?.firstname === null) {
            message.push(t('childProfile.firstnameError'));
        }
        if (profileDetails?.grade === 0 || profileDetails?.grade === null) {
            message.push(t('childProfile.gradeError'));
        }
        if (message.length) {
            setMessage(t('childProfile.validationError') + '<br />' + message.join('<br />'));
            setToastType('danger');
            setShowToast(true);
            setTimeout(() => setShowToast(false), 5000);
        } else {
            storeData();
        }
    }

    function storeData() {
        let mode = 'update';
        if (profileDetails.user_id === 0) {
            profileDetails.user_id = Number(localStorage.getItem('user_id'));
            mode = 'create';
        }

        saveData(mode, () => {
            if (imageRef.current !== null && imageRef.current!.files!.length > 0) {
                const formData = new FormData()
                formData.append('id', profileDetails.id)
                formData.append('file', imageRef.current!.files![0])
                Api.post('profile/image-upload', {body: formData, headers: {}}).then(
                    (response) => {
                        setProfileDetails(oldState => {return {...oldState, image: response}})
                        // profileDetails.image = response
                    }
                ).catch(error => {
                    let message = error.errors.join('<br />');
                    // error.errors.forEach((errorString: string) => {
                    //     if (message) {
                    //         message += '<br />';
                    //     }
                    //     message += errorString;
                    // });
                    setMessage(message);
                    setToastType('danger');
                    setShowToast(true);
                    setTimeout(() => setShowToast(false), 5000);
                });
            }
        });
    }

    function saveData(mode: string, callback?: () => void) {
        const data = {
            storageName: 'profiles',
            apiUrl: 'profile/save',
            apiData: {
                records: [
                    profileDetails!
                ]
            },
            methodType: 'post'
        };
        if (mode === 'update') {
            Storage.update(data).then(() => {
                if (callback !== null) {
                    callback!();
                }
                storageSuccess();
            }).catch(error => {
                storageError(error);
            });
        } else {
            Storage.create(data).then(() => {
                if (callback !== null) {
                    callback!();
                }
                storageSuccess();
            }).catch(error => {
                storageError(error);
            });
        }
        setActive(0);
    }

    function storageSuccess() {
        setMessage(t('apiPostSuccess'));
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
        onSave(profileDetails, index);
    }

    function storageError(error: any) {
        let message = '';
        if (typeof error === 'string') {
            message = error;
        } else {
            message = error[0].errors.join('<br  />');
            // error[0].errors.forEach((errorString: string) => {
            //     if (message) {
            //         message += '<br />';
            //     }
            //     message += errorString;
            // });
        }
        setMessage(message);
        setToastType('danger');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);
    }

    function ImageUpload() {
        // if (profileDetails.id) {
        return (
            <IonButton onClick={() => imageRef.current!.click()} size="small" expand="full">
                <input
                    name="file"
                    accept="image/*"
                    hidden
                    ref={imageRef}
                    type="file"
                    onChange={e => { setFileSelected(imageRef.current!.files!.length); }}
                />
                    <svg viewBox="0 0 100 50">
                        <text x="50" y="20" textAnchor="middle">&nbsp;{t("myaccount.addPhoto1")}</text>
                        <text x="50" y="40" textAnchor="middle">&nbsp;{t("myaccount.addPhoto2")}</text>
                    </svg>

            </IonButton>
        );
        // } else {
        //     return (
        //         <>
        //         </>
        //     );
        // }
    }

    return (
        <>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={t('deviceslist.alerttitle')}
                message={t('childProfile.confirmDelete')}
                buttons={[
                    {
                        text: t('deviceslist.cancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: t('deviceslist.ok'),
                        handler: () => {
                            setActive(0);
                            Api.delete('profile/delete/' + profileDetails.id).then(
                                (response) => {
                                    onDelete(profileDetails, index);
                                }
                            )
                        }
                    }
                ]}
            />

            <IonListHeader>
                <IonButton
                    fill="clear"
                    expand="full"
                    className="ion-no-margin ion-no-padding ion-padding-end"
                    onClick={(e) => eventHandler(e, 1)}
                    size="large"
                >
                    <span className="ion-text-left width-100 no-text-transform">{profileDetails?.firstname}</span>
                    {active === 1 ? <IonIcon icon={chevronDown}/> : <IonIcon icon={chevronForward}/>}
                </IonButton>
            </IonListHeader>
            <IonList className={active === 1 ? 'sublist max-height-none ion-padding-horizontal' : 'ion-hide'}
                     lines="none">
                <form encType="multipart/form-data" className="profile-data">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="4" sizeMd="2" className="profile-image">
                                {fileSelected > 0 && <span className="file-selected">{fileSelected} file kiválasztva</span>}

                                <Avatar profileId={profileDetails.id} profileImage={profileDetails.image}/>
                                <ImageUpload/>
                                <IonButton onClick={e => setShowAlert(true)} size="small" expand="full" className="ion-margin-top" fill="outline">
                                    <svg viewBox="0 0 100 50">
                                        <text x="50" y="20" textAnchor="middle">{t('childProfile.deleteProfile1')}</text>
                                        <text x="50" y="40" textAnchor="middle">{t('childProfile.deleteProfile2')}</text>
                                    </svg>

                                </IonButton>
                            </IonCol>
                            <IonCol size="8" sizeMd="10" className="ion-text-left">
                                <IonList>
                                    <IonItem key="lastname">
                                        <IonLabel position="stacked">
                                            {t('myaccount.lastName')}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.lastname}
                                            onIonChange={e => setProfileDetailByKey('lastname', e.detail.value!)}
                                        />
                                    </IonItem>
                                    <IonItem key="firstname">
                                        <IonLabel position="stacked">
                                            {t('myaccount.firstName')}*
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.firstname}
                                            onIonChange={e => setProfileDetailByKey('firstname', e.detail.value!)}
                                        />
                                    </IonItem>
                                    <IonItem key="grade">
                                        <IonLabel position="stacked">
                                            {t('myaccount.grade')}*
                                        </IonLabel>
                                        <IonSelect
                                            value={profileDetails?.grade}
                                            cancelText={t('cancel')}
                                            onIonChange={e => setProfileDetailByKey('grade', e.detail.value)}
                                        >
                                            {
                                                [...Array(7)].map((e, i) => {
                                                    return <IonSelectOption key={i+6}
                                                                            value={(i + 7).toString()}>{i + 7}</IonSelectOption>
                                                })
                                            }
                                        </IonSelect>
                                    </IonItem>
                                    {/*
                                    <IonItem key="gradename">
                                        {profileDetails.grade_name &&
                                            <IonLabel position="stacked">
                                                {t('myaccount.gradeName')}
                                            </IonLabel>
                                        }
                                        <IonSelect
                                            value={profileDetails?.grade_name}
                                            placeholder={t('myaccount.gradeName')}
                                            cancelText={t('cancel')}
                                            onIonChange={e => setProfileDetailByKey('grade_name', e.detail.value)}
                                        >
                                            {
                                                ['A', 'B', 'C', 'D', 'E', 'F'].map((e, i) => {
                                                    return <IonSelectOption key={i} value={e}>{e}</IonSelectOption>
                                                })
                                            }
                                        </IonSelect>
                                    </IonItem>
                                    */}
                                    {/*
                                    <IonItem key="schoolcity">
                                        <IonLabel position="stacked">
                                            {t('myaccount.city')}
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.school_city}
                                            onIonChange={e => setProfileDetailByKey('school_city', e.detail.value!)}
                                        />
                                    </IonItem>
                                    <IonItem key="schoolname">
                                        <IonLabel position="stacked">
                                            {t('myaccount.school')}
                                        </IonLabel>
                                        <IonInput
                                            value={profileDetails?.school_name}
                                            onIonChange={e => setProfileDetailByKey('school_name', e.detail.value!)}
                                        />
                                    </IonItem>
                                    */}
                                    <IonItem>
                                        <SubmitButton/>
                                    </IonItem>
                                </IonList>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </form>
            </IonList>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={message}
                position="top"
                color={toastType}
            />
        </>
    );
};

export default ChildProfile;